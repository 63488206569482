import {FC, memo} from 'react';

import * as SC from './title.style';

interface Props{
	title: string;
}

export const TitlePage: FC<Props> = memo(({title}) => {
	return <SC.Title >{title}</SC.Title>;
});
