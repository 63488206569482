import {CSSProperties, FC, memo, ReactNode} from 'react';

import * as SC from './button-new.style';

interface Props {
	onClick?: () => void;
	label: string;
	bgColor?: 'yellow' | 'green' | 'purple';
	className?: string;
	icon?: ReactNode;
	disabled?: boolean;
	iconPosition?: 'left' | 'right';
	style?: CSSProperties;
	type?: 'button' | 'submit' | 'reset';
}

export const ButtonNew: FC<Props> = memo(({
											  bgColor = 'green', onClick, label, className, disabled, icon,
											  iconPosition = 'right', style, type,
										  }) => {
	return (
		<SC.Container onClick={onClick} type={type}
					  disabled={disabled}>
			<SC.Wrapper bgColor={bgColor} className={className} style={style}>
				{(icon && iconPosition === 'left') && icon}
				{label}
				{(icon && iconPosition === 'right') && icon}
			</SC.Wrapper>
		</SC.Container>
	);
});
