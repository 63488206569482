import {range} from 'lodash';
import {
	ChangeEvent,
	Dispatch,
	FC,
	memo,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';

import {IOrder} from '../../../../interfaces/order';
import {setOrderActivate} from '../../../../redux/reducers/orders-reducer';
import {setScheduleEditorTime} from '../../../../redux/reducers/schedule-editor-settings-daily-reducer';
import {useAppSelector} from '../../../../redux/selectors/hook';
import {scheduleEditorSettingsSelector} from '../../../../redux/selectors/schedule-editor-settings-selector';
import {useAppDispatch} from '../../../../redux/store';
import {POINTS_PER_DAY} from '../../../../shared/constants/time';
import {getIdUser} from '../../../../shared/utils/decoder-jwt';
import {
	converterTimeLocalString,
	converterTimeString
} from '../../../../shared/utils/time/converter-time';
import {getTimeDisplayValue} from '../../../../shared/utils/time/get-time-display-value';
import {Button} from '../../../buttons';
import {TimeSelect} from '../../../controls';
import {Calendar} from '../../../controls/calendar/calendar';
import IconPencil from '../../../svg-icon-components/icon-pencil';
import * as SC from './update-data-time.style';

interface Props {
	order: IOrder;
	from: number;
	to: number;
	setFrom: Dispatch<SetStateAction<number>>;
	setTo: Dispatch<SetStateAction<number>>;
	setDate: Dispatch<SetStateAction<string>>;
	date: string
	nowShowBtnUpdate?: boolean
}

export const UpdateDataTime: FC<Props> = memo(({order, to, from, setFrom, setTo, setDate, date,nowShowBtnUpdate}) => {
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useAppDispatch();
	const [showCalendar, setShowCalendar] = useState(false);
	const {scheduleEditorModalSettingsDaily: {startAt, endAt}} = useAppSelector(scheduleEditorSettingsSelector);
	const ref = useRef<HTMLDivElement>(null);
	const idUser = getIdUser();

	const clickSave = async () => {
		setFrom(startAt);
		setTo(endAt);
		await dispatch(setOrderActivate({
			date: date || order.order_details.date,
			from,
			duration: to - from,
			order_id: order.id,
			platform_id: order.host_info.platform_id,
			requester: idUser,
			service: order.service_info.service_id
		}));
		setOpenModal(false);
	};

	const clickOpenModal = () => {
		dispatch(setScheduleEditorTime({startAt: order.order_details.from}));
		dispatch(setScheduleEditorTime({endAt: order.order_details.from + order.order_details.duration}));
		setOpenModal(prevState => !prevState);
	};
	const clickShowCalendar = () => setShowCalendar(prevState => !prevState);

	const startPoints = useMemo(() => range(0, POINTS_PER_DAY - 1, 1), []);

	const endPoints = useMemo(() => {
		return range(startAt, POINTS_PER_DAY, 1);
	}, [startAt]);

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement> | number, type: string) => {
		if (typeof e === 'number') {
			dispatch(setScheduleEditorTime({[type]: e}));
		} else {
			dispatch(setScheduleEditorTime({[type]: +e.target.value}));
		}
	}, [startAt]);


	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				ref.current &&
				!ref.current.contains(event.target as Node)
			) {
				setOpenModal(false);
			}
		};

		document.addEventListener('click', handleClickOutside, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [ref]);

	window.addEventListener('scroll', function () {
		if (openModal) {
			setOpenModal(false);
		}
	});

	const onSelectCalendar = useCallback((value: Date | undefined) => {
		if (value) {
			setDate(converterTimeLocalString(value));
			setShowCalendar(false);
		}
	}, []);

	return (
		<SC.Container>
			{openModal && <SC.Modal ref={ref}>
				<SC.SelectTimeWindowContainer>
					<h4>Выберите время:</h4>
					<TimeSelect
						points={startPoints}
						value={startAt}
						onChange={(e) => {
							setOpenModal(true);
							handleChange(e, 'startAt');
						}}
						label="Начало"
					/>
					<TimeSelect
						points={endPoints}
						value={endAt}
						onChange={(e) => handleChange(e, 'endAt')}
						label="Конец"
					/>
				</SC.SelectTimeWindowContainer>
				<SC.DateWrapper>
					<SC.DateTitleWrapper>
						<h4>Выберите дату:</h4>
						<span>{date || order.order_details.date}</span>
					</SC.DateTitleWrapper>
					<Button style={SC.ButtonNavigateData} label={'Выбрать дату'} onClick={clickShowCalendar}
							bgColor={'purple'}/>
					{showCalendar &&
						<Calendar className={SC.Calendar} mode="single" onSelect={onSelectCalendar}/>}
				</SC.DateWrapper>
				<Button style={SC.ButtonNavigateData} label={'Сохранить'} onClick={clickSave}
						bgColor={'green'}/>
			</SC.Modal>}
			<SC.ButtonUpdateWrapper>
				<p>{`${converterTimeString(date)} ${getTimeDisplayValue(from, to, true,)}`}</p>
				{!nowShowBtnUpdate && <SC.ButtonUpdate onClick={clickOpenModal}>
					<IconPencil backgroundColor="transparent" strokeColor={'grey'}/>
				</SC.ButtonUpdate>}
			</SC.ButtonUpdateWrapper>
		</SC.Container>
	);
});
