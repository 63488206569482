import {FC, useCallback, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import noImagePlaceholder from '../../../../assets/img/no_image.png';
import canceled_icon from '../../../../assets/order-icon/canceled-icon.svg';
import confirmed_icon from '../../../../assets/order-icon/confirmed-icon.svg';
import details from '../../../../assets/order-icon/details-icon.svg';
import pending_icon from '../../../../assets/order-icon/expectation-icon.svg';
import {OrderServerStatus} from '../../../../interfaces/enums';
import {IOrder} from '../../../../interfaces/order';
import {useAppDispatch} from '../../../../redux/store';
import {fetchPutOrderConfirm} from '../../../../redux/thunks/order/order-confirm';
import {fetchPutOrderActivate} from '../../../../redux/thunks/order/put-order-activate';
import {convertPriceToString} from '../../../../shared/utils/converter-price';
import {getIdUser} from '../../../../shared/utils/decoder-jwt';
import {getStatusColor, getStatusIcon, renderStatus} from '../../../../shared/utils/order/order';
import {activateScroll, disabledScroll} from '../../../../shared/utils/scroll-body';
import {converterTime, converterTimeString} from '../../../../shared/utils/time/converter-time';
import {OrderCardBtnAdmin} from '../../../buttons';
import {ButtonNew, ButtonWithBorder} from '../../../buttons-new';
import {CancelOrder} from '../../../order/modals/components/cancel-order/cancel-order';
import {OrderDetails} from '../../../order/order-details/order-details';
import {OverflowTooltip} from '../../../overflow-tooltip/overflow-tooltip';
import {UpdateDataTime} from '../update-data-time/update-data-time';
import * as SC from './cards-order.style';

interface Props {
	order: IOrder;
	refetch?: () => void;
	className?: string;
	notShowBtnAdmin?: boolean;
	showBtnCancellation?: boolean;
	showBtnConfirm?: boolean;
	nowShowBtnUpdate?: boolean;
	idUser?: string;
}

export const statusesForConfirm: string[] = [
	OrderServerStatus.Ignored,
	OrderServerStatus.WaitConfirmation
];

export const CardsOrder: FC<Props> = ({
										  order, refetch, className, notShowBtnAdmin, showBtnCancellation,
										  showBtnConfirm, nowShowBtnUpdate, idUser,
									  }) => {
	const [showDetails, setShowDetails] = useState(false);
	const [showWindowCaselOrder, setShowWindowCaselOrder] = useState(false);
	const [from, setFrom] = useState(order.order_details.from);
	const [to, setTo] = useState(order.order_details.from + order.order_details.duration);
	const [date, setDate] = useState(order.order_details.date);
	const iconStatus = getStatusIcon(canceled_icon, confirmed_icon, pending_icon, order.status_info.status);
	const isMobileMod = useMediaQuery({query: '(max-width: 600px)'});
	const dispatch = useAppDispatch();

	const clickShowWindowCanselOrder = useCallback(() => setShowWindowCaselOrder(prevState => !prevState), []);

	const clickShowDetails = () => {
		disabledScroll();
		setShowDetails(true);
	};
	const closeWindowDetails = () => {
		activateScroll();
		setShowDetails(false);
	};
	const handleActivateOrder = async () => {
		if (statusesForConfirm.includes(order.status_info.status)) {
			await dispatch(fetchPutOrderConfirm({
				order_id: order.id,
				platform_id: order.host_info.platform_id,
				requester: getIdUser() || idUser,
				service_id: order.service_info.service_id,
			}));
			if (refetch) {
				await refetch();
			}
			return;
		}

		await dispatch(fetchPutOrderActivate({
			service: order.service_info.service_id,
			order_id: order.id,
			requester: getIdUser() || idUser,
			platform_id: order.host_info.platform_id,
			date,
			from,
			duration: to - from,
		}));
		if (refetch) {
			await refetch();
		}
	};

	return (
		<SC.Container className={className}>
			<SC.Details src={details} alt="Подробнее" onClick={clickShowDetails}
						status={getStatusColor(order.status_info.status)} title={'Подробности заказа'}/>
			<SC.HeaderCard>
				<SC.Logo src={order.host_info.org_logo.at(0)?.info.image_link || noImagePlaceholder}
						 alt={order.host_info.organization}/>
				<SC.OrgDataWrapper>
					{!isMobileMod && <SC.OrgName><OverflowTooltip content={order.host_info.organization}/></SC.OrgName>}
					{isMobileMod && <SC.OrgNameMobile>{order.host_info.organization}</SC.OrgNameMobile>}
					{!isMobileMod && <SC.Address><OverflowTooltip
						content={`${order.host_info.city}, ${order.host_info.location_info.address}`}/></SC.Address>}
					{isMobileMod &&
						<SC.AddressMobile>{order.host_info.city}, {order.host_info.location_info.address}</SC.AddressMobile>}
				</SC.OrgDataWrapper>
			</SC.HeaderCard>
			<SC.Status status={getStatusColor(order.status_info.status)}>
				<SC.StatusIcon src={iconStatus} alt={'статус заказа сделанного в Sporder.by'} status={getStatusColor(order.status_info.status)}/>
				<span>{renderStatus(order)}</span>
			</SC.Status>
			<SC.OptionDataWrapper>
				{order.status_info.status !== 'WaitApproval' && <SC.OptionData>
					{converterTimeString(order.order_details.date)} {converterTime(order.order_details.from)} - {converterTime(order.order_details.from + order.order_details.duration)}
				</SC.OptionData>}
				{order.status_info.status === 'WaitApproval' && <SC.OptionData>
					<UpdateDataTime order={order} from={from} to={to} date={date} setDate={setDate} setFrom={setFrom}
									setTo={setTo} nowShowBtnUpdate={nowShowBtnUpdate}/>
				</SC.OptionData>}
				<SC.OptionData>{order.service_info.service_type}</SC.OptionData>
			</SC.OptionDataWrapper>
			<SC.Price
				status={getStatusColor(order.status_info.status)}>{convertPriceToString(order.order_details.price)}</SC.Price>
			{!notShowBtnAdmin &&
				<OrderCardBtnAdmin order={order} handleActivateOrder={handleActivateOrder}
								   clickShowWindowCanselOrder={clickShowWindowCanselOrder}/>}
			{showBtnCancellation && <ButtonWithBorder label={'Отменить заказ'} borderColor={'yellow'}
													  onClick={clickShowWindowCanselOrder}/>}
			{showDetails && <OrderDetails order={order} closeWindowDetails={closeWindowDetails}/>}
			{showWindowCaselOrder &&
				<CancelOrder order={order} onClickClose={clickShowWindowCanselOrder} refetch={refetch}
							 idUser={idUser}/>}
			{showBtnConfirm && < ButtonNew label={'Подтвердить заказ'} bgColor={'purple'}
										   onClick={handleActivateOrder}/>}
		</SC.Container>
	);
};
