import axios from 'axios';

import {ListAbonementsParams} from '../interfaces/abonement';
import {
	HISTORY_USER, urlGetActiveAbonements, urlGetHistoryAbonements, urlGetOrdersActive, urlGetTotalHistoryAbonements,
	urlGetTotalVisitedOrgs,
	urlGetTotalVisitedPlatforms,
	urlGetTotalVisitedServices,
	urlGetVisitedOrgs,
	urlGetVisitedPlatforms,
	urlGetVisitedServices,
	urlUserClosestOrder,
} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';
import {GetVisitedOrganizationsParams, GetVisitedPlatformsParams, GetVisitedServicesParams} from './query-params';

const instanceJwt = axios.create({
	baseURL: HISTORY_USER,
	headers: {
		'Content-Type': 'application/json',
	},
});

instanceJwt.interceptors.request.use(
	(request) => {
		request.headers.Authorization = `Bearer ${jwtUser()}`;
		return request;
	},
	(error) => {
		console.log(error, 'No token');
	},
);

export const history = {
	getVisitedPlatforms(userId: string, params: GetVisitedPlatformsParams) {
		return instanceJwt.get(`${urlGetVisitedPlatforms}/${userId}?`, {params});
	},
	getTotalVisitedPlatforms(userId: string, params: GetVisitedPlatformsParams) {
		return instanceJwt.get(`${urlGetTotalVisitedPlatforms}/${userId}?`, {params});
	},

	getVisitedOrganizations(userId: string, params: GetVisitedOrganizationsParams) {
		return instanceJwt.get(`${urlGetVisitedOrgs}/${userId}`, {params});
	},
	getTotalVisitedOrganizations(userId: string, params: GetVisitedOrganizationsParams) {
		return instanceJwt.get(`${urlGetTotalVisitedOrgs}/${userId}`, {params});
	},

	getVisitedServices(userId: string, params: GetVisitedServicesParams) {
		return instanceJwt.get(`${urlGetVisitedServices}/${userId}`, {params});
	},
	getTotalVisitedServices(userId: string, params: GetVisitedServicesParams) {
		return instanceJwt.get(`${urlGetTotalVisitedServices}/${userId}`, {params});
	},

	getClosestService(userId: string) {
		return instanceJwt.get(urlUserClosestOrder + userId);
	},

	getActiveAbonements(params: { id: string }) {
		return instanceJwt.get(`${urlGetActiveAbonements}${params.id}`, {params});
	},
	getHistoryAbonements({params, userId}: { params?: ListAbonementsParams, userId: string }) {
		return instanceJwt.get(`${urlGetHistoryAbonements}${userId}`, {params});
	},
	getHistoryTotalAbonements({params, userId}: { params?: ListAbonementsParams, userId: string }) {
		return instanceJwt.get(`${urlGetTotalHistoryAbonements}${userId}`, {params});
	},
	getOrdersActive(userId: string) {
		return instanceJwt.get(`${urlGetOrdersActive}${userId}`);
	}
};
