import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const UserInfo = styled.div`
	display: none;
	@media screen and (min-width: 450px) {
		display: flex;
		align-items: center;
		position: relative;

		&:hover {
			cursor: pointer;
		}
	}
`;
export const BalanseWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: var(--Spacing-desctop-Block-S, 16px);
`;
export const Balance = styled.span`
	color: var(--text-G, #1C8666);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	text-wrap: nowrap;
`;
export const UserInfoDetails = styled.div`
	right: 60%;

	@media screen and (max-width: 600px) {
		right: 15%;
	}
	@media screen and (min-width: 450px) {
		position: absolute;
		top: calc(100% + 15px);
		z-index: 5;
		display: flex;
		flex-direction: column;
		padding: 10px 40px 15px 20px;
		color: var(--black-brown);
		background-color: var(--white-color);
		border-radius: 5px;
		gap: 10px;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);

		&:hover {
			cursor: default;
		}
`;


export const UserInfoDetailsListItem = css`
	@media screen and (min-width: 450px) {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		font-size: 14px;
		line-height: 1.3em;
		white-space: nowrap;
		gap: 15px;
	}
`;
