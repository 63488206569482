import styled from '@emotion/styled';

export const Container = styled.div`
    border-radius: 12px;
    padding: 12px;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
	justify-content: space-between;
    gap: 6px;
    color: var(--text-Strong);
    width: 200px;
    //height: 300px !important;
`;

export const Title = styled.h4`
	font-weight: 500;
	font-size: 16px;
	line-height: 1.2;
	height: 40px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const DataList = styled.ul`
	font-weight: 400;
	font-size: 18px;
	line-height: 1.2;
`;
export const DataListLi = styled.li`
	position: relative;
	padding-left: 20px;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.2;

	&:before {
		font-size: 44px;
		content: '•';
		color: var(--yellow);
		position: absolute;
		left: 0;
		top: 60%;
		transform: translateY(-50%);
	}
`;
export const LimitNone = styled.li`
	height: 17px;
`;
export const Button = {
	width: '100px',
	height: '33px',
	fontWeight: 600,
	fontSize: '14px',
	lineHeight: 1.2,
};
export const Price = styled.span`
	font-weight: 700;
	font-size: 20px;
	text-align: left;
`;
