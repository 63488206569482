import {createSlice} from '@reduxjs/toolkit';

import {ServiceSingle} from '../../interfaces/service';
import {fetchGetServiceById} from '../thunks/services/get-service-by-id';


const initialState: ServiceSingle = {} as ServiceSingle;

export const serviceSlice = createSlice({
	name: 'serviceSlice',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchGetServiceById.fulfilled, (_, action) => {
			return  action.payload.data;
		});
	}
});
