import {FC, useCallback, useMemo, useState} from 'react';

import {AbonementType} from '../../../interfaces/abonement';
import {ControlButtons} from '../../../pages/abonements/all-abonements/components/control-buttons/control-buttons';
import {setCheckedAbonementUpdate} from '../../../redux/reducers/abonement-reducer';
import {setScheduleEditorTime} from '../../../redux/reducers/schedule-editor-settings-daily-reducer';
import {useAppDispatch} from '../../../redux/store';
import {fetchRemoveAbonement} from '../../../redux/thunks/abonements/remove-abonement';
import {convertPriceToString} from '../../../shared/utils/converter-price';
import {orgLogoSmall} from '../../../shared/utils/get-images-size';
import {isPlatformAbonement} from '../../../shared/utils/is-platform-abonement';
import {activateScroll, disabledScroll} from '../../../shared/utils/scroll-body';
import {getAbonementTime} from '../../../shared/utils/time/converter-time';
import {ButtonNew, ButtonWithLineBottom} from '../../buttons-new';
import {
	ModalPaymentsAbonementMoreOneService
} from '../../order/modals/modal-payments-abonement/with-more-one-services/modal-payments-abonement-more-one-service';
import {
	ModalPaymentsAbonementOneService
} from '../../order/modals/modal-payments-abonement/with-one-service/modal-payments-abonement-one-service';
import {getLimitAbonement} from '../../order/modals/utils';
import IconTime from '../../svg-icon-components/icon-time';
import {IconLock} from '../../svg-icon-components/Lock';
import * as SC from './abonement-new.style';
import {DetailWrapper} from './components/detail-wrapper/detail-wrapper';
import {ModalDetails} from './components/modal-details-abonement/modal-details-abonement';
import {Name} from './components/name/name';
import {OrgInfo} from './components/org-info/org-info';

interface AbonementNewProps {
	abonement: AbonementType;
	refetch?: () => void;
	showButtonsControl?: boolean;
	className?: string
}


export const AbonementNew: FC<AbonementNewProps> = ({abonement, refetch, showButtonsControl, className}) => {
	const BG_ICON = 'var(--yellow)';
	const dispatch = useAppDispatch();
	const [showDetails, setShowDetails] = useState(false);
	const [showDetailsAbonement, setShowDetailsAbonement] = useState<AbonementType | null>(null);
	const [openPymentWindow, setOpenPymentWindow] = useState(false);

	const onClickBye = useCallback(() => {
		setShowDetails(false);
		if(openPymentWindow){
			setOpenPymentWindow(false);
			activateScroll();
			return;
		}
		setOpenPymentWindow(true);
		disabledScroll();
	}, [openPymentWindow]);

	const closeWindow = useCallback(() => {
		setShowDetailsAbonement(null);
		setShowDetails(false);
		activateScroll();
	}, []);
	const clickShowDetails = useCallback(() => {
		setShowDetailsAbonement(abonement);
		setShowDetails(true);
		disabledScroll();
	}, [abonement]);
	const clickUpdateAbonement = () => {
		dispatch(setCheckedAbonementUpdate(abonement));
		dispatch(setScheduleEditorTime({['startAt']: abonement.info.working_from}));
		dispatch(setScheduleEditorTime({['endAt']: abonement.info.working_to}));
	};

	const clickDeleteAbonement = () => {
		const issuer = abonement.info.issuer;

		dispatch(fetchRemoveAbonement({
			id: abonement.id || '',
			issuer,
		})).unwrap().then(() => {
			if (refetch) {
				refetch();
			}
		});
	};

	const uniqueServices = useMemo(() => abonement.service_types.filter((service, index, self) =>
		index === self.findIndex((s) => s.trim() === service.trim())
	), [abonement]);
	const timeLabel = getAbonementTime(abonement);

	return (
		<SC.Container showButtonsControl={showButtonsControl} className={className}>
			{showButtonsControl && <ControlButtons clickUpdateAbonement={clickUpdateAbonement}
												   clickDeleteAbonement={clickDeleteAbonement}/>}
			<SC.ContentWrapper>
				<Name title={abonement.info.name}/>
				<SC.Hr/>
				<OrgInfo logo={orgLogoSmall(abonement.org_info.logo)} orgName={abonement.org_info.org_name}
						 address={isPlatformAbonement(abonement.info.issuer) && abonement.platform_info
							 ? `${abonement.platform_info.city}, ${abonement.platform_info.location_info.address}` : 'Клубная карта'}/>
				<SC.DataContainer>
					<DetailWrapper label={'Количество занятий'} icon={<IconLock backgroundColor={BG_ICON}/>}
								   text={abonement.info.limit !== null ? abonement.info.limit.toString() : 'Безлимитно'}
								   unLimit={abonement.info.limit === null}/>
					<DetailWrapper label={'Длительность абонемента'} icon={<IconTime backgroundColor={BG_ICON}/>}
								   text={getLimitAbonement(abonement.info.duration)}/>
					<SC.TextHoverWrapper>
						{timeLabel &&
							<DetailWrapper label={'Время для посещения'} icon={<IconTime backgroundColor={BG_ICON}/>}
										   text={timeLabel}/>}
					</SC.TextHoverWrapper>
				</SC.DataContainer>
				{uniqueServices.length > 0 && <SC.HasAbonement>Доступно в абонементе:</SC.HasAbonement>}
				<SC.ListServices>
					{uniqueServices.map(i => <li key={i}>{i}</li>)}
				</SC.ListServices>
			</SC.ContentWrapper>
			<SC.ButtonsWrapper>
				{abonement.payment_method === 'CASH'
					? <SC.Price>Цена: {convertPriceToString(abonement.info.price)}</SC.Price>
					: <ButtonNew label={`Купить за ${convertPriceToString(abonement.info.price)}`} bgColor={'green'}
								 onClick={onClickBye}/>}
				<ButtonWithLineBottom label={'Подробности абонемента'} onClick={clickShowDetails} defaultColor={'green'}/>
			</SC.ButtonsWrapper>
			{openPymentWindow && abonement.service_types.length === 1 &&
				<ModalPaymentsAbonementOneService abonement={abonement} onClickClose={onClickBye}/>}
			{openPymentWindow && abonement.service_types.length > 1 &&
				<ModalPaymentsAbonementMoreOneService abonement={abonement} onClickClose={onClickBye}/>}
			{(showDetails && showDetailsAbonement !== null) &&
				<ModalDetails abonement={showDetailsAbonement} closeWindow={closeWindow}
							  setOpenPymentWindow={onClickBye} setShowDetailsAbonement={setShowDetailsAbonement}/>}
		</SC.Container>
	);
};
