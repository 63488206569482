import {useCallback, useEffect, useState} from 'react';
import Headroom from 'react-headroom';
import {useDispatch} from 'react-redux';
import {useMediaQuery} from 'react-responsive';

import {setIsAuthUser} from '../../../redux/reducers/user-data-reducer';
import {isAuth} from '../../../shared/utils/save-local-storage';
import {activateScroll, disabledScroll} from '../../../shared/utils/scroll-body';
import {BurgerMenu} from './components/burger-menu/burger-menu';
import {BurgerMenuButton} from './components/burger-menu-button/burger-menu-button';
import {CityInfo} from './components/city-info/city-info';
import {Logo} from './components/logo/logo';
import {NavBar} from './components/navbar/navbar';
import {UserInfo} from './components/user-info/user-info';
import * as SC from './header.style';

export const Header = () => {
	const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
	const dispatch = useDispatch();
	const isMobileMod = useMediaQuery({query: '(max-width: 1130px)'});

	useEffect(() => {
		if (isAuth()) {
			dispatch(setIsAuthUser());
		}
	}, [dispatch]);

	const handleToggleBurgerMenu = useCallback(() => {
		setIsBurgerMenuOpen((prev) => {
			if (prev) {
				activateScroll();
				return false;
			}
			disabledScroll();
			return true;
		});
	}, []);

	return (
		<Headroom style={{zIndex: 999999}}>
			<SC.Header>
				<SC.HeaderContent>
					{isMobileMod && <>
						<SC.HeaderContentAdaptive>
							<Logo/>
							<SC.HeaderControls>
								<CityInfo/>
								<UserInfo/>
								<BurgerMenuButton onClick={handleToggleBurgerMenu}/>
							</SC.HeaderControls>
						</SC.HeaderContentAdaptive>
						<NavBar/>
					</>}
					{!isMobileMod && <>
						<Logo/>
						<NavBar/>
						<SC.HeaderControls>
							<CityInfo/>
							<UserInfo/>
							<BurgerMenuButton onClick={handleToggleBurgerMenu}/>
						</SC.HeaderControls>
					</>}
				</SC.HeaderContent>
				<BurgerMenu isOpen={isBurgerMenuOpen} setIsOpen={handleToggleBurgerMenu}/>
			</SC.Header>
		</Headroom>
	);
};
