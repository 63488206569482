import {createSlice} from '@reduxjs/toolkit';

import {AbonementType} from '../../interfaces/abonement';
import {IOrder} from '../../interfaces/order';
import {ServiceListItem} from '../../interfaces/service';
import {fetchGetAbonements} from '../thunks/pagination/get-abonements';
import {getHistoryAbonementPagination} from '../thunks/pagination/get-history-abonement';
import {getHistoryOrdersPagination} from '../thunks/pagination/get-history-orders-pagination';
import {fetchGetServices} from '../thunks/pagination/get-services';


interface InitialState {
    services: ServiceListItem[];
    abonements: AbonementType[];
    abonementsHistory: { abonement: AbonementType, expire_at: string }[];
    historyOrders: IOrder[];
}

const initialState: InitialState = {
    services: [],
    abonements: [],
    historyOrders: [],
    abonementsHistory: [],
};

export const paginationSlice = createSlice({
    name: 'paginationSlice',
    initialState,
    reducers: {
        resetServices(state: InitialState) {
            state.services = [];
        },
        resetAbonements(state: InitialState) {
            state.abonements = [];
        },
        resetAbonementsHistory(state: InitialState) {
            state.abonementsHistory = [];
        },
        resetHistoryOrders(state: InitialState) {
            console.log(111);
            state.historyOrders = [];
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchGetServices.fulfilled, (state, action) => {
            if (action.payload) {
                state.services = [...state.services, ...action.payload.data];
            }
        });
        builder.addCase(fetchGetAbonements.fulfilled, (state, action) => {
            if (action.payload) {
                state.abonements = [...state.abonements, ...action.payload.data];
            }
        });
        builder.addCase(getHistoryOrdersPagination.fulfilled, (state, action) => {
            if (action.payload) {
                state.historyOrders = [...state.historyOrders, ...action.payload.data];
            }
        });
        builder.addCase(getHistoryAbonementPagination.fulfilled, (state, action) => {
            if (action.payload) {
                state.abonementsHistory = [...state.abonementsHistory, ...action.payload.data];
            }
        });
    },
});

export const {resetServices, resetAbonements, resetHistoryOrders, resetAbonementsHistory} = paginationSlice.actions;
