import {createAsyncThunk} from '@reduxjs/toolkit';

import {history} from '../../../api/history';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetOrdersActive = createAsyncThunk(
	'fieldSlice/fetchGetOrderActive',
	async (userId: string, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await history.getOrdersActive(userId);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				return {status: response.status, data: response.data.orders};
			}
			return {status: response.status, data: []};
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
