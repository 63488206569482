import {createAsyncThunk} from '@reduxjs/toolkit';

import {SearchPayment} from '../../../api/search-payment';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetBalanceHistoryTotal = createAsyncThunk(
	'paymentSlice/fetchGetBalanceHistoryTotal',
	async (userId:  string, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPayment.getBalanceHistoryTotal(userId);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				return {status: response.status, data: response.data.total};
			}

		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
