import {TFullUserRole} from '../../interfaces/role';
import {LOCAL_STORAGE_KEY_MAP} from '../../shared/constants/local-storage';
import {getLocalStorage} from '../../shared/utils/save-local-storage';
import {RootState} from '../store';

export const isShowLoaderSelector = (state: RootState) => state.userState.isShowLoader;
export const dataMessageSelector = (state: RootState) => state.userState.messageUser;
export const dataUserSelector = (state: RootState) => state.userState.userData;
export const userDataForAdminSelector = (state: RootState) => state.userState.userDataForAdmin;
export const dataUserBalanceSelector = (state: RootState) => state.userState.userData.balance;
export const dataUsermetaSelector = (state: RootState) => state.userState.userMeta;
export const isShowMessageSelector = (state: RootState) => state.userState.isShowMessage;
export const isAuthSelector = (state: RootState) => state.userState.isAuth;
export const allUsersSelector = (state: RootState) => state.userState.allUsers;
export const checkedUserRolesSelector = (state: RootState): TFullUserRole[] => state.userState.checkedUserFullRoles || [];
export const viewedUserRolesSelector = (state: RootState): TFullUserRole[] => state.userState.viewedUserFullRoles || [];
export const allUsersTotalCountSelector = (state: RootState) => state.userState.totalCount;
export const nickUserSelector = (state: RootState) => state.userState.nickName;

export const blackListPlatformSelector = (state: RootState) => state.userState.allBlackUsers;
export const blackListTotalCountSelector = (state: RootState) => state.userState.allBlackTotalCount;
export const commentariesUser = (state: RootState) => state.userState.commentaries;
export const requestStatusSelector = (state: RootState) => state.userState.status;
export const historyOrdersSelector = (state: RootState) => state.userState.historyOrders;
export const historyOrdersTotalSelector = (state: RootState) => state.userState.historyOrdersTotal;

export const selectUserCity = (state: RootState) => state.userState.city;
export const selectAvatars = (state: RootState) => state.userState.avatars;
export const selectedAdminFullRole = (state: RootState): TFullUserRole => state.userState.selectedAdminFullRole || getLocalStorage(LOCAL_STORAGE_KEY_MAP.selectedFullRole);
