import styled from '@emotion/styled';

export const LogoImage = styled.img`
	width: fit-content;
	height: 50px;
	min-height: 50px;

	@media screen and (max-width: 800px) {
		height: 40px;
		min-height: 40px;
	}
`;
