import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const ButtonPhone = css`
	padding: 8px 24px;
`;
export const OrderButton = css`
	height: 38px;
`;
export const ContainerButtons = styled.div`
	display: flex;
	flex-direction: column;
	gap: var(--Spacing-desctop-Body-M);
	height: 84px;
	justify-content: flex-end;
`;
