import classNames from 'classnames';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import {SingleSelect} from '../../../../../components';
import IconSporder from '../../../../../components/svg-icon-components/icon-sporder';
import {setUserCity} from '../../../../../redux/reducers/user-data-reducer';
import {useAppSelector} from '../../../../../redux/selectors/hook';
import {arrCitiesSelector} from '../../../../../redux/selectors/sport-state-selectors';
import {selectUserCity} from '../../../../../redux/selectors/user-state-selectors';
import {LOCAL_STORAGE_KEY_MAP} from '../../../../../shared/constants/local-storage';
import {useClickOutside} from '../../../../../shared/hooks/use-click-outside';
import {getLocalStorage, saveLocalStorage} from '../../../../../shared/utils/save-local-storage';
import styles from './city-info.module.css';
import {ALL_CITIES_OPTION} from './constants';

export const CityInfo = () => {
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedCity, setSelectedCity] = useState<string | null>(null);
	const city = useAppSelector(selectUserCity);
	const cities = useAppSelector(arrCitiesSelector);
	const urlParams = new URLSearchParams(location.search);

	useEffect(() => {
		setSelectedCity(city);
	}, [city]);

	useEffect(() => {
		if (getLocalStorage('city')) {
			dispatch(setUserCity(getLocalStorage('city')));
		}
	}, []);

	const handleToggle = useCallback(
		(isOpen: boolean) => {
			if (!isOpen) {
				setSelectedCity(city);
			}

			setIsOpen(isOpen);
		},
		[city],
	);

	const elementRef = useRef<HTMLDivElement>(null);
	useClickOutside(elementRef, () => handleToggle(false));

	const handleCitySave = useCallback(() => {
		saveLocalStorage(LOCAL_STORAGE_KEY_MAP.city, selectedCity);
		dispatch(setUserCity(selectedCity));
		handleToggle(false);
		if (selectedCity === null) {
			urlParams.delete('city');
		} else {
			urlParams.set('city', selectedCity);
		}
		navigate(`${location.pathname}?${urlParams.toString()}`, {replace: true});
	}, [selectedCity]);

	const handleCityChange = useCallback((city: string) => {
		setSelectedCity(city === ALL_CITIES_OPTION.value ? null : city);
	}, []);

	const citiesOptions = cities.map((city) => ({
		value: city,
		label: city,
	}));

	const fullCitiesOptions = citiesOptions.length > 1 ? [ALL_CITIES_OPTION, ...citiesOptions] : citiesOptions;

	return (
		<div className={styles.city_info} ref={elementRef}>
			<div className={styles.city_info_content} onClick={() => handleToggle(!isOpen)}>
				<IconSporder width={16} height={22} strokeColor="#404040"/>
				<p className={styles.city_info_title}>
					{city === null || cities.every((el) => el !== city) ? ALL_CITIES_OPTION.label : city}
				</p>
			</div>
			{isOpen ? (
				<div className={styles.city_info_details} onClick={(event) => event.stopPropagation()}>
					<p className={styles.city_info_details_label}>Выберите ваш город</p>
					<SingleSelect
						value={
							selectedCity === null || cities.every((el) => el !== selectedCity) ? ALL_CITIES_OPTION.value : selectedCity
						}
						options={fullCitiesOptions.map((option) => ({
							...option,
							className: styles.city_info_details_select_option,
						}))}
						containerClassName={styles.city_info_details_select}
						optionsContainerClassName={styles.city_info_details_select_options_container}
						onChange={handleCityChange}
					/>
					<button
						className={classNames('btn_yellow', 'btn_yellow_highlight', styles.submit_button)}
						type="button"
						onClick={handleCitySave}
					>
						Сохранить
					</button>
				</div>
			) : null}
		</div>
	);
};
