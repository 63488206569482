import {createAsyncThunk} from '@reduxjs/toolkit';

import {GetServicesByFilterParams} from '../../../api/query-params';
import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';
import {ServicesResponse} from '../services/get-services-by-filter';

export const fetchGetServices = createAsyncThunk<
	ServicesResponse,
	GetServicesByFilterParams
>(
	'paginationSlice/fetchGetServices',
	async (params, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getServicesByFilter(params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === requestCodes.success) {
				return {status: response.status, data: response.data.services};
			}

			if (response.status === requestCodes.emptyData) {
				return {status: response.status, data: []};
			}
			return {status: response.status, data: []};
		} catch (error) {
			return 	handleAxiosError({dispatch, rejectWithValue, error});
		}
	}
);
