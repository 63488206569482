import {Dispatch, SetStateAction} from 'react';
import {NavigateFunction} from 'react-router-dom';

import {DataForPayment} from '../../../components/order/modals/modal-anonim-order/modal-anonim-order';
import {OrderType} from '../../../interfaces/enums';
import {OrderCreateFieldKey, TOrderClaimCreate} from '../../../interfaces/order';
import {ServiceListItem, ServiceSingle} from '../../../interfaces/service';
import {AppDispatch} from '../../../redux/store';
import {fetchGetHistoryOrdersUser} from '../../../redux/thunks/history/get-history-orders-user-thunk';
import {fetchGetSingleOrder} from '../../../redux/thunks/order/get-single-order';
import {fetchSetOrderClaimUser, FetchSetOrderClaimUserResponse} from '../../../redux/thunks/order/set-order-claim-user';
import {fetchSetOrderUser} from '../../../redux/thunks/order/set-order-user-thunk';
import {getIdUser, isRightTokenTime} from '../../../shared/utils/decoder-jwt';
import {isAuth} from '../../../shared/utils/save-local-storage';
import {disabledScroll} from '../../../shared/utils/scroll-body';
import {coderTimeIn5FromString, converterTimeLocalString} from '../../../shared/utils/time/converter-time';
import {formatDateString} from '../../order-page/utils/convert-date';

interface HandleOrderClickParams {
	service: ServiceSingle | ServiceListItem;
	quarterTime: string;
	dayDate: Date;
	endDate: Date;
	startDate: Date;
	duration: number;
	amount: number;
	trial: boolean;
	setOrderAccepted?: Dispatch<SetStateAction<boolean>>;
	setOpenWindowOrder: Dispatch<SetStateAction<string | undefined>>;
	dispatch: AppDispatch;
	navigate: NavigateFunction;
}

export const handleOrderClick = ({
									 service, quarterTime, dayDate, duration, amount, trial,
									 setOrderAccepted, dispatch, endDate, startDate, setOpenWindowOrder,
								 }: HandleOrderClickParams) => {
	const params = {
		[OrderCreateFieldKey.from]: coderTimeIn5FromString(quarterTime),
		[OrderCreateFieldKey.service_id]: service.id,
		[OrderCreateFieldKey.date]: converterTimeLocalString(dayDate),
		[OrderCreateFieldKey.duration]: duration + (service.order_policy.periodic?.min_order_duration || 0),
		[OrderCreateFieldKey.amount]: amount,
		[OrderCreateFieldKey.attached]: [],
		[OrderCreateFieldKey.description]: '',
		[OrderCreateFieldKey.platform_id]: service.platform_info.platform_id,
		[OrderCreateFieldKey.trial_order]: trial,
		[OrderCreateFieldKey.requester]: {
			Registered: {id: getIdUser()}
		},
	};
	if (!isAuth() || !isRightTokenTime()) {
		if (setOpenWindowOrder) {
			setOpenWindowOrder('anonim');
		}
		return;
	}
	const allows = service.order_policy.allows;

	if (allows === OrderType.request) {
		dispatch(fetchSetOrderClaimUser({allow: allows as OrderType, params}))
			.then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const payload = res.payload as FetchSetOrderClaimUserResponse;
					if (payload.status === 200) {
						dispatch(fetchGetSingleOrder(payload.data.id));
						if (setOrderAccepted) {
							setOrderAccepted(true);
						}
						setOpenWindowOrder('claim');
						disabledScroll();
					}
				}
				dispatch(fetchGetHistoryOrdersUser({
					user: getIdUser(),
					start_date: formatDateString(startDate),
					end_date: formatDateString(endDate)
				}));
			});
	} else if (allows === OrderType.abonementOnly) {
		dispatch(fetchSetOrderClaimUser({allow: allows as OrderType, params: {...params, trial_order: true}}))
			.then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const payload = res.payload as FetchSetOrderClaimUserResponse;
					if (payload.status === 200) {
						dispatch(fetchGetSingleOrder(payload.data.id));
						if (setOrderAccepted) {
							setOrderAccepted(true);
						}
						setOpenWindowOrder('claim');
						disabledScroll();
						dispatch(fetchGetHistoryOrdersUser({
							user: getIdUser(),
							start_date: formatDateString(startDate),
							end_date: formatDateString(endDate)
						}));
					}
				}
			});
	} else if (allows === OrderType.book) {
		dispatch(fetchSetOrderUser({
			allow: OrderType.book,
			params: {
				...params,
				requester: {User: {info: {Registered: {id: getIdUser()}}}},
				[OrderCreateFieldKey.attached]: []
			}
		})).unwrap().then(res => {
			if (res?.status === 200) {
				dispatch(fetchGetSingleOrder(res.data));
				setOpenWindowOrder('book');
				disabledScroll();
				dispatch(fetchGetHistoryOrdersUser({
					user: getIdUser(),
					start_date: formatDateString(startDate),
					end_date: formatDateString(endDate)
				}));
			}
		});
	}
};

interface HandleOrderAfterRegister {
	params: TOrderClaimCreate;
	dispatch: AppDispatch;
	endDate: string;
	startDate: string;
	allows: string;
	setOpenWindowOrder: Dispatch<SetStateAction<string | undefined>>;
}

export const handleOrderAfterRegister = async ({
												   dispatch, params, startDate, endDate, allows, setOpenWindowOrder
											   }: HandleOrderAfterRegister) => {

	if (allows === OrderType.request) {
		dispatch(await fetchSetOrderClaimUser({allow: allows as OrderType, params}))
			.then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const payload = res.payload as FetchSetOrderClaimUserResponse;
					if (payload.status === 200) {
						dispatch(fetchGetSingleOrder(payload.data.id));
						setOpenWindowOrder('claim');
						disabledScroll();
					}
				}
				dispatch(fetchGetHistoryOrdersUser({
					user: getIdUser(),
					start_date: startDate,
					end_date: endDate,
				}));
			});
	} else if (allows === OrderType.abonementOnly) {
		dispatch(await fetchSetOrderClaimUser({allow: allows as OrderType, params: {...params, trial_order: true}}))
			.then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const payload = res.payload as FetchSetOrderClaimUserResponse;
					if (payload.status === 200) {
						dispatch(fetchGetSingleOrder(payload.data.id));
						setOpenWindowOrder('claim');
						disabledScroll();
						dispatch(fetchGetHistoryOrdersUser({
							user: getIdUser(),
							start_date: startDate,
							end_date: endDate,
						}));
					}
				}
			});
	}
};

interface OnOrderAnonym {
	service: ServiceSingle | ServiceListItem;
	successOrder: (value: string) => void;
	phone: string;
	dispatch: AppDispatch;
	params: TOrderClaimCreate;
	setDataOrder: (value: DataForPayment)=> void;
}


export const onOrderAnonym = ({successOrder, service, dispatch, params, phone, setDataOrder}: OnOrderAnonym) => {

	const allows = service.order_policy.allows;
	if (allows === OrderType.request) {
		dispatch(fetchSetOrderClaimUser({allow: allows as OrderType, params}))
			.then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const payload = res.payload as FetchSetOrderClaimUserResponse;
					if (payload.status === 200) {
						successOrder('claim');
					}
				}
			});
	}
	if (allows === OrderType.abonementOnly) {
		dispatch(fetchSetOrderClaimUser({allow: allows as OrderType, params}))
			.then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const payload = res.payload as FetchSetOrderClaimUserResponse;
					if (payload.status === 200) {
						successOrder('claim');
					}
				}
			});
	}
	if (allows === OrderType.book) {
		dispatch(fetchSetOrderUser({
			allow: OrderType.book,
			params: {
				...params,
				requester: {
					User: {info: {Anonym: {phone}}}
				},
				[OrderCreateFieldKey.attached]: []
			}

		})).unwrap().then(res => {
			if (res?.status === 200) {
				setDataOrder({phone, orderId: res.data});
				successOrder('book');
			}
		});
	}
};
