import {createSlice} from '@reduxjs/toolkit';

import {ServiceSingle} from '../../interfaces/service';
import {fetchGetOrderAcceptServiceById} from '../thunks/playfields-order-accept-service-thunk';

interface InitialState {
	getCheckedService: ServiceSingle,
}

const initialState: InitialState = {
	getCheckedService: {} as ServiceSingle,
};

export const orderAttachSlice = createSlice({
	name: 'orderAttachSlice',
	initialState,
	reducers: {
		setClearCheckedService: (state) => {
			state.getCheckedService = {} as ServiceSingle;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchGetOrderAcceptServiceById.fulfilled, (state, action) => {
			state.getCheckedService = action.payload?.data;
		});
	},
});

export const {setClearCheckedService,} = orderAttachSlice.actions;
export const orderAttachReducer = orderAttachSlice.reducer;
