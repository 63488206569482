import {FC, useCallback} from 'react';
import {useForm} from 'react-hook-form';

import {IOrder} from '../../../../../interfaces/order';
import {useAppDispatch} from '../../../../../redux/store';
import {fetchCloseOrder} from '../../../../../redux/thunks/close-order-thunk';
import {getIdUser} from '../../../../../shared/utils/decoder-jwt';
import {ButtonWithBorder} from '../../../../buttons-new';
import {Radio} from '../../../../controls-new/radio/radio';
import {TextareaNew} from '../../../../controls-new/textarea-new/textarea-new';
import {OrderCard} from '../../../../filtering-table/components/order-card/order-card';
import {ModalNew} from '../../../../modal-new/modal-new';
import {Title} from '../title/title';
import * as SC from './cancel-order.style';

interface Props {
	order: IOrder;
	onClickClose: () => void;
	refetch?: () => void;
	idUser?: string;
}

const confirm = ['Услуга забронирована ошибочно', 'Поменялись планы', 'Не устраивает цена', 'Не подходит время', 'Другое'];


export const CancelOrder: FC<Props> = ({order, onClickClose, refetch, idUser}) => {
	const dispatch = useAppDispatch();
	const userId = getIdUser();
	const {register, watch, setValue} = useForm({
		defaultValues: {
			confirm_different: '',
			confirm: confirm[0],
		}
	});

	const handleCancelOrder = async () => {
		const ownedBy = 'Registered' in order.order_details.owned_by ? order.order_details.owned_by.Registered.id : order.order_details.owned_by.Anonym.id;

		dispatch(fetchCloseOrder({
			owned_by: ownedBy,
			order_id: order.id,
			requester: userId || idUser,
			platform_id: order.host_info.platform_id,
			reason: watch('confirm') === confirm.at(-1) ? watch('confirm_different') : watch('confirm'),
		})).unwrap().then(()=>{
			if (refetch) {
				refetch();
			}
		});
		onClickClose();
	};

	const handlePlatformChange = useCallback((value: string) => {
		const currentStatus = watch('confirm');
		if (value === currentStatus) {
			setValue('confirm', '');
		} else {
			setValue('confirm', value);
		}
	}, [watch('confirm')]);

	return (
		<ModalNew onClickClose={onClickClose} classNameWrapper={SC.Modal} classNameContainer={SC.ContainerModal}>
			<Title title={'Отмена заказа'}/>
			<SC.OrderCardwrapper>
				<SC.ConfirmCancel>Очень жаль. Вы уверены, что хотите отменить заказ?</SC.ConfirmCancel>
				<OrderCard order={order} notShowButton/>
			</SC.OrderCardwrapper>
			<SC.ReasonsWrapper>
				{confirm.map(item => {
					return (
						<Radio key={item} checked={watch('confirm') === item}
							   onChange={() => handlePlatformChange(item)} label={item} value={item}/>
					);
				})}
				{watch('confirm') === confirm.at(-1) &&
					<TextareaNew {...register('confirm_different')} placeholder={'Напишите причину'}
								 label={'Напишите причину'} autoFocus/>}
			</SC.ReasonsWrapper>
			<ButtonWithBorder label={'Отменить заказ'} borderColor={'yellow'} onClick={handleCancelOrder}/>
		</ModalNew>
	);
};
