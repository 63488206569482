import {FC, memo, ReactNode} from 'react';

import * as SC from './main-page.style';

interface Props {
	children: ReactNode;
}

export const MainPage:FC<Props> = memo(({children}) => {
	return (
		<SC.Main>{children}</SC.Main>
	);
});
