import {FC, memo, useCallback, useEffect, useState} from 'react';

import {Rewards} from '../../../../interfaces/payment';
import {ServiceListItem, ServiceSingle} from '../../../../interfaces/service';
import {useAppSelector} from '../../../../redux/selectors/hook';
import {useAppDispatch} from '../../../../redux/store';
import {fetchGetConfig} from '../../../../redux/thunks/payment/get-config';
import {fetchSetPayment} from '../../../../redux/thunks/payment/set-payment';
import {LOGIN, REGISTER} from '../../../../shared/constants/path';
import {ButtonNew} from '../../../buttons-new';
import {Link, LinkWithBorder} from '../../../links-navigate';
import {ModalNew} from '../../../modal-new/modal-new';
import {PaymentMethods} from '../../../payment-methods/payment-methods';
import {BonusesIconText} from '../components/bonuses-icon-text/bonuses-icon-text';
import {ForPayment} from '../components/for-payment/for-payment';
import {OrderDetail} from '../components/order-detail/order-detail';
import {OrgInfo} from '../components/org-info/org-info';
import {TitleOrderFast} from '../components/title-order-fast/title-order-fast';
import {DataForPayment} from '../modal-anonim-order/modal-anonim-order';
import {getBonusesWordIcon} from '../utils';
import * as SC from './fast-order-success.style';

interface Props {
	service: ServiceListItem | ServiceSingle;
	onClickClose: () => void;
	goToAuth: () => void;
	time: string;
	price: string;
	dataForPayment: DataForPayment;
	rewards: Rewards;
	order_reward: number;
}

export const FastOrderSuccess: FC<Props> = memo(({
													 service, onClickClose, time, price, goToAuth,
													 rewards, order_reward, dataForPayment,
												 }) => {

	const dispatch = useAppDispatch();
	const [useProvider, setUseProvider] = useState('Oplati');
	const bonuses = useAppSelector(state => state.payment.config);

	useEffect(() => {
		dispatch(fetchGetConfig());
	}, []);

	const onChangeServicePayment = useCallback((value: string) => setUseProvider(value), []);

	const onClickBye = useCallback(() => {
		dispatch(fetchSetPayment({
			issuer: {Order: {id: dataForPayment.orderId}},
			requester: {Anonym: {phone: dataForPayment.phone}},
			provider: useProvider,
			success_url: `${window.location.protocol}//${window.location.host}/payment-abonement-success/${service.id}`,
			fail_url: `${window.location.protocol}//${window.location.host}/payment-abonement-fail/${service.id}`,
		}));
	}, [useProvider, dataForPayment.orderId, dataForPayment.phone]);

	return (
		<ModalNew onClickClose={onClickClose} classNameWrapper={SC.Modal}>
			<TitleOrderFast order_reward={order_reward}/>
			<SC.OrderInfoContainer>
				<OrgInfo org_logo={service.org_info.logo} org_name={service.org_info.org_name}
						 address={service.platform_info.location_info.address}/>
				<SC.ServiceType>{service.service_type}</SC.ServiceType>
				<SC.DetailWrapper>
					<OrderDetail detailLabel={'Вид спорта'}
								 children={<SC.ServiceType>{service.sports.join(', ')}</SC.ServiceType>}/>
					<OrderDetail detailLabel={'Дата и Время'} children={<SC.DetailDate>{time}</SC.DetailDate>}/>
				</SC.DetailWrapper>
				<SC.BonusesIconWrapper>
					<BonusesIconText rewards={rewards} price={parseFloat(price) * 100} balance={0}
									 useAllBonuses={false}/>
				</SC.BonusesIconWrapper>
			</SC.OrderInfoContainer>
			<SC.Price>{price}</SC.Price>
			<SC.PaymentWrapper>
				<ForPayment/>
				<ButtonNew label={'Оплатить'} onClick={onClickBye}/>
				<SC.RecommendationPaymentLater>Вы сможете оплатить заказ позже в разделе "Мои заказы" вашего личного
					кабинета.</SC.RecommendationPaymentLater>
			</SC.PaymentWrapper>
			<PaymentMethods onChangeServicePayment={onChangeServicePayment} value={useProvider}
							discount={getBonusesWordIcon(parseFloat(price) / 100 * bonuses.rewards.oplati_payment_reward_coef)}/>
			<SC.BenefitWrapper>
				<SC.BenefitTitleWrapper>
					<SC.BenefitTitle>Не упускайте выгоду! </SC.BenefitTitle>
					<SC.BenefitRecommendation>Войдите в свой аккаунт или зарегистрируйтесь,
						чтобы оплачивать услуги бонусами и экономить.</SC.BenefitRecommendation>
				</SC.BenefitTitleWrapper>
				<SC.BenefitWrapperLinks>
					<LinkWithBorder label={'Зарегестрироваться'} path={REGISTER} onClick={goToAuth}/>
					<Link label={'Войти'} path={LOGIN} click={goToAuth}/>
				</SC.BenefitWrapperLinks>
			</SC.BenefitWrapper>
		</ModalNew>
	);
});
