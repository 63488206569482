import styled from '@emotion/styled';

export const BonusesIconText = styled.span`
	display: flex;
	flex-direction: column;
	align-items: center;
	rotate: 4deg;
	color: var(--text-Accent);
	text-align: center;
	font-size: 17px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Container = styled.div`
	position: relative;
	width: fit-content;
	rotate: 4deg;
	max-width: 210px;
`;
export const Wrapper = styled.div`
	background-color: var(--background-Green-weak);
	border-radius: var(--Corner-L);
	box-shadow: 8px 8px 5px 0 rgba(86, 129, 116, 0.05);
	padding: 24px;
	margin-bottom: 20px;
	width: fit-content;
	height: fit-content;

	&::before,
	&::after {
		content: '';
		position: absolute;
		background-color: var(--background-Alternative);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		left: 10px;
		top: 4px;
		z-index: -1;
		transform: rotate(6deg);
	}

	&::after {
		transform: rotate(12deg);
		z-index: -2;
		left: 16px;
		top: 8px;
		background-color: #dfdfdf;
	}
`;
