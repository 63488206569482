import {FC, memo, useCallback, useEffect, useState} from 'react';

import {IOrder} from '../../../../interfaces/order';
import {useAppSelector} from '../../../../redux/selectors/hook';
import {dataUserBalanceSelector} from '../../../../redux/selectors/user-state-selectors';
import {useAppDispatch} from '../../../../redux/store';
import {fetchGetConfig} from '../../../../redux/thunks/payment/get-config';
import {fetchSetPayment} from '../../../../redux/thunks/payment/set-payment';
import {fetchGetUserById} from '../../../../redux/thunks/user/get-user-by-id';
import {convertPriceToString} from '../../../../shared/utils/converter-price';
import {getIdUser} from '../../../../shared/utils/decoder-jwt';
import {ButtonCheckbox} from '../../../controls-new/checkboxes/button-checkbox/button-checkbox';
import {ButtonNew, ButtonWithBorder} from '../../../index';
import {ModalNew} from '../../../modal-new/modal-new';
import {PaymentMethods} from '../../../payment-methods/payment-methods';
import {AddBonuses} from '../components/add-bonuses/add-bonuses';
import {OrderInfo} from '../components/order-info/order-info';
import {OrgInfo} from '../components/org-info/org-info';
import {Title} from '../components/title/title';
import {bonusesAvailable, calculateFinishPrice, canculateForPayment, canculateForPaymentOplati} from '../utils';
import * as SC from './modal-payment.style';

interface Props {
	order: IOrder;
	onClickClose: () => void;
}

export const ModalPayment: FC<Props> = memo(({order, onClickClose}) => {
	const order_details = order.order_details;
	const dispatch = useAppDispatch();
	const idUser = getIdUser();
	const balance = useAppSelector(dataUserBalanceSelector);
	const [useAllBonuses, setUseAllBonuses] = useState(false);
	const [useProvider, setUseProvider] = useState('Oplati');
	const bonuses = useAppSelector(state => state.payment.config);

	useEffect(() => {
		dispatch(fetchGetConfig());
		dispatch(fetchGetUserById(idUser));
	}, []);

	const onChangeServicePayment = useCallback((value: string) => setUseProvider(value), []);

	const clickUseAllBonuses = useCallback(() => setUseAllBonuses(prevState => !prevState), []);

	const clickPayOrder = useCallback(() => {
		dispatch(fetchSetPayment({
			issuer: {Order: {id: order.id}},
			provider: useProvider,
			requester: {Registered: {id: idUser}},
			success_url: `${window.location.protocol}//${window.location.host}/order-success/${order.id}`,
			fail_url: `${window.location.protocol}//${window.location.host}/order-fail/${order.id}`,
			used_bonus_amount: (useAllBonuses && balance > 0) ? (order_details.price >= balance ? balance : order_details.price) : undefined,
		}));
		onClickClose();
	}, [order_details.price, balance, order.id, useAllBonuses, useProvider]);

	return (
		<ModalNew onClickClose={onClickClose}>
			<Title title={'Оплата услуги'}/>
			<OrgInfo org_logo={order.host_info.org_logo} org_name={order.host_info.organization}
					 address={`${order.host_info.city}, ${order.host_info.location_info.address}`}/>
			<SC.OrderInfoWrapper>
				<OrderInfo from={order_details.from} price={convertPriceToString(order_details.price)}
						   date={order_details.date} sports={order.service_info.sports}
						   duration={order_details.duration} service_type={order.service_info.service_type}/>
				<SC.OrderInfo>
					<SC.BonusesWrapper>
						<SC.AvailableBonuses><span>Доступно</span>
							<SC.Bonuses>  {bonusesAvailable({
								price: order.order_details.price, balance, useAllBonuses
							})}</SC.Bonuses>
						</SC.AvailableBonuses>
						<ButtonCheckbox label={'Cписать'} id={'bonuses'} checked={useAllBonuses}
										handleCheckboxChange={clickUseAllBonuses}/>
					</SC.BonusesWrapper>
					<SC.FinishPriceWrapper>
						Итого <SC.FinishPrice>{calculateFinishPrice({
						price: order_details.price, balance, useAllBonuses
					})}</SC.FinishPrice>
					</SC.FinishPriceWrapper>
					<AddBonuses text={`${canculateForPayment({
						rewards: bonuses.rewards,
						balance,
						useAllBonuses,
						price: order.order_details.price
					})} за оплату`}/>
				</SC.OrderInfo>
			</SC.OrderInfoWrapper>
			<PaymentMethods onChangeServicePayment={onChangeServicePayment} value={useProvider}
							discount={canculateForPaymentOplati({
								balance,
								useAllBonuses,
								price: order.order_details.price
							})}/>
			<SC.ButtonsWrapper>
				<ButtonWithBorder label={'Отменить'} borderColor={'yellow'} className={SC.Button}/>
				<ButtonNew label={'Оплатить'} bgColor={'purple'} className={SC.Button} onClick={clickPayOrder}/>
			</SC.ButtonsWrapper>
		</ModalNew>
	);
});
