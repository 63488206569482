import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Block-S, 16px);
	align-self: stretch;
	width: fit-content;
	margin: 0 auto;
`;
export const Title = styled.h2`
	color: var(--text-B);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const ServiceContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;
export const ServiceLogo = styled.img`

`;
export const DescriptionServiceWrapper = styled(Container)`
	justify-content: center;
	gap: var(--Spacing-desctop-Body-S, 4px);
`;
export const OplatyTitle = styled.h3`
	color: var(--text-Strong);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const BePaidTitle = styled(OplatyTitle)`
	color: var(--text-Weak);
`;
export const WarningAfterPay = styled.p`
	color: var(--text-Strong);
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 14px;
`;
export const RadioWrapper = styled.div`
	div {
		margin-bottom: 28px;
	}
`;
export const OplatiWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-S);
`;
export const BonusesOplati = styled.span`
	padding: 4px;
	color: var(--text-Accent);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
	background-color: var(--background-Green-weak);
`;
