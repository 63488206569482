import styled from '@emotion/styled';

export const SelectImg = styled.img`
	width: 570px;
	aspect-ratio: 16/9;
	height: 100%;
	object-fit: cover;
	user-select: none;
	border-radius: var(--Corner-M);
`;
export const EmblaSlide = styled.div`
	transform: translate3d(0, 0, 0);
	flex: 0 0 100%;
	min-width: 0;
	width: fit-content;
	padding-left: 1rem;
	aspect-ratio: 16/9;
`;
export const EmblaContainer = styled.div`
	display: flex;
	touch-action: pan-y pinch-zoom;
	margin-left: calc(1rem * -1);
`;
export const EmblaViewport = styled.div`
	overflow: hidden;
`;
export const Embla = styled.div`
	width: 100%;
	margin: auto;
	--slide-height: 19rem;
	--slide-spacing: 10px;
	--slide-size: 100%;
`;
export const EmblaThumbs = styled.div`
	--thumbs-slide-spacing: 0.8rem;
	--thumbs-slide-height: 6rem;
	margin: 16px 0 0;
`;
export const EmblaThumbsViewport = styled.div`
	overflow: hidden;
	width: 310px;
	margin: 0 auto;
	position: relative;
	@media screen and (max-width: 600px){
		width: 240px;
	}
`;
export const EmblaThumbsContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: flex-end;
`;
export const ButtonNext = styled.button`
	border-radius: var(--Corner-S, 4px);
	border: 1px solid var(--border-Neutral);
	background-color: var(--background-Base);
	display: flex;
	width: 24px;
	height: 24px;
	padding: 4px;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: -40px;
	z-index: 10;
	top: 50%;
	transform: translateY(-50%);

	&:disabled {
		background-color: var(--button-Hover-B-Secondary);
		pointer-events: none;
	}

	@media (hover: hover) {
		&:hover {
			border: 1px solid var(--border-Neutral);
			background-color: var(--button-Hover-B-Secondary);;
		}
	}
`;
export const IconButtonNext = styled.img`
	rotate: 90deg;
`;
export const ButtonPrev = styled(ButtonNext)`
	right: -40px;
	left: auto;
	z-index: 10;
	top: 50%;
	transform: translateY(-50%);
`;
export const IconButtonNPrev = styled.img`
	rotate: 270deg;
`;
export const EmblaThumbsViewportWrapper= styled.div`
	width: fit-content;
	position: relative;
	margin: 0 auto;
`;
