import styled from '@emotion/styled';
interface IconWrapperInterface {
	showAllServices?: boolean;
}
export const IconWrapper = styled.span<IconWrapperInterface>`
	transition: 0.7s ease-in-out;
	transform: rotate(${props => props.showAllServices ? '200grad' : 0});
`;
export const Vector = styled.img<IconWrapperInterface>`
	height: 30px;
	width:30px;
	filter: brightness(10%) grayscale(100%) invert(100%);
`;
