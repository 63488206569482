import {css} from '@emotion/css';
import styled from '@emotion/styled';

import {isAuthRightToken} from '../../../../../shared/utils/save-local-storage';

export const AbonementInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: var(--Corner-L, 16px);
`;
export const Name = styled.h2`
	color: var(--text-Accent, #1B1B1B);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
`;
export const Price = styled.span`
	color: var(--text-G, #1C8666);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
`;
export const Modal = css`
	max-width: 720px !important;
	@media screen and (max-width: 760px) {
		gap: var(--Spacing-desctop-Block-M, 24px) !important;
	}
	@media screen and (max-width: 670px) {
		.content-wrapper {
			padding: 0;
		}
	}
`;
export const TermsVisiting = styled.div`
	display: flex;
	padding: 8px 16px;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	align-self: stretch;
	border-radius: var(--Corner-M, 8px);
	background: var(--background-Alternative, #F4F4F4);
`;
export const TitleWrapperConditions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	width: fit-content;
`;
export const TitleConditions = styled.h3`
	color: var(--text-B, #352C8D);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const RecommendationConditions = styled.p`
	color: var(--text-Accent, #1B1B1B);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const ButtonPaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;
export const IconBonusesWrapper = styled.div`
	position: absolute;
	right: 16px;
	top: 0;
`;
export const BunusesWrapper = styled.div`
	display: flex;
	padding: var(--Spacing-desctop-Body-M, 8px) var(--Spacing-desctop-Block-S, 16px);
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	gap: var(--Spacing-desctop-Body-M, 8px);
	border-radius: var(--Corner-S, 4px);
	height: fit-content;
	border: 1px solid var(--border-Neutral, #CFCFCF);
`;
export const HaveBonuses = styled.p`
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const ResultPrice = styled.div`
	display: flex;
	gap: var(--Spacing-desctop-Body-M, 8px);
	color: var(--text-Weak, #4D4D4D);
	justify-content: space-between;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	align-items: center;
	width: 100%;
`;
export const TimeContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: ${isAuthRightToken ? '270px' : '140px'};
	align-items: flex-end;
	position: relative;
`;
export const PriceAbonement = styled.span`
	color: var(--text-Weak, #4D4D4D);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const TimeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
`;
export const TimeLabel = styled.div`
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Time = styled(PriceAbonement)`
	color: var(--text-Strong, #303030);
`;
export const WorkToWrapper = styled(ButtonPaymentWrapper)`
	gap: 4px;
`;
