import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const Container = styled.div`
	position: relative;
`;
export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: absolute;
	border: 1px solid var(--border-input);
	border-radius: 5px;
	top: -288px;
	left: 0;
	padding: 10px;
	background-color: var(--white-color);
	z-index: 10;
`;
export const ButtonUpdateWrapper = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;
`;
export const ButtonUpdate = styled.button`
	background-color: transparent;
	border-radius: 50%;
	padding: 0 1px ;
	cursor: pointer;
	transition: 0.7s;
	&:hover{
		background-color: var(--gray-light);
	}
`;
export const SelectTimeWindowContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	background-color: var(--white-color);
	padding-bottom: 12px;
	border-bottom: 1px solid #aaa;
	width: 100%;
`;
export const ButtonNavigateData = {
	height: '30px',
	padding: '2px 10px',
	width: 'fit-content',
	fontWeight: 400,
	fontSize: '16px',
};
export const Calendar =  css`
	position: absolute;
	top: calc(100% + 2px);
	right: -28px;
	border-radius: 10px;
	border: 1px solid var(--border-input);
	z-index: 10;
`;
export const DateTitleWrapper = styled.div`
	display: flex;
	gap: 12px;
`;
export const DateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-bottom: 12px;
	border-bottom: 1px solid #aaa;
`;
