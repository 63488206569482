import {FC, memo} from 'react';

import {AbonementType} from '../../../../../interfaces/abonement';
import {ButtonNew} from '../../../../buttons-new';
import {ButtonCheckbox} from '../../../../controls-new/checkboxes/button-checkbox/button-checkbox';
import {PaymentMethods} from '../../../../payment-methods/payment-methods';
import {bonusesAvailable, calculateFinishPrice, canculateForPaymentOplati} from '../../utils';
import {CardAbonement} from '../components/card-abonement/card-abonement';
import * as SC from './one-service-adaptive.style';

interface Props {
	abonement: AbonementType;
	balance: number;
	useProvider: string;
	showMore: boolean;
	useAllBonuses: boolean;
	onClickPayment: () => void;
	clickShowMore: () => void;
	clickUseAllBonuses: () => void;
	onChangeServicePayment: (value: string) => void;
}

export const OneServiceAdaptive: FC<Props> = memo(({
													   abonement, balance, onClickPayment, clickShowMore,
													   useAllBonuses, clickUseAllBonuses, useProvider,
													   onChangeServicePayment,
												   }) => {
	const price = abonement.info.price;

	return (
		<>
			<CardAbonement abonement={abonement} clickShowMore={clickShowMore}/>
			<SC.BonusesWrapper>
				<SC.HasBalance>Доступно {bonusesAvailable({
					useAllBonuses, price, balance
				})}</SC.HasBalance>
				<ButtonCheckbox label={'Cписать'} checked={useAllBonuses} id={'bonuses'} name={'bonuses'}
								value={'bonuses'} handleCheckboxChange={clickUseAllBonuses}/>
				<SC.ResultPrice>
					<SC.HasBalance>Итого</SC.HasBalance>
					<SC.Price>{calculateFinishPrice({price, balance, useAllBonuses})}</SC.Price>
				</SC.ResultPrice>
			</SC.BonusesWrapper>
			<PaymentMethods onChangeServicePayment={onChangeServicePayment} value={useProvider}
							discount={canculateForPaymentOplati({useAllBonuses, balance, price})}/>
			<ButtonNew label={'Оплатить'} bgColor={'purple'} onClick={onClickPayment}/>
		</>
	);
});
