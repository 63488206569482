import styled from '@emotion/styled';

interface EmblaThumbsSlideStyle{
	selected: boolean
}

export const Img = styled.img<EmblaThumbsSlideStyle>`
	aspect-ratio: 16/9;
	height: ${props => props.selected ? '60px' : '45px'};
	width: ${props => props.selected ? '106px' : '80px'};
	object-fit: cover;
	border-radius: var(--Corner-S);
	border: 2px solid var(--border-Weak);

	@media screen and (max-width: 600px){
		width: ${props => props.selected ? '80px' : '60px'};
		height: ${props => props.selected ? '46px' : '36px'};
	}
`;
export const EmblaThumbsSlide = styled.div<EmblaThumbsSlideStyle>`
	flex: 0 0 auto;
	min-width: 0;
	height: ${props => props.selected ? '60px' : '45px'};
	width: ${props => props.selected ? '106px' : '80px'};
	@media screen and (max-width: 600px){
		width: ${props => props.selected ? '80px' : '60px'};
		height: ${props => props.selected ? '46px' : '36px'};
	}
`;
export const EmblaThumbsSlideNumber = styled.button<EmblaThumbsSlideStyle>`
	border-radius: 1.8rem;
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent;
	touch-action: manipulation;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	padding: 0;
	margin: 0;
	font-size: 1.8rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${props => props.selected ? '60px' : '45px'};
	width: ${props => props.selected ? '106px' : '80px'};
	aspect-ratio: 16/9;
	@media screen and (max-width: 600px){
		width: ${props => props.selected ? '80px' : '60px'};
		height: ${props => props.selected ? '46px' : '36px'};
	}
`;
