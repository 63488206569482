import {FC, memo} from 'react';
import {Link} from 'react-router-dom';

import notPhoto from '../../../assets/img/no_image.png';
import {Contacts} from '../../../interfaces/interfaces';
import {ONE_ORG_NAME} from '../../../shared/constants/path';
import {generatedUrlOrgName} from '../../../shared/generated-url-org-name';
import {cleanWebsiteUrl} from '../../../shared/utils/convert-url';
import IconInstagram from '../../svg-icon-components/icon-instagram';
import IconTelegram from '../../svg-icon-components/icon-telegram';
import IconWebSite from '../../svg-icon-components/icon-web-site';
import IconPhone from '../../svg-icon-components/new-icon-card/icon-phone';
import * as SC from './org-schedule.style';

interface Props {
	org_name: string;
	logo?: string;
	address?: string;
	contacts: Contacts;
	className?: string;
}

export const OrgSchedule: FC<Props> = memo(({org_name, logo, contacts, address, className}) => {


	return (
		<SC.Container className={className}>
			<Link to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(org_name))}>
				<SC.Logo alt={org_name} src={logo || notPhoto}/>
			</Link>
			<SC.ContentWrapper>
				<Link to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(org_name))}>
					<SC.NameWrapper>
						<SC.OrgName>{org_name}</SC.OrgName>
						<SC.Address>{address}</SC.Address>
					</SC.NameWrapper>
				</Link>
				<SC.Links>
					{(contacts.viber.length > 0) && <SC.LinkWrapper>
						<IconPhone width={24} height={24}/>
						<SC.Phone>{contacts.viber[0]}</SC.Phone>
					</SC.LinkWrapper>}
					{(contacts.telegram.length > 0) && <SC.LinkWrapper>
						<IconTelegram width={22} height={22}/>
						<SC.Link href={contacts.telegram[0]} target={'_blank'}>Telegram</SC.Link>
					</SC.LinkWrapper>}
					{(contacts.instagram.length > 0) && <SC.LinkWrapper>
						<IconInstagram width={22} height={22}/>
						<SC.Link href={contacts.instagram[0]} target={'_blank'}>Instagram</SC.Link>
					</SC.LinkWrapper>}
					{(contacts.web_site.length > 0) && <SC.LinkWrapper>
						<IconWebSite width={22} height={22}/>
						<SC.LinkSite href={contacts.web_site[0]}
								 target={'_blank'}>{cleanWebsiteUrl(contacts.web_site[0])}</SC.LinkSite>
					</SC.LinkWrapper>}
				</SC.Links>
			</SC.ContentWrapper>
		</SC.Container>
	);
});
