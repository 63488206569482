import {FC, memo} from 'react';
import {Link} from 'react-router-dom';

import vector from '../../../../../assets/icon/arrow_white.svg';
import no_image from '../../../../../assets/img/no_image.png';
import {ServiceListItem} from '../../../../../interfaces/service';
import {ageView} from '../../../../../pages/service-list-page/constants';
import {SERVICE_PAGE} from '../../../../../shared/constants/path';
import {logoBigSize} from '../../../../../shared/utils/get-images-size';
import {showSex} from '../../../../../shared/utils/show-sex';
import * as SC from './service-card.style';

interface ServiceCardProps {
	closeWindow: () => void;
	service: ServiceListItem;
}

export const ServiceCard: FC<ServiceCardProps> = memo(({closeWindow, service}) => {

	const labels: string[] = [
		...(service.sex !== 'Both' ? [showSex({
			sex: service.sex,
			ageFrom: service.age_from,
			ageTo: service.age_to
		})] : []),
		...(service.age_from || service.age_to ? [ageView(service.age_from, service.age_to)] : []),
		...(service.entry_level ? [service.entry_level] : []),
	];

	return (
		<Link to={SERVICE_PAGE.replace(':service_id', service.id)} onClick={closeWindow}><SC.Container>
			<SC.ContentWrapper>
				<SC.Logo src={logoBigSize(service.logo) || no_image} alt={service.service_type}/>
				<SC.ServiceInfo>
					<p>{service.service_type}</p>
					<p>{service.sports.join(', ')}</p>
					<p>{labels.join(', ')}</p>
				</SC.ServiceInfo>
			</SC.ContentWrapper>
			<SC.Vector src={vector} alt="направление"/>
		</SC.Container></Link>
	);
});
