import {DetailedHTMLProps, forwardRef, InputHTMLAttributes} from 'react';

import * as SC from './radio.style';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
	value: string;
	label: string;
	checked: boolean;
	onChange: () => void;
}

export const Radio= forwardRef<HTMLInputElement, Props>(({value, label, checked, onChange}, ref) => {

	return (
		<SC.FormRadio>
			<input
				id={value} type="radio" checked={checked} ref={ref}
				name="radio" value={value} onChange={onChange}/>
			<SC.Label htmlFor={value}>{label}</SC.Label>
		</SC.FormRadio>
	);
});
