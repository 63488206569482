import styled from '@emotion/styled';

interface ContainerStyle{
	bgColor: 'yellow' | 'green' | 'purple';
	className?: string;
}

export const Container = styled.button`
	width: 100%;
	border-radius: 8px;
`;
export const Wrapper = styled.div<ContainerStyle>`
	color: var(--white-color);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	border-radius: 8px;
	background-color: ${props => props.bgColor === 'yellow' ? 'var(--yellow)' : (props.bgColor === 'green' ? 'var(--green)' : 'var(--text-B)')};
	transition: 0.3s;
	padding: 10px;
	width: 100%;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
	text-wrap: nowrap;

	&:disabled {
		background-color: var(--button-Desabled-Primary);
		pointer-events: none;
	}

	@media (hover: hover) {
		&:hover {
			background-color: ${props => props.bgColor === 'yellow' ? '#B97916' : (props.bgColor === 'green' ? 'var(--green-hover)' : 'var(--text-B-hover)')};
		}
	}
`;
