import {FC, memo} from 'react';

import vector from '../../../../../../assets/icon/arrow-down.svg';
import {AbonementType} from '../../../../../../interfaces/abonement';
import {formatDateString} from '../../../../../../pages/order-page/utils/convert-date';
import {ABONEMENT} from '../../../../../../shared/constants/path';
import {convertPriceToString} from '../../../../../../shared/utils/converter-price';
import {orgLogoSmall} from '../../../../../../shared/utils/get-images-size';
import {ButtonWithBorder} from '../../../../../buttons-new';
import {resetTime} from '../../../../../filtering-table/components/order-card/order-card';
import {LinkWithBorder} from '../../../../../links-navigate';
import {getLimitAbonement, workingAbonementTo} from '../../../utils';
import {OrgInfo} from '../org-info/org-info';
import * as SC from './card-abonement.style';

interface Props {
	abonement: AbonementType;
	clickShowMore: () => void;
	profilePage?: boolean;
	expire_at?: string;
}

export const CardAbonement: FC<Props> = memo(({abonement, profilePage, clickShowMore, expire_at}) => {
	const {info, platform_info, org_info, id} = abonement;
	const today = resetTime(new Date());
	const compareDate = resetTime(new Date(expire_at || formatDateString(new  Date())));
	return (
		<SC.Container active={today <= compareDate}>
			<OrgInfo logo={orgLogoSmall(org_info.logo)} org_name={org_info.org_name}
					 address={platform_info?.location_info.address || ''}
					 city={platform_info?.city ?? null}/>
			<SC.Name>{info.name}</SC.Name>
			{profilePage && <SC.ButtonDetails onClick={clickShowMore}>
				<span>Показать условия</span>
				<SC.Vector src={vector} alt="vector"/>
			</SC.ButtonDetails>}
			<SC.AbonementPriceLimitWrapper>
				<SC.Price>{convertPriceToString(info.price)}</SC.Price>
				<SC.LimitWrapper>
					<SC.Limit>{getLimitAbonement(info.duration)}</SC.Limit>
					<SC.WorkTo>До {workingAbonementTo(info.duration)}</SC.WorkTo>
				</SC.LimitWrapper>
			</SC.AbonementPriceLimitWrapper>
			{!profilePage && <ButtonWithBorder label={'Подробнее'} borderColor={'purple'} onClick={clickShowMore}/>}
			{profilePage && today > compareDate &&
				<LinkWithBorder label={'Повторить'}
								path={`/${ABONEMENT}?abonement_id=${id}`}
								borderColor={'purple'}/>}
		</SC.Container>
	);
});

