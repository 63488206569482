import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

export const LinkReact = styled(Link)`
	color: var(--text-B);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
	border-bottom: 1px solid var(--text-B);
`;
export const LinkATeg = styled.a`
	color: var(--text-B);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
	border-bottom: 1px solid var(--text-B);
`;
