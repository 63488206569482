import {CSSProperties, FC, memo, ReactNode} from 'react';

import * as SC from './button-with-border.style';

interface Props {
	onClick?: () => void;
	label: string;
	borderColor?: 'yellow' | 'green' | 'purple';
	className?: string;
	icon?: ReactNode;
	iconPosition?: 'left' | 'right';
	style?: CSSProperties;
}

export const ButtonWithBorder: FC<Props> = memo(({
													 label, onClick, borderColor = 'green', className, icon,
													 iconPosition = 'left', style
												 }) => {
	return (
		<SC.Container onClick={onClick} style={style}>
			<SC.Wrapper className={className} borderColor={borderColor} style={style}>
				{(icon && iconPosition === 'left') && icon}
				<SC.LabelButton>{label}</SC.LabelButton>
				{(icon && iconPosition === 'right') && icon}
			</SC.Wrapper>
		</SC.Container>
	);
});
