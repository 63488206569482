import classNames from 'classnames';
import {FC, memo, ReactNode, useEffect} from 'react';

import {CloseIcon} from '../close-icon/close-icon';
import * as SC from './modal-new.style';

interface Props {
	children: ReactNode;
	classNameWrapper?: string;
	classNameContainer?: string;
	classNameWindow?: string;
	onClickClose?: () => void;
}

export const ModalNew: FC<Props> = memo(({
											 children, classNameWrapper, onClickClose, classNameContainer,
											 classNameWindow,
										 }) => {

	useEffect(() => {
		if (!onClickClose) {
			return;
		}
		const handlePopState = () => {
			if (onClickClose) {
				onClickClose();
			}
		};

		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	return (
		<SC.Window className={classNames(classNameWindow, 'window-modal')}>
			<SC.Container className={classNames(classNameContainer, 'content-container')}>
				<SC.ContentWrapper>
					<SC.Content className={classNames(classNameWrapper, 'content-wrapper')}>{children}</SC.Content>
					<SC.CloseButton onClick={onClickClose}>
						<CloseIcon/>
					</SC.CloseButton>
				</SC.ContentWrapper>
			</SC.Container>
		</SC.Window>
	);
});
