import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {ConfirmOrderParams} from '../../../api/query-params';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchPutOrderConfirm = createAsyncThunk(
	'fieldSlice/fetchPutOrderConfirm',
	async (data: ConfirmOrderParams, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.putOrderConfirm(data);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				// dispatch(setMessageUser({
				// 	messageUser: emptyMessage,
				// 	titleMessage: dataSavedText,
				// 	textMessage: 'Заказ успешно подтвержден'
				// }));
				// dispatch(setIsShowMessage({isShowMessage: true}));
				return {status: response.status};
			}
		} catch (error) {
			handleAxiosError({dispatch, error, rejectWithValue});
		}
	},
);
