import styled from '@emotion/styled';

export const ButtonGroup = styled.div`
	display: flex;
	gap: 10px;
	button > div, a > div{
		width: 100%;
		font-size: 16px;
		padding: 6px;
	}
`;
export const ButtonWrapper = styled.div`
	position: relative;
	width: 100%;
`;
export const ConfirmDeletion = {
	top: '-90px',
};
