import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	backgroundColor?: string;
	strokeColor?: string;
}

const IconPhone: FC<IconProps> = ({backgroundColor, strokeColor, ...restProps}) => {
	return (
		<svg width="30" height="30" viewBox="0 0 30.1699 30.1699" fill="none"
			 xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<defs>
				<clipPath id="clip9062_1326">
					<rect id="Hicon / Linear / Time Circle 8" rx="14.399048" width="28.798096" height="28.798096"
						  transform="translate(0.685669 0.685669)" fill="white" fillOpacity="0"/>
				</clipPath>
			</defs>
			<rect id="Hicon / Linear / Time Circle 8" rx="14.399048" width="28.798096" height="28.798096"
				  transform="translate(0.685669 0.685669)" fill={backgroundColor} fillOpacity="1.000000"/>
			<g clipPath="url(#clip9062_1326)">
				<path id="Vector" stroke={strokeColor}
					  d="M21.89 22.51C21.23 23.17 20.28 23.45 19.36 23.24C13.15 21.86 8.3 17.01 6.91 10.79C6.71 9.88 6.99 8.93 7.65 8.27L8.46 7.46C8.53 7.39 8.56 7.36 8.59 7.33C9.29 6.69 10.36 6.69 11.07 7.33C11.09 7.36 11.13 7.39 11.19 7.46L12.52 8.79C13.26 9.53 13.47 10.63 13.05 11.59C12.64 12.54 12.85 13.65 13.58 14.38L15.78 16.57C16.51 17.31 17.62 17.52 18.57 17.1C19.52 16.69 20.63 16.9 21.37 17.63L22.7 18.97C22.76 19.03 22.8 19.06 22.82 19.09C23.47 19.79 23.47 20.87 22.82 21.57C22.8 21.6 22.76 21.63 22.7 21.7L21.89 22.51Z"
					  strokeOpacity="1.000000"/>
			</g>
		</svg>
	);
};

IconPhone.defaultProps = {
	backgroundColor: '#1c8666',
	strokeColor: '#ffffff',
};

export default IconPhone;
