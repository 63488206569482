import {Suspense, useCallback, useEffect} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';

import {MessageUser} from '../components';
import {setIsShowOrderContainer} from '../redux/reducers/orders-reducer';
import {resizeScreen} from '../redux/reducers/resize-screen-reduser';
import {useAppSelector} from '../redux/selectors/hook';
import {isShowLoaderSelector, isShowMessageSelector} from '../redux/selectors/user-state-selectors';
import {useAppDispatch} from '../redux/store';
import {fetchGetAllCity} from '../redux/thunks/get-all-city';
import {ADMIN, FAQ, LOGIN, PROFILE_USER} from '../shared/constants/path';
import {isRightTokenTime} from '../shared/utils/decoder-jwt';
import {isAuth} from '../shared/utils/save-local-storage';
import {FooterNew} from './components/footer-new/footer-new';
import {Header} from './components/header/header';
import {Loader} from './components/loader/loader';
import * as SC from './layuot.style';

const Layout = () => {
	const isShowLoader = useAppSelector(isShowLoaderSelector);
	const isShowMessage = useAppSelector(isShowMessageSelector);
	const param = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();

	useEffect(() => {
		if (param.id) {
			dispatch(setIsShowOrderContainer({data: false}));
		}
	}, [dispatch, param]);

	useEffect(() => {
		dispatch(fetchGetAllCity());
	}, [dispatch]);

	const saveSizeScreenStore = useCallback(() => {
		dispatch(resizeScreen({size: window.innerWidth}));
	}, [dispatch]);

	useEffect(() => {
		window.addEventListener('resize', saveSizeScreenStore);
		return () => {
			window.removeEventListener('resize', saveSizeScreenStore);
		};
	}, [saveSizeScreenStore]);


	useEffect(() => {
		if (isAuth() && !isRightTokenTime() &&
			(location.pathname.startsWith(ADMIN)
				|| location.pathname.startsWith(PROFILE_USER)
				|| location.pathname.startsWith(FAQ))) {
			navigate(LOGIN, {replace: true});
		}
	}, [location.pathname, isAuth(), !isRightTokenTime()]);

	return (
		<SC.Container>
			{isShowLoader && <Loader/>}
			{isShowMessage && <MessageUser/>}
			<Header/>
			<Suspense fallback={<Loader/>}>
				<Outlet/>
			</Suspense>
			<FooterNew/>
		</SC.Container>
	);
};
export default Layout;
