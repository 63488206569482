import {createAsyncThunk} from '@reduxjs/toolkit';

import {history} from '../../../api/history';
import {ListAbonementsParams} from '../../../interfaces/abonement';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const getHistoryAbonementPagination = createAsyncThunk(
	'pagination/getHistoryAbonementPagination',
	async (args: ListAbonementsParams, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await history.getHistoryAbonements({params: args, userId: args.user || ''});
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				return {status: response.status, data: response.data.abonements};
			}
			return {status: response.status, data: []};
		} catch (error) {
			handleAxiosError({dispatch, error, rejectWithValue});
		}
	},
);
