import styled from '@emotion/styled';

interface ContainerButtonFilterStyle{
	onClickMap: boolean
}

export const ContainerButtonFilter = styled.button<ContainerButtonFilterStyle>`
	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 16px;
	border-radius: 6px;
	background-color: var(--green);
	transition: 0.3s;
	color: var(--white-color);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	width: fit-content;

	@media (hover: hover) {
		&:hover {
			background-color: var(--green-hover);
		}
	}
	@media (max-width: 600px) {
		font-size: 16px;
		padding: 8px 16px;
		width: ${props => props.onClickMap? '100%' : 'fit-content'};
	}
`;
export const Icon = styled.img`
	@media (max-width: 600px) {
		width: 18px;
	}
`;
export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 18px;
	position: sticky;
	top: 0;
	background-color: var(--background-Base);
	padding: 4px 0 8px;
	z-index: 10;
	width: 100%;
`;
export const ShowMap = styled.button`
	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 16px;
	border-radius: 6px;
	transition: 0.3s;
	background-color: var(--border-B);

	@media (hover: hover) {
		&:hover {
			background-color: var(--button-Hover-B-Primary);
		}
	}

	@media (max-width: 600px) {
		padding: 8px;
		width: 100%;
	}
`;
export const TextButton = styled.span`
	color: var(--white-color);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	@media (max-width: 420px) {
		display: none;
	}
	@media (max-width: 600px) {
		font-size: 16px;
	}
`;
export const TextButtonMobile = styled(TextButton)`
	display: none;
	@media (max-width: 420px) {
		display: block;
	}
`;
