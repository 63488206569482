import {useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {DEFAULT_OFFSET, DEFAULT_TOTAL_COUNT, FIRST_PAGE} from '../constants/pagination';

interface UsePagination{
	offsetLocation: number,
	perPage: number;
	totalCount: number
}

export function usePagination({perPage, totalCount = DEFAULT_TOTAL_COUNT, offsetLocation}: UsePagination) {
	const location = useLocation();
	const match = location.pathname.match(/page(\d+)/);
	const pageNumber = match ? +match[1] : 1;
	const [page, setPage] = useState(pageNumber);
	const [offset, setOffset] = useState((pageNumber - 1) * offsetLocation || 0);

	const pageCount = Math.ceil(totalCount / perPage);
	const onPageChange = useCallback((nextPage: number) => {
		const nextPageIndex = nextPage - 1;
		setOffset(nextPageIndex * perPage);
		setPage(nextPage);
	}, [perPage]);

	const onResetPagination = useCallback(() => {
		setPage(FIRST_PAGE);
		setOffset(DEFAULT_OFFSET);
	}, []);

	return {
		page,
		perPage,
		offset,
		pageCount,
		onPageChange,
		onResetPagination,
	};
}
