import axios from 'axios';

import {getAllUsersTotalCount, HOST_SEARCH_USERS, urlGetAllUsers, urlGetUserById, urlGetUserFullRole, urlGetUserRole} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';
import {GetAllUsersParams} from './query-params';

const instanceJwt = axios.create({
	baseURL: HOST_SEARCH_USERS,
});

instanceJwt.interceptors.request.use(
	(request) => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('jwt_token');
		if (token) {
			request.headers.Authorization = `Bearer ${token}`;
		} else {
			const jwt = jwtUser();
			if (jwt) {
				request.headers.Authorization = `Bearer ${jwt}`;
				return request;
			}
			request.headers.Authorization = 'Bearer 1';
		}

		return request;
	},
);

export const searchUsersAPI = {
	getAllUsers(params?: GetAllUsersParams) {
		return instanceJwt.get(urlGetAllUsers, {params});
	},
	getAllUsersTotalCount(params?: GetAllUsersParams) {
		return instanceJwt.get(getAllUsersTotalCount, {params});
	},
	getUserRoles(id: string) {
		return instanceJwt.get(`${urlGetUserRole}/${id}`);
	},
	getUserFullRoles(id: string) {
		return instanceJwt.get(`${urlGetUserFullRole}/${id}`);
	},
	getUserById(id: string) {
		return instanceJwt.get(`${urlGetUserById}/${id}`);
	},
};
