import {createSlice} from '@reduxjs/toolkit';

import {OrganizationSingle} from '../../interfaces/organization';
import {fetchGetOrganizationByName} from '../thunks/organization/get-organization-by-name-thunk';

interface InitialStateSchedulePage {
selectOrg : OrganizationSingle | null;
}

const initialState: InitialStateSchedulePage = {
	selectOrg: null
};

export const schedulePageSlice = createSlice({
	name: 'schedule-page',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchGetOrganizationByName.fulfilled, (state, action) => {
			state.selectOrg = action.payload?.data;
		});
	}
});
export const schedulePage = schedulePageSlice.reducer;
