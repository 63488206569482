import styled from '@emotion/styled';

export const Title= styled.h1`
	color: var(--text-Accent);
	font-family: Jost, sans-serif;
	font-size: clamp(24px, 5vw, 40px);
	font-style: normal;
	font-weight: 600;
	line-height: 48px;

	@media screen and (max-width: 718px) {
		text-align: center;
		line-height: 30px;
	}
	@media screen and (max-width: 530px) {
		text-align: left;
	}
`;
