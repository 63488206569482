import {EmblaOptionsType} from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import {FC, memo, useCallback, useEffect, useState} from 'react';

import vector from '../../assets/icon/arrow-down.svg';
import no_image from '../../assets/img/no_image.png';
import {UniqueImage} from '../images/index.type';
import * as SC from './new-slider.style';
import {Thumb} from './thumb/thumb';

type PropType = {
    slides: UniqueImage[]
    options?: EmblaOptionsType
};

export const NewSlider: FC<PropType> = memo(({slides, options}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options);
    const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
        containScroll: 'keepSnaps',
        dragFree: true,
        align: 'center',
    });

    const onThumbClick = useCallback(
        (index: number) => {
            if (!emblaMainApi || !emblaThumbsApi) {
                return;
            }
            emblaMainApi.scrollTo(index);
        },
        [emblaMainApi, emblaThumbsApi]
    );

    const onSelect = useCallback(() => {
        if (!emblaMainApi || !emblaThumbsApi) {
            return;
        }
        setSelectedIndex(emblaMainApi.selectedScrollSnap());
        emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
    }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

    useEffect(() => {
        if (!emblaMainApi) {
            return;
        }
        onSelect();

        emblaMainApi.on('select', onSelect).on('reInit', onSelect);
    }, [emblaMainApi, onSelect]);


    return (
        <SC.Embla className="embla_slider">
            <SC.EmblaViewport ref={emblaMainRef}>
                <SC.EmblaContainer>
                    {slides.length > 0 ? slides.map((item) => (
                        <SC.EmblaSlide key={item.id} className="embla_slide">
                            <SC.SelectImg src={item.info.image_link} alt={'Фотографии спортивных услуг'} className="select_img"/>
                        </SC.EmblaSlide>
                    )) : <SC.EmblaSlide className="embla_slide">
                        <SC.SelectImg src={no_image} alt={'Спорт - как часть вашей жизни'} className="select_img"/>
                    </SC.EmblaSlide>}
                </SC.EmblaContainer>
            </SC.EmblaViewport>

            {slides.length > 1 && <SC.EmblaThumbs className="embla_thumbs">
                <SC.EmblaThumbsViewportWrapper>
                    <SC.EmblaThumbsViewport ref={emblaThumbsRef}>
                        <SC.EmblaThumbsContainer>
                            {slides.map((item, index) => (
                                <Thumb
                                    key={item.id}
                                    onClick={() => onThumbClick(index)}
                                    selected={index === selectedIndex}
                                    src={item.info.image_link}
                                />
                            ))}
                        </SC.EmblaThumbsContainer>
                    </SC.EmblaThumbsViewport>
                    <SC.ButtonNext disabled={selectedIndex === 0} onClick={() => onThumbClick(selectedIndex - 1)}>
                        <SC.IconButtonNext src={vector} alt="направление прокрутки галереии спортивных услуг"/>
                    </SC.ButtonNext>
                    <SC.ButtonPrev disabled={selectedIndex === slides.length - 1}
                                   onClick={() => onThumbClick(selectedIndex + 1)}>
                        <SC.IconButtonNPrev src={vector} alt="направление прокрутки галереии спортивных услуг"/>
                    </SC.ButtonPrev>
                </SC.EmblaThumbsViewportWrapper>
            </SC.EmblaThumbs>}
        </SC.Embla>
    );
});

