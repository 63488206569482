import bg_img from '../../../assets/img/start_section_7.webp';
import {FormApplicationConnectionOrganization} from '../../forms';
import {Section} from '../../section/section';
import * as SC from './section-welcome.style';

export const SectionWelcome = () => {
	return (
		<Section className={SC.SectionWelcome}>
			<SC.Title>Оставь заявку на подключение</SC.Title>
			<SC.BoxItems>
				<SC.ImgContainer style={{backgroundImage:` url(${bg_img})`}}>
					<SC.TextContainer>
						<SC.ImgContainerTitle>Быстрое подключение</SC.ImgContainerTitle>
						<SC.ImgContainerText>
							Мы открыты к новым предложениям и будем рады видеть вас частью нашего проекта
						</SC.ImgContainerText>
					</SC.TextContainer>
				</SC.ImgContainer>
				<SC.FormContainer>
					<FormApplicationConnectionOrganization page={'main'}/>
				</SC.FormContainer>
			</SC.BoxItems>
		</Section>
	);
};
