import {ABONEMENT, ABOUT_PROJECT, ALL_ORG, ORDER, PLAY_FIELDS} from '../../../../../shared/constants/path';
import * as SC from './navbar.style';

export const NavBar = () => {

	return (
		<SC.Navbar>
			<ul>
				<li>
					<SC.LinkNav to={ALL_ORG}>
						Спортклубы
					</SC.LinkNav>
				</li>
				<li>
					<SC.LinkNav to={PLAY_FIELDS}>
						Залы
					</SC.LinkNav>
				</li>
				<li>
					<SC.LinkNav to={ORDER}>
						Услуги
					</SC.LinkNav>
				</li>
				<li>
					<SC.LinkNav to={ABONEMENT}>
						Абонементы
					</SC.LinkNav>
				</li>
				<li>
					<SC.LinkNav to={ABOUT_PROJECT}>
						О проекте
					</SC.LinkNav>
				</li>
			</ul>
		</SC.Navbar>
	);
};
