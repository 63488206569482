import styled from '@emotion/styled';

interface StatusStyle {
	status: string;
}

export const Container = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 6px;
    background: #EFEFEF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    color: var(--text-Strong);
    position: relative;
    justify-content: space-between;
`;
export const Logo = styled.img`
	width: 58px;
	height: 58px;
	border-radius: 50%;
	object-fit: cover;
`;
export const OrgName = styled.div`
	margin-right: 30px;
	width: 88%;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;

	p {
		text-wrap: nowrap;
	}
`;
export const OrgNameMobile = styled.h4`
	width: 94%;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
`;
export const Address = styled.div`
	margin-right: 30px;
	width: 82%;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	p {
		text-wrap: nowrap;
	}
`;
export const AddressMobile = styled.p`
	width: 100%;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
`;
export const HeaderCard = styled.div`
	display: flex;
	gap: 14px;
	align-items: center;
	max-width: 86%;
	margin-right: 24px;
	margin-top: 12px;
	@media screen and (max-width: 600px){
		max-width: 100%;
	}
`;
export const OrgDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
    max-width: 100%;
	width: 100%;
`;
export const Status = styled.div<StatusStyle>`
	display: flex;
	padding: 4px 8px;
	justify-content: flex-end;
	align-items: center;
	gap: 4px;
	background-color: ${props => props.status};
	border-radius: 4px;
	color: ${props => props.status === 'var(--yellow)' ? 'var(--black-color)' : 'var(--white-color)'};
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
`;
export const Details = styled.img<StatusStyle>`
	position: absolute;
	right: 10px;
	top: 10px;
	width: 18px;
	height: 18px;
	border-radius: 4px;
	background-color: ${props => props.status};
	padding: 4px;
	cursor: pointer;
`;
export const StatusIcon = styled.img<StatusStyle>`
	filter: ${props => props.status === 'var(--yellow)' ? 'brightness(10%)' : 'brightness(0) invert(1)'};
`;
export const OptionDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`;
export const OptionData = styled.span`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
`;
export const Price = styled.span<StatusStyle>`
	color: ${props => props.status === 'var(--yellow)' ? '#6F6F6F' : props.status};
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 138.5%;
`;
export const Button = styled.button`
	border-radius: 4px;
	border: 1px solid var(--yellow);
	padding: 14px 20px;
	color: var(--black-color);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	width: 100%;
	cursor: pointer;
`;
export const RepeatOrderButton = {
	padding: '14px 20px',
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: '130%',
};
export const Success = styled.div`
    color: var(--border-B);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    border-radius: 6px;
    border: 1px solid var(--border-B);
    height: 32px;
    width: 100%;
`;
export const Closed = styled.div`
	border-radius: 5px;
	border: 1px solid #6F6F6F;
	padding: 14px 20px;
	background: #E2E2E2;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	text-align: center;
	width: 100%;
	gap: 5px;
`;
