import {FC, memo, useCallback, useState} from 'react';

import {OrderType} from '../../../../../interfaces/enums';
import {ServiceListItem} from '../../../../../interfaces/service';
import {howOrderInfo} from '../../../../../pages/schedule-page/utils/how-order-info';
import {SERVICE_PAGE} from '../../../../../shared/constants/path';
import {crossToAbonementsParams} from '../../../../../shared/utils/convert-url';
import {ButtonNew} from '../../../../buttons-new';
import {Link, LinkWithBorder} from '../../../../links-navigate';
import * as SC from './buttons-navigates.style';

interface Props {
	service: ServiceListItem;
}

export const ButtonsNavigates: FC<Props> = memo(({service}) => {
	const [showPhone, setShowPhone] = useState(false);
	const platform_info = service.platform_info;
	const order_policy = service.order_policy;
	const pathToServicePage = SERVICE_PAGE.replace(':service_id', service.id);
	const params = new URLSearchParams();
	params.append('sports', service.sports[0]);
	params.append('service_type', service.service_type);
	params.append('platform_id', service.platform_info.platform_id);

	const finalUrl = `${pathToServicePage}?${params.toString()}`;
	const clickShowPhone = useCallback(() => setShowPhone(prevState => !prevState), []);

	const crossToAbonements = crossToAbonementsParams({
		platform_id: service.platform_info.platform_id,
		service_type: service.service_type,
		service_kind: service.service_kind,
		sport_types: service.sports,
	});

	const orderWithTrial = useCallback(() => {
		sessionStorage.setItem('scrollToMap', 'true');
		localStorage.setItem('trial_order', service.id);
	}, []);

	return (
		<SC.ContainerButtons>
			<LinkWithBorder path={finalUrl} label={'Подробнее'}
							className={SC.OrderButton}/>
			{order_policy.allows === OrderType.abonementOnly &&
				<>
					{order_policy.trial_order === null
						? (service.abonements.length > 0 ?
							<Link path={crossToAbonements} label={'Перейти к абонементам'}
								  className={SC.OrderButton} bgColor={'purple'}/> : null)
						: <Link path={finalUrl} label={'Перейти к расписанию'}
								className={SC.OrderButton} bgColor={'purple'}
								click={orderWithTrial}/>}
				</>}
			{(order_policy.allows === OrderType.book || service.order_policy.allows === OrderType.request) &&
				<Link
					path={finalUrl}
					label={'Перейти к расписанию'}
					className={SC.OrderButton} bgColor={'purple'}
					click={() => sessionStorage.setItem('scrollToMap', 'true')}/>}
			{order_policy.allows === OrderType.view &&
				<>
					{platform_info.contacts.viber.length === 0
						? <Link path={howOrderInfo(platform_info.contacts).contactLink || ''}
								label={howOrderInfo(platform_info.contacts).buttonText}
								className={SC.OrderButton} bgColor={'purple'}/>
						: <ButtonNew label={showPhone ? platform_info.contacts.viber[0] : 'Телефон'}
									 onClick={clickShowPhone} className={SC.ButtonPhone}/>}
				</>}
		</SC.ContainerButtons>
	);
});
