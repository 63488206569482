import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const BtnWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 4px;
	height: 72px;
	justify-content: flex-end;
`;
export const Success = styled.div`
    color: var(--border-B);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    border-radius: 6px;
    border: 1px solid var(--border-B);
    height: 32px;
    width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const Closed = styled.div`
	border-radius: 5px;
	border: 1px solid #6F6F6F;
	height: 34px;
	padding: 6px 20px;
	background: #E2E2E2;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	text-align: center;
	width: 100%;
	gap: 5px;
`;
export const Button = css`
	height: 34px !important;
	font-size: 14px !important;
	font-weight: 400;
`;
export const ConfirmtOrderButton = css`
	height: 40px;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: 130%;
`;
