import styled from '@emotion/styled';

export const Container = styled.section`
    background-color: var(--background-Alternative);
    padding: 24px 52px;
    display: flex;
    align-items: center;
	width: 100%;
    gap: 24px;
    color: var(--black-brown);
	border-radius: var(--Corner-M, 8px);
	border: 2px solid var(--border-Neutral, #CFCFCF);
	@media screen and (max-width: 670px){
		flex-direction: column;
		align-items: flex-start
	}
`;
export const OrgName = styled.h2`
    font-weight: 700;
    font-size: 32px;
    line-height: 1.25;
`;
export const Logo = styled.img`
    border-radius: 50%;
    width: 120px;
    height: 120px;
	min-width: 120px;
	object-fit: cover;
`;
export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
    gap: 20px;
    align-items: center;
	@media screen and (max-width: 460px){
		flex-direction: column;
		align-items: flex-start;
	}
`;
export const Links = styled.nav`
    display: flex;
    gap: 36px;
	flex-wrap: wrap;
	@media screen and (max-width: 800px){
		gap: 24px;
	}
	@media screen and (max-width: 460px){
		flex-direction: column;
	}
`;
export const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
export const Link = styled.a`
	color: var(--black-brown);
    font-weight: 400;
    font-size: 20px;
	line-height: 24px;
`;
export const LinkSite = styled(Link)`
	font-style: normal;
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-skip-ink: none;
	text-decoration-thickness: auto;
	text-underline-offset: auto;
	text-underline-position: from-font;
`;
export const Phone = styled.span`
    font-weight: 400;
    font-size: 20px;
    line-height: 1.385;
`;
export const Address = styled.span`
	color: var(--black-color);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
export const NameWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
