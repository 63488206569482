import firstNews from '../../assets/img/news_1.webp';
import secondNews from '../../assets/img/news_2.webp';
import thirdNews from '../../assets/img/news_3.webp';

export const arrStartPageCard = ['любые спорт залы', 'любые виды спорта', 'любое время дня'];
export const arrStartPageCard_Order = [
	{title: 'Быстро и Просто', text: 'Заказывайте быстро и легко через простой интерфейс'},
	{title: 'Выбор по Вкусу', text: 'Широкий выбор залов для разнообразных тренировок'},
	{title: 'Удобное Управление', text: 'Контроль над заказами через личный кабинет.'},
	{title: 'Гибкость Расписания', text: 'Выбирайте когда вам удобно, а мы найдем лучшие варианты'},
];
export const arrStartPageCard_ForOrg = [
	{title: 'Удобный Контроль', text: 'Легкая администрация и управление заказами на аренду'},
	{title: 'Гибкость Подхода', text: 'Устанавливайте свои правила и гибкий график аренды'},
	{title: 'Эффективная Аналитика', text: 'Детальные данные для решений и управления бизнеса.'},
	{title: 'Рекламные Инициативы', text: 'Увеличивайте посещаемость площадки через нас.'},
];

export const newsItems1 = {
	img: 0,
	title: 'Программа летних Олимпийских игр 2028 года в Лос-Анджелесе удивит зрителей новинками',
	text: 'Международный олимпийский комитет продолжает популяризировать локальные виды спорта и объявил о пяти новых дисциплинах, которые дебютируют на Олимпиаде в 2028 году. Имя стали флаг-футбол, крикет, лакросс, сквош и бейсбол/софтбол. По словам президента МОК Томаса Баха, они являются важной частью американского спорта и заслужили свое место в олимпийском движении: «Эти пять предложенных видов спорта полностью соответствуют американской спортивной культуре и продемонстрируют миру культовые американские виды спорта. Их включение позволит олимпийскому движению взаимодействовать с новыми сообществами спортсменов и болельщиков в США и во всем мире. В то же время международные виды спорта придут в Соединенные Штаты».',
	date: '10.10.2023',
	ok: '249',
	address: 'Новости спорта',
};

export const newsItemsMore = [
	{
		img: firstNews,
		title: 'Флаг-футбол',
		text: 'Этот вид спорта берет свои корни в американском футболе, но заметно отличается от него правилами. Целью является не остановить игрока с мячом с помощью силового контакта, а «обесфлажить» оппонента, то есть сорвать с него флаг или специальную ленточку. Флаг-футбол до сих пор не имеет собственной федерации и четких правил, поэтому количество игроков в командах может варьироваться от 4 до 9, команды могут быть разнополыми, а размер поля устанавливается на месте. Этот вид спорта представлен в каждом американском штате, но также в него играют в Европе и Азии. И даже в России. Правда, здесь он носит очень локальный характер — в основном представлен в Москве, а перерыв между турнирами легко может составить несколько лет.',
		date: '17.10.2023',
		ok: '109',
		address: 'Футбол. Новости',
	},
	{
		img: secondNews,
		title: 'Крикет',
		text: 'Крикет — это вид спорта, в котором участвуют две команды по 11 игроков каждая. Игроки используют биты и мячи для того, чтобы отбивать мяч через калитку. Калитка — это два столбика, между которыми натянута веревка. Команда, которая набирает больше очков, становится победителем. Крикет является одним из самых популярных видов в некоторых странах. Он имеет богатую историю и традиции, которые передаются из поколения в поколение. Крикет также является символом национальной гордости для многих стран, таких как Индия, Пакистан, Бангладеш и Шри-Ланка. Недавно крикет был включен в программу Олимпийских игр, что вызвало большой интерес у зрителей и спортсменов. Многие считают, что крикет станет одним из самых зрелищных видов спорта на Олимпиаде, а также поможет привлечь больше внимания к этому виду спорта в целом.',
		date: '18.10.2023',
		ok: '749',
		address: 'Крикет, Новости',
	},
	{
		img: thirdNews,
		title: 'Лакросс',
		text: 'В лакросс играют две команды по 10 человек каждая. Цель игры — забросить мяч в ворота противника, используя специальную клюшку с ловушкой. Этот вид спорта напоминает смесь хоккея и гандбола — есть защитная экипировка, но спортсмены передвигаются бегом и проводят как быстрые, так и позиционные атаки с резкими бросками. Лакросс является одним из старейших видов спорта в Канаде и США и имеет глубокие культурные корни. Интересным фактом об этой игре является то, что она была включена в программу Олимпийских игр в 1904 году, став первым командным видом спорта на Олимпиаде. Однако после этого лакросс был исключен из программы и только в 2016 году снова вернулся на Игры. Это решение вызвало много споров, но многие считают, что лакросс заслуживает своего места на Олимпиаде.',
		date: '17.10.2023',
		ok: '49',
		address: 'Лакросс, Новости',
	},
];
