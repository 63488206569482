import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {AllOrdersParams} from '../../../api/query-params';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';

export const getHistoryOrdersPagination = createAsyncThunk(
	'paginationSlice/getHistoryOrdersPagination',
	async (params: AllOrdersParams, {dispatch, rejectWithValue}) => {
		try {
			const response = await crmPlatformAPI.getOrders(params);

			if (response.status === 200) {
				return {status: response.status, data: response.data.orders};
			}
			if (response.status === 204) {
				return {status: response.status, data: []};
			}
		} catch (error) {
			handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
