import {RootState} from '../store';

export const selectOrganizations = (state: RootState) => state.organizationState.organizations;
export const allModeratorsOrgSelector = (state: RootState) => state.organizationState.allModeratorsOrg;
export const allModeratorsTotalCountSelector = (state: RootState) => state.organizationState.totalCount;
export const selectOrganizationNames = (state: RootState) => state.organizationState.organizationNames;
export const selectOrganizationsTotal = (state: RootState) => state.organizationState.organizationsTotal;
export const selectSingleOrganization = (state: RootState) => state.organizationState.singleOrganization;
export const orgsCreationsSelector = (state: RootState) => state.organizationState.orgsCreations;
export const servicesOrgSelector = (state: RootState) => state.organizationState.servicesOrg;
export const allOwnersOrg = (state: RootState) => state.OrgReducer.allOwners;
