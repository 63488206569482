import {FC, useCallback, useState} from 'react';

import {ServiceListItem} from '../../../interfaces/service';
import {useAppDispatch} from '../../../redux/store';
import {fetchRecoverService} from '../../../redux/thunks/recover-service';
import {fetchDeactivateService} from '../../../redux/thunks/services/deactivate-service';
import {fetchRemoveService} from '../../../redux/thunks/services/remove-service';
import {ADMIN, UPDATE_SERVICE} from '../../../shared/constants/path';
import {ButtonNew} from '../../buttons-new';
import {ConfirmDeletion} from '../../confirm-deletion/confirm-deletion';
import {Link} from '../../links-navigate';
import * as SC from './button-group-setting-service.style';

interface ButtonGroupSettingServiceProps {
	service: ServiceListItem;
	onUpdate: () => void;
}

export const ButtonGroupSettingService: FC<ButtonGroupSettingServiceProps> = ({service, onUpdate}) => {
	const [type, setType] = useState('');
	const dispatch = useAppDispatch();
	const warningDeleteService = () => setType('remove');
	const warningRecoverService = () => setType('recover');
	const deleteService = () => {
		dispatch(fetchRemoveService({id: service.id})).then(() => onUpdate());
		setType('');
	};
	const deactivateItem = useCallback(async () => {
		setType('');
		dispatch(fetchDeactivateService({id: service.id})).then(() => onUpdate());
	}, [onUpdate, service, dispatch]);

	const recoverItem = useCallback(() => {
		setType('');
		dispatch(fetchRecoverService({id: service.id})).then(() => onUpdate());
	}, [onUpdate, service, dispatch]);
	return (
		<SC.ButtonGroup>
			<Link path={`${ADMIN}services/${UPDATE_SERVICE}`.replace(':id', service.id)} label={'Изменить'}/>

			{service.active &&
				<SC.ButtonWrapper>
					<ButtonNew label={'Деактивировать'} bgColor={'yellow'} onClick={warningRecoverService}/>
					{type === 'recover' &&
						<ConfirmDeletion type={type} remove deleteFalse={() => setType('')} deleteTrue={deactivateItem}
										 style={SC.ConfirmDeletion}/>}
				</SC.ButtonWrapper>}
			{!service.active &&
				<>
					<ButtonNew label={'Активировать'} onClick={recoverItem}/>
					<SC.ButtonWrapper>
						{type === 'remove' && <ConfirmDeletion type={type} remove deleteFalse={() => setType('')}
															   deleteTrue={deleteService} style={SC.ConfirmDeletion}/>}
						<ButtonNew label={'Удалить'} bgColor={'yellow'} onClick={warningDeleteService}/>
					</SC.ButtonWrapper>
				</>}
		</SC.ButtonGroup>
	);
};
