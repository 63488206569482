import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../api/crm-platform';
import {CloseOrderData} from '../../interfaces/interfaces';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';
import {setShowLoader} from '../reducers/user-data-reducer';

export const fetchCloseOrder = createAsyncThunk(
	'fieldSlice/fetchCloseOrder',
	async (data: CloseOrderData, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.putOrderClose(data);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === 200) {
				// dispatch(setShowLoader({isShowLoader: false}));
				// dispatch(setMessageUser({messageUser: messageSuccessOrderClose}));
				// dispatch(setIsShowMessage({isShowMessage: true}));
				return {status: response.status};
			}
			return {status: response.status};
		} catch (error) {
			return handleAxiosError({dispatch, error, rejectWithValue});
		}
	},
);
