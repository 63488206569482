import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

interface ContainerStyle{
	bgColor: 'yellow' | 'green' | 'purple';
}

export const Container = styled(Link)`
	width: 100%;
	height: fit-content;
`;
export const Wrapper = styled.div<ContainerStyle>`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: var(--white-color);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	border-radius: 8px;
	background-color:${props => props.bgColor === 'yellow' ? 'var(--yellow)' : (props.bgColor === 'green' ? 'var(--green)' : 'var(--border-B)')};
	transition: 0.3s;
	text-wrap: nowrap;
	padding: 10px;
	width: 100%;

	@media (hover: hover) {
		&:hover {
			background-color:${props => props.bgColor === 'yellow' ? '#B97916' : (props.bgColor === 'green' ? 'var(--green-hover)' : 'var(--button-Hover-B-Primary)')};
		}
	}
`;
