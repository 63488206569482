import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const ServiceType = styled.h3`
	color: var(--text-Strong, #303030);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const DetailDate = styled.span`
	color: var(--text-Strong);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Price = styled.span`
	color: var(--text-G, #1C8666);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const PaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-M, 8px);
	align-self: stretch;
	width: 100%;
`;
export const RecommendationPaymentLater = styled.p`
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const DetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-M, 8px);
`;
export const OrderInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	position: relative;
	width: calc(100% - 10px);
`;
export const BenefitWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 16px 24px;
	align-items: center;
	gap: 16px;
	border-radius: var(--Corner-12, 12px);
	border: 1px solid var(--border-Neutral, #CFCFCF);
	background: var(--background-Base, #FFF);
`;
export const BenefitTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--Spacing-desctop-Body-M, 8px);
	align-self: stretch;
`;
export const BenefitTitle = styled.h3`
	color: var(--text-Accent, #1B1B1B);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const BenefitRecommendation = styled.p`
	color: var(--text-Weak, #4D4D4D);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const BenefitWrapperLinks = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	@media screen and (max-width: 600px) {
		flex-direction: column-reverse;
	}
`;
export const Modal = css`
	gap: var(--Spacing-desctop-Block-M) !important;
`;
export const BonusesIconWrapper = styled.div`
	position: absolute;
	right: 14px;
	bottom: -25%;
	width: fit-content;
	@media screen and (max-width: 500px) {
		position: relative;
		margin: 0 auto;
	}
`;
