import {createAsyncThunk} from '@reduxjs/toolkit';

import {SearchPayment} from '../../../api/search-payment';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetBalanceHistory = createAsyncThunk(
	'paymentSlice/fetchGetBalanceHistory',
	async (data: {id: string, params: {offset: number, limit: number}}, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPayment.getBalanceHistory(data);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				return {status: response.status, data: response.data};
			}

		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
