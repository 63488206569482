import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {UserRole} from '../../interfaces/enums';
import {IComments, JwtData, MessageUser, UserMeServerInfo, UserWithPlatformInfo,} from '../../interfaces/interfaces';
import {IOrder} from '../../interfaces/order';
import {filterAdminRoles, TFullUserRole} from '../../interfaces/role';
import {LOCAL_STORAGE_KEY_MAP} from '../../shared/constants/local-storage';
import {
	emptyMessage,
	messageAuthError,
	messageBack,
	messageErrDataRecover,
	messageErrDataUserByLogin,
	messageErrNotDataRecover,
	messageErrorData,
	messageErrorServer,
	messageErrorUpdateUser,
	messageErrRecoverCode,
	messageErrStep1,
	messageRepeat,
	messageSuccessUpdatePlatform,
} from '../../shared/constants/message-user';
import {getIdUser} from '../../shared/utils/decoder-jwt';
import {isAdminRole, isModeratorRole, isOwnerRole} from '../../shared/utils/roles';
import {saveLocalStorage} from '../../shared/utils/save-local-storage';
import {fetchAuthorizeUser} from '../thunks/authorize-thunk';
import {fetchGetAvatars} from '../thunks/get-avatars-thunk';
import {fetchGetAllUsersBlackList} from '../thunks/get-black-list-by-platform';
import {fetchRecoverPasswordComplete} from '../thunks/recover-password-complete-thunk';
import {fetchRecoverPassword} from '../thunks/recover-password-thunk';
import {fetchRegistrationUser} from '../thunks/registration-thunk';
import {fetchUpdatePassword} from '../thunks/update-password-thunk';
import {fetchGetAllUsers} from '../thunks/user/get-all-users-thunk';
import {fetchGetUserById} from '../thunks/user/get-user-by-id';
import {fetchGetUserByIdForAdmin} from '../thunks/user/get-user-by-id-for-admin';
import {fetchGetUserFullRoles} from '../thunks/user/get-user-fullRoles-thunk';
import {fetchGetUserRoles} from '../thunks/user/get-user-roles-thunk';
import {fetchUpdateDataUser} from '../thunks/user/update-user-data-thunk';

export interface UserDataForAdmin {
	id: string;
	name: string;
	phone: string;
	email: string;
	telegram: string | null;
	avatar: string;
}

export interface UserDataState {
	isShowMessage: boolean;
	isAuth: boolean;
	isLoginPage: boolean;
	isShowLoader: boolean;
	nickName: string;
	userMeta: {
		avatar: string;
		subscribed_to_news: boolean;
	};
	userData: {
		id?: string;
		name: string;
		phone: string;
		email: string;
		balance: number;
		telegram: string | null;
	};
	userDataForAdmin: UserDataForAdmin,
	jwt: string;
	// TODO: здесь лежат роли, возьми их плиз отсюда
	jwtData: JwtData;
	selectedAdminFullRole?: TFullUserRole;
	status: 'idle' | 'loading' | 'succeeded' | 'failed';
	messageUser: MessageUser;
	allUsers: UserMeServerInfo[];
	totalCount: number,
	allBlackUsers: UserWithPlatformInfo[];
	allBlackTotalCount: number;
	commentaries: IComments[];
	checkedUserFullRoles?: TFullUserRole[];
	viewedUserFullRoles?: TFullUserRole[];

	historyOrders: IOrder[];
	historyOrdersTotal: number;

	city: string | null;
	avatars: string[];
}

const initialState: UserDataState = {
	isShowMessage: false,
	isAuth: false,
	isLoginPage: false,
	isShowLoader: false,
	nickName: '',
	userMeta: {
		avatar: '',
		subscribed_to_news: false,
	},
	userData: {
		id: '',
		name: '',
		phone: '',
		email: '',
		balance: 0,
		telegram: '',
	},
	userDataForAdmin: {
		id: '',
		email: '',
		name: '',
		phone: '',
		telegram: '',
		avatar: '',
	},
	jwt: '',
	jwtData: {
		exp: 0,
		res: {},
		roles: [UserRole.viewer],
		sub: '',
	},
	selectedAdminFullRole: undefined,
	status: 'idle',
	messageUser: {
		titleMessage: '',
		textMessage: '',
		descriptionMessage: '',
		action: '',
		btnTitle: '',
		isErrorMessage: false,
	},
	allUsers: [],
	totalCount: 0,
	allBlackUsers: [],
	allBlackTotalCount: 0,
	commentaries: [],

	historyOrders: [],
	historyOrdersTotal: 0,

	city: null,
	avatars: [],
};

export const userDataSlice = createSlice({
	name: 'userDataSlice',
	initialState,
	reducers: {
		resetDataUser: (state: UserDataState) => {
			state.historyOrders = [];
			state.historyOrdersTotal = 0;
			state.selectedAdminFullRole = undefined;
			state.nickName = '';
			state.userData = {
				id: '',
				name: '',
				phone: '',
				email: '',
				balance: 0,
				telegram: '',
			};
			state.allUsers = [];
			state.totalCount = 0;
			state.isAuth = false;
			state.allBlackUsers = [];
			state.allBlackTotalCount = 0;
		},
		setBlackList: (state: UserDataState, action) => {
			state.allBlackUsers = [...action.payload.data];
		},
		setShowLoader: (state: UserDataState, action) => {
			state.isShowLoader = action.payload.isShowLoader;
		},
		setMessageUser: (state: UserDataState, action: PayloadAction<{
			messageUser: MessageUser,
			textMessage?: string
			titleMessage?: string
			descriptionMessage?: string
		}>) => {
			state.messageUser.titleMessage = action.payload.titleMessage || action?.payload?.messageUser?.titleMessage;
			state.messageUser.textMessage = action?.payload?.textMessage || action?.payload?.messageUser?.textMessage;
			state.messageUser.descriptionMessage = action.payload.descriptionMessage;
			state.messageUser.action = action?.payload?.messageUser?.action;
			state.messageUser.btnTitle = action?.payload?.messageUser?.btnTitle || 'Ok';
			state.messageUser.isErrorMessage = action?.payload?.messageUser?.isErrorMessage;
		},
		setResetMessageUser: (state: UserDataState) => {
			state.messageUser = emptyMessage;
		},
		setIsShowMessage: (state: UserDataState, action) => {
			state.isShowMessage = action.payload.isShowMessage;
		},
		setResetIsAuth: (state: UserDataState) => {
			state.isAuth = false;
			state.jwt = '';
			state.jwtData = {
				exp: 0,
				res: {},
				roles: [UserRole.viewer],
				sub: '',
			};
		},
		setIsAuthUser: (state: UserDataState) => {
			state.isAuth = true;
		},
		setIsLoginPage: (state: UserDataState, action) => {
			state.isLoginPage = action.payload;
		},
		setUserCity: (state: UserDataState, action) => {
			state.city = action.payload;
		},
		setSelectedAdminFullRole: (state: UserDataState, action) => {
			state.selectedAdminFullRole = action.payload;
		},
		setHistoryOrders: (state: UserDataState, action) => {
			state.historyOrders = action.payload.data;
		},
		setHistoryOrdersTotal: (state: UserDataState, action) => {
			state.historyOrdersTotal = action.payload.data;
		},
		setUserComments: (state: UserDataState, {payload}: PayloadAction<IComments[]>) => {
			state.commentaries = payload;
		},
		clearCommentaries: (state: UserDataState) => {
			state.commentaries = [];
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRecoverPasswordComplete.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
		});
		builder.addCase(fetchRecoverPasswordComplete.fulfilled, (state: UserDataState, {payload}) => {
			state.isShowLoader = false;
			state.status = 'succeeded';
			if (payload?.status === 200) {
				saveLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt, payload.data);
			}
			if (payload?.status === 204) {
				state.messageUser = messageErrRecoverCode;
				state.status = 'failed';
				state.isShowMessage = true;
			}
		});
		builder.addCase(fetchRecoverPasswordComplete.rejected, (state: UserDataState) => {
			state.messageUser = messageErrDataRecover;
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});

		builder.addCase(fetchRecoverPassword.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
		});
		builder.addCase(fetchRecoverPassword.fulfilled, (state: UserDataState, {payload}) => {
			state.isShowLoader = false;
			state.status = 'succeeded';

			if (payload?.status === 200) {
				return;
			}

			if (payload?.status === 204) {
				state.messageUser = messageErrNotDataRecover;
				state.isShowMessage = true;
			}
		});
		builder.addCase(fetchRecoverPassword.rejected, (state: UserDataState) => {
			state.messageUser = messageErrDataRecover;
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});

		builder.addCase(fetchGetUserById.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
		});
		builder.addCase(fetchGetUserById.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.status = 'succeeded';
				if (payload?.data?.description) {
					state.userData = payload?.data?.description?.Registered;
					state.nickName = payload?.data?.description?.Registered.name;
					saveLocalStorage(LOCAL_STORAGE_KEY_MAP.userNick, payload?.data?.description?.Registered.name);
				}
				if (payload?.data?.meta) {
					state.userMeta.avatar = payload?.data?.meta?.avatar;
					state.userMeta.subscribed_to_news = payload?.data?.meta?.subscribed_to_news;
				}

				state.userData.balance = payload?.data?.balance;
			}
			state.isShowLoader = false;
		});
		builder.addCase(fetchGetUserById.rejected, (state: UserDataState, action) => {
			if (action.payload === 500) {
				state.messageUser = messageBack;
			} else {
				state.messageUser = messageErrDataUserByLogin;
			}
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});
		builder.addCase(fetchGetUserByIdForAdmin.fulfilled, (state: UserDataState, action) => {
			if (action.payload) {
				state.userDataForAdmin = action.payload.data;
			}
		});
		builder.addCase(fetchRegistrationUser.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
			state.messageUser = emptyMessage;
		});
		builder.addCase(fetchRegistrationUser.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.status = 'succeeded';
				saveLocalStorage(LOCAL_STORAGE_KEY_MAP.userNick, payload.dataUser);
			}
			if (payload?.status === 204) {
				state.isShowLoader = false;
				state.messageUser = messageErrStep1;
				state.isShowMessage = true;
			}
		});
		builder.addCase(fetchRegistrationUser.rejected, (state: UserDataState, action) => {
			if (action.payload === 500) {
				state.messageUser = messageBack;
			} else {
				state.messageUser = messageRepeat;
			}
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});
		builder.addCase(fetchAuthorizeUser.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
		});
		builder.addCase(fetchAuthorizeUser.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.jwt = payload.jwt;
				if (payload.jwtData) {
					state.jwtData = payload.jwtData;
					const hasAdminRole = payload.jwtData?.roles?.find((role) => isAdminRole(role) || isModeratorRole(role) || isOwnerRole(role)) !== undefined;
					saveLocalStorage(LOCAL_STORAGE_KEY_MAP.hasAdminRole, hasAdminRole);
				}
				state.status = 'succeeded';
				state.isAuth = true;
				saveLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt, payload.jwt);
				saveLocalStorage(LOCAL_STORAGE_KEY_MAP.auth, true);
			}
			if (payload?.status === 204) {
				state.messageUser = messageAuthError;
				state.status = 'failed';
				state.isShowMessage = true;
			}
			state.isShowLoader = false;
		});
		builder.addCase(fetchAuthorizeUser.rejected, (state: UserDataState, action) => {
			if (action.payload === 500) {
				state.messageUser = messageAuthError;
			}
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});
		builder.addCase(fetchGetAllUsers.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
		});
		builder.addCase(fetchGetAllUsers.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.allUsers = [...payload.data];
				state.totalCount = payload.totalCount;
				state.isShowLoader = false;
				state.status = 'succeeded';
			}
		});
		builder.addCase(fetchGetAllUsers.rejected, (state: UserDataState) => {
			state.messageUser = messageErrorData;
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});

		builder.addCase(fetchGetUserFullRoles.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
			state.messageUser = emptyMessage;
		});
		builder.addCase(fetchGetUserFullRoles.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.isShowLoader = false;
				if (state.jwtData.sub === payload.data.sub || getIdUser() === payload.data.sub) {
					const filteredRoles = filterAdminRoles(payload.data.roles);
					state.checkedUserFullRoles = filteredRoles;
					state.selectedAdminFullRole = filteredRoles.at(0);
					saveLocalStorage(LOCAL_STORAGE_KEY_MAP.selectedFullRole, filteredRoles.at(0));
				}
				state.viewedUserFullRoles = payload.data.roles;
				state.status = 'succeeded';
			}
		});
		builder.addCase(fetchGetUserFullRoles.rejected, (state: UserDataState) => {
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});

		builder.addCase(fetchGetUserRoles.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
			state.messageUser = emptyMessage;
		});
		builder.addCase(fetchGetUserRoles.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.isShowLoader = false;
				state.status = 'succeeded';
			}
		});
		builder.addCase(fetchGetUserRoles.rejected, (state: UserDataState) => {
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});

		builder.addCase(fetchUpdateDataUser.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
			state.messageUser = emptyMessage;
		});
		builder.addCase(fetchUpdateDataUser.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.status = 'succeeded';
				state.messageUser = messageSuccessUpdatePlatform;
				state.isShowMessage = true;
			}
			state.isShowLoader = false;
		});
		builder.addCase(fetchUpdateDataUser.rejected, (state: UserDataState) => {
			state.messageUser = messageErrorUpdateUser;
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});

		builder.addCase(fetchUpdatePassword.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
			state.messageUser = emptyMessage;
		});
		builder.addCase(fetchUpdatePassword.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.status = 'succeeded';
				state.messageUser = messageSuccessUpdatePlatform;
				state.isShowMessage = true;
			}
			state.isShowLoader = false;
		});
		builder.addCase(fetchUpdatePassword.rejected, (state: UserDataState) => {
			state.messageUser = messageErrorServer;
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});

		builder.addCase(fetchGetAllUsersBlackList.pending, (state: UserDataState) => {
			state.isShowLoader = true;
			state.status = 'loading';
		});
		builder.addCase(fetchGetAllUsersBlackList.fulfilled, (state: UserDataState, {payload}) => {
			if (payload?.status === 200) {
				state.status = 'succeeded';
				state.allBlackUsers = payload.data;
				state.allBlackTotalCount = payload.totalCount;
			}
			if (payload?.status === 204) {
				state.status = 'succeeded';
				state.allBlackUsers = [];
			}
			state.isShowLoader = false;
		});
		builder.addCase(fetchGetAllUsersBlackList.rejected, (state: UserDataState) => {
			state.messageUser = messageErrorServer;
			state.isShowLoader = false;
			state.status = 'failed';
			state.isShowMessage = true;
		});
		builder.addCase(fetchGetAvatars.fulfilled, (state: UserDataState, {payload}) => {
			state.avatars = [...payload?.avatars];
		});
	},
});

export const {
	setIsLoginPage,
	setBlackList,
	resetDataUser,
	setMessageUser,
	setIsAuthUser,
	setShowLoader,
	setIsShowMessage,
	setResetIsAuth,
	setResetMessageUser,
	setUserCity,
	setHistoryOrders,
	setHistoryOrdersTotal,
	setUserComments,
	clearCommentaries,
	setSelectedAdminFullRole,
} = userDataSlice.actions;
