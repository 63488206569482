import {FC, memo} from 'react';

import filter from '../../../assets/icon/filter.svg';
import show_map_icon from '../../../assets/icon/sporder-icon-to-map.svg';
import * as SC  from './button-filter.style';

interface Props{
	onClickShowFilters: ()=>void;
	className?: string;
	onClickMap?: ()=>void;
	isOpenMap?: boolean;
}

export const ButtonFilter:FC<Props> = memo(({onClickShowFilters, onClickMap, isOpenMap, className}) => {

	return (
		<SC.ButtonsWrapper className={className}>
			<SC.ContainerButtonFilter onClick={onClickShowFilters} onClickMap={!!onClickMap}>
				<span>Фильтры</span>
				<SC.Icon src={filter} alt={'filter'}/>
			</SC.ContainerButtonFilter>
			{onClickMap && <SC.ShowMap onClick={onClickMap}>
				<SC.TextButton>{isOpenMap ? 'Скрыть карту' :'Показать на карте'}</SC.TextButton>
				<SC.TextButtonMobile>карта</SC.TextButtonMobile>
				<SC.Icon src={show_map_icon} alt="show-map-icon" loading={'lazy'}/>
			</SC.ShowMap>}
		</SC.ButtonsWrapper>
	);
});
