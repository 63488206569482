import {memo} from 'react';

import close_icon from '../../assets/icon/icon_cross.svg';
import * as SC from './close-icon.style';

export const CloseIcon = memo(() => {
	return (
		<SC.CloseIcon src={close_icon} alt={'close icon'}/>
	);
});
