import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {initialReservationData} from '../../components/forms/services/components/constants/initial';
import {OrderPolicy, OrderStepPolicy} from '../../interfaces/service';

export interface initialStateServiceSlice {
	serviceKind: string,
	orderPolicy: OrderPolicy
}

export const initialState: initialStateServiceSlice = {
	serviceKind: '',
	orderPolicy: initialReservationData,
};


const serviceReservationModalSlice = createSlice({
	name: 'serviceReservationModal',
	initialState,
	reducers: {
		changeServiceKind: (state: initialStateServiceSlice, action: PayloadAction<string>) => {
			state.serviceKind = action.payload;
		},
		changePeriodPolicy: (state: initialStateServiceSlice, action: PayloadAction<OrderStepPolicy | null>) => {
			if (action.payload === null) {
				state.orderPolicy.periodic = action.payload;
				return;
			}
			state.orderPolicy.periodic = {
				order_step: action.payload!.order_step,
				service_step: action.payload!.service_step,
				min_order_duration: action.payload!.min_order_duration,
			};
		},
		changeReservationType: (state: initialStateServiceSlice, action: PayloadAction<'View' | 'Request' | 'Book' | 'AbonementOnly'>) => {
			state.orderPolicy.allows = action.payload;
		},
		changeDurationFree: (state) => {
			state.orderPolicy.duration_free = !state.orderPolicy.duration_free;
		},
		changeTrial: (state: initialStateServiceSlice, action: PayloadAction<number | null>) => {
			state.orderPolicy.trial_order = action.payload === null ? null : action.payload;
		},
		changePeriodic: (state: initialStateServiceSlice, action) => {
			state.orderPolicy.periodic = action.payload;
		},
		changeServiceUpdate: (state: initialStateServiceSlice, action: PayloadAction<OrderPolicy>) => {
			return {
				...state,
				orderPolicy: {
					...state.orderPolicy,
					allows: action.payload.allows,
					attachable: action.payload.attachable,
					max_date_delay: action.payload.max_date_delay,
					max_user_hours: action.payload.max_user_hours,
					max_user_services: action.payload.max_user_services,
					periodic: action.payload.periodic,
					// TODO: теряем информацию о копейках (14.50 становится 14)
					trial_order: action.payload.trial_order === null ? null : action.payload.trial_order / 100,
					duration_free: action.payload.duration_free,
				},
			};
		},
	},
});


export const serviceReservationModal = serviceReservationModalSlice.reducer;
export const {
	changeTrial,
	changeServiceKind,
	changePeriodPolicy,
	changeReservationType,
	changeDurationFree,
	changeServiceUpdate,
	changePeriodic,
} = serviceReservationModalSlice.actions;
