import {createSlice} from '@reduxjs/toolkit';

import {AbonementType} from '../../interfaces/abonement';
import {fetchGetHistoryAbonementTotal} from '../thunks/abonements/fetch-get-history-abonement-total';
import {fetchGetActiveAbonement} from '../thunks/abonements/get-active-abonements';
import {fetchGetHistoryAbonement} from '../thunks/abonements/get-history-abonements';
import {fetchGetListAbonements} from '../thunks/abonements/get-list-abonements';
import {fetchGetTotalListAbonements} from '../thunks/abonements/get-total-list-abonements';

interface InitialStateAbonement {
	abonements: AbonementType[];
	checkedAbonementUpdate: AbonementType;
	activeAbonements: { abonement: AbonementType, expire_at: string }[];
	historyAbonements: { abonement: AbonementType, expire_at: string }[];
	historyAbonementsTotal: number;
	total: number;
}

const initialState: InitialStateAbonement = {
	abonements: [],
	checkedAbonementUpdate: {} as AbonementType,
	total: 0,
	historyAbonementsTotal: 0,
	activeAbonements: [],
	historyAbonements: [],
};

export const abonementSlice = createSlice({
	name: 'abonement',
	initialState,
	reducers: {
		setCheckedAbonementUpdate: (state: InitialStateAbonement, action) => {
			state.checkedAbonementUpdate = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchGetListAbonements.fulfilled, (state, action) => {
			state.abonements = action.payload?.data || [];
		});
		builder.addCase(fetchGetTotalListAbonements.fulfilled, (state, action) => {
			state.total = action.payload?.data;
		});
		builder.addCase(fetchGetActiveAbonement.fulfilled, (state, action) => {
			state.activeAbonements = action.payload?.data || [];
		});
		builder.addCase(fetchGetHistoryAbonement.fulfilled, (state, action) => {
			state.historyAbonements = action.payload?.data || [];
		});
		builder.addCase(fetchGetHistoryAbonementTotal.fulfilled, (state, action) => {
			state.historyAbonementsTotal = action.payload?.data;
		});
	},
});

export const abonement = abonementSlice.reducer;
export const {setCheckedAbonementUpdate} = abonementSlice.actions;
