import axios from 'axios';

import {AddAbonement, DeleteAbonement, UpdateAbonement} from '../interfaces/abonement';
import {
	BlackListUser,
	BlackListUserRemove,
	CloseOrderData,
	OrderAttachConfirmOrder,
	OrganizationUpdate,
	SystemID,
} from '../interfaces/interfaces';
import {OrderAttachDelete, OrderAttachList, TOrderClaimCreate, TOrderCreate} from '../interfaces/order';
import {RegisterPlatform} from '../interfaces/platform';
import {AddService, UpdateService} from '../interfaces/service';
import {
	HOST_CRM, urlAddAbonement,
	urlAddService,
	urlAddUserInBlackList, urlDeactivateService,
	urlGetAllUsersBlackList,
	urlGetAllUsersBlackListTotalCount,
	urlGetModeratorsByOrganization,
	urlGetModeratorsTotalCount,
	urlGetOrderAttach,
	urlGetOrders,
	urlGetOwnersOrg,
	urlGetTotalOrders,
	urlGetUsersByOrder,
	urlGetWorkload, urlNotificationsPlatform, urlPutOrderActivate,
	urlPutOrderAttach, urlPutOrderClaim,
	urlPutOrderClose, urlPutOrderConfirm,
	urlRecoverService, urlRemoveAbonement,
	urlRemoveDependency,
	urlRemoveOrderAttach,
	urlRemoveService,
	urlRemoveUserInBlackList, urlSetOrder, urlUpdateAbonement,
	urlUpdateDependency,
	urlUpdateOrganization,
	urlUpdatePlatform,
	urlUpdateService,
} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';
import {
	ActivateOrderParams,
	AllOrdersParams,
	ConfirmOrderParams,
	GetAllBlackListParams,
	GetAllModeratorParams
} from './query-params';

const instanceJwt = axios.create({
	baseURL: HOST_CRM,
	headers: {
		'Content-Type': 'application/json',
	},
});

instanceJwt.interceptors.request.use(
	(request) => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('jwt_token');
		if (token) {
			request.headers.Authorization = `Bearer ${token}`;
		} else {
			const jwt = jwtUser();
			if (jwt) {
				request.headers.Authorization = `Bearer ${jwt}`;
				return request;
			}
			request.headers.Authorization = 'Bearer 1';
		}

		return request;
	},
	(error) => {
		console.log(error, 'Error in request interceptor');
		return Promise.reject(error);
	},
);

export const crmPlatformAPI = {
	updateOrganization(data: OrganizationUpdate) {
		return instanceJwt.put(urlUpdateOrganization, data);
	},
	updatePlatform(data: RegisterPlatform) {
		return instanceJwt.put(urlUpdatePlatform, data);
	},
	addService(data: AddService) {
		return instanceJwt.post(urlAddService, data);
	},
	updateService(data: UpdateService) {
		return instanceJwt.put(urlUpdateService, data);
	},
	getWorkload(idService: string, date: string) {
		return instanceJwt.get(`${urlGetWorkload}/${idService}/${date}`);
	},
	setBookOrderUser(data: TOrderCreate) {
		// TODO: move trial_order to TOrderCreate and fix isDisabled in confirm-order page
		return instanceJwt.post(urlSetOrder, {...data, trial_order: false});
	},
	setClaimOrderUser(data: TOrderClaimCreate) {
		return instanceJwt.post(urlPutOrderClaim, {...data});
	},
	getOrderAttach(params: OrderAttachList) {
		return instanceJwt.get(urlGetOrderAttach, {params});
	},
	removeOrderAttach(params: OrderAttachDelete) {
		return instanceJwt.put(urlRemoveOrderAttach, params);
	},
	getUsersByOrder(idService: string, date: string, timePoint: number) {
		return instanceJwt.get(`${urlGetUsersByOrder}/${idService}/${date}/${timePoint}`);
	},
	putOrderClose(data: CloseOrderData) {
		return instanceJwt.put(urlPutOrderClose, data);
	},
	putOrderActivate(data: ActivateOrderParams) {
		return instanceJwt.put(urlPutOrderActivate, data);
	},
	putOrderConfirm(data: ConfirmOrderParams) {
		return instanceJwt.put(urlPutOrderConfirm, data);
	},
	putOrderAttach(data: OrderAttachConfirmOrder) {
		return instanceJwt.put(urlPutOrderAttach, data);
	},
	updateDependency(params: { dependency: string, origin: string, amount: number | null }) {
		return instanceJwt.put(urlUpdateDependency, params);
	},
	removeDependency(params: { dependency: string, origin: string }) {
		return instanceJwt.put(urlRemoveDependency, params);
	},
	getNotificationsPlatform(idPlatform: string) {
		return instanceJwt.get(`${urlNotificationsPlatform}/${idPlatform}`);
	},
	addUserInBlackList(data: BlackListUser) {
		return instanceJwt.post(urlAddUserInBlackList, data);
	},
	addAbonement(data: AddAbonement) {
		return instanceJwt.post(urlAddAbonement, data);
	},
	updateAbonement(data: UpdateAbonement) {
		return instanceJwt.put(urlUpdateAbonement, data);
	},
	removeAbonement(data: DeleteAbonement) {
		return instanceJwt.put(urlRemoveAbonement, data);
	},
	removeUserInBlackList(data: BlackListUserRemove) {
		return instanceJwt.put(urlRemoveUserInBlackList, data);
	},
	getAllUsersBlackList(params: GetAllBlackListParams) {
		return instanceJwt.get(urlGetAllUsersBlackList, {params});
	},
	getAllUsersBlackListTotalCount(params: GetAllBlackListParams) {
		return instanceJwt.get(urlGetAllUsersBlackListTotalCount, {params});
	},
	getModeratorsByOrganization(params: GetAllModeratorParams) {
		return instanceJwt.get(urlGetModeratorsByOrganization, {params});
	},
	getModeratorsTotalCount(params: GetAllModeratorParams) {
		return instanceJwt.get(urlGetModeratorsTotalCount, {params});
	},
	getOrders(params: AllOrdersParams) {
		return instanceJwt.get(urlGetOrders, {params});
	},
	getTotalOrders(params: AllOrdersParams) {
		return instanceJwt.get(urlGetTotalOrders, {params});
	},
	getOwnersOrg(params: { org_name: string }) {
		return instanceJwt.get(urlGetOwnersOrg, {params});
	},
	deactivateService(data: SystemID) {
		return instanceJwt.put(urlDeactivateService, data);
	},
	removeService(data: SystemID) {
		return instanceJwt.put(urlRemoveService, data);
	},
	recoverService(data: SystemID) {
		return instanceJwt.put(urlRecoverService, data);
	},
};
