import {createSlice} from '@reduxjs/toolkit';

import {UserWithPlatformInfo} from '../../interfaces/interfaces';
import {OrganizationListItem, OrganizationSingle, Records} from '../../interfaces/organization';
import {ServiceListItem} from '../../interfaces/service';
import {fetchGetServicesOrg} from '../thunks/abonements/get-services-orgs';
import {fetchGetModeratorsByOrganizationTotal} from '../thunks/organization/get-moderators-by-organization-total-thunk';
import {fetchGetOrgCreations} from '../thunks/record/get-applications-orgs-record';

export interface OrganizationState {
	organizationNames: {org_name: string}[];
	organizations: OrganizationListItem[];
	allModeratorsOrg: UserWithPlatformInfo[];
	totalCount: number;
	singleOrganization: OrganizationSingle | null;
	organizationsTotal: number;
	orgsCreations: Records[]
	servicesOrg:  ServiceListItem[]
}

const initialState: OrganizationState = {
	organizationNames: [],
	servicesOrg: [],
	organizations: [],
	orgsCreations: [],
	allModeratorsOrg: [],
	totalCount: 0,
	singleOrganization: null,
	organizationsTotal: 0,
};

export const organizationSlice = createSlice({
	name: 'organizationSlice',
	initialState,
	reducers: {
		setOrganizationsTotal: (state: OrganizationState, action) => {
			state.organizationsTotal = action.payload.data;
		},
		setOrganizationNames: (state: OrganizationState, action) => {
			state.organizationNames = action.payload.data;
		},
		setOrganizations: (state: OrganizationState, action) => {
			state.organizations = [...action.payload.data];
		},
		setModeratorsOrg: (state: OrganizationState, action) => {
			state.allModeratorsOrg = [...action.payload.data];
		},
		setSingleOrganization: (state: OrganizationState, action) => {
			state.singleOrganization = {...action.payload.data};
		},
		resetSingleOrganization: (state: OrganizationState)=>{
			state.singleOrganization = null;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchGetOrgCreations.fulfilled, (state, action) => {
			state.orgsCreations = action.payload?.data;
		});
		builder.addCase(fetchGetModeratorsByOrganizationTotal.fulfilled, (state, action) => {
			state.totalCount = action.payload?.data;
		});
		builder.addCase(fetchGetServicesOrg.fulfilled, (state, action) => {
			state.servicesOrg = action.payload?.data;
		});
	}
});

export const {
	setOrganizationsTotal,
	setOrganizationNames,
	resetSingleOrganization,
	setOrganizations,
	setModeratorsOrg,
	setSingleOrganization,
} = organizationSlice.actions;
