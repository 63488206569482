import {AbonementDuration} from '../../../interfaces/abonement';
import {Payment, Rewards} from '../../../interfaces/payment';
import {convertPriceToString} from '../../../shared/utils/converter-price';
import {getCountLabel, getMonthsLabel, getWeeksLabel} from '../../../shared/utils/transform-text-label';

export const calculateFinishPrice = ({price, balance, useAllBonuses}: CalculateFinishPrice): string => {
	if (price <= balance && useAllBonuses) {
		return '0 BYN';
	} else if (useAllBonuses) {
		return convertPriceToString(price - balance);
	}
	return convertPriceToString(price);

};

export const addToDate = (dateString: string, duration: AbonementDuration) => {
	const date = new Date(dateString);

	if ('Month' in duration) {
		date.setMonth(date.getMonth() + duration.Month);
	} else {
		const weeksToDays = duration.Week * 7;
		date.setDate(date.getDate() + weeksToDays);
	}

	const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
	return date.toLocaleDateString('ru-RU', options);
};

export const workingAbonementTo = (duration: AbonementDuration) => {
	const today = new Date();

	if ('Month' in duration) {
		today.setMonth(today.getMonth() + duration.Month);
	} else {
		const weeksToDays = duration.Week * 7;
		today.setDate(today.getDate() + weeksToDays);
	}

	const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
	return today.toLocaleDateString('ru-RU', options);
};

export const getLimitAbonement = (duration: AbonementDuration) => {

	return `${'Month' in duration
		? `${duration.Month} ${getMonthsLabel(duration.Month)}`
		: `${duration.Week} ${getWeeksLabel(duration.Week)}`}`;
};
export const getLimitClassesAbonement = (limit: number | null) => {

	return limit !== null ? `${limit} ${getCountLabel(limit)}` : 'Безлимит';
};
export const getBonusesWordIcon = (count: number): string => {
	// Округляем до одного знака после запятой
	const desiredValue = Math.floor(count * 100) / 100;
	const roundedCount = parseFloat(desiredValue.toFixed(2));

	// Получаем целую часть и дробную часть
	const integerPart = Math.floor(roundedCount);
	const decimalPart = roundedCount % 1;

	if (integerPart % 100 >= 11 && integerPart % 100 <= 14) {
		return `${roundedCount} баллов`;
	} else if (integerPart % 10 === 1 && decimalPart === 0) {
		return `${roundedCount} балл`;
	} else if (integerPart % 10 >= 2 && integerPart % 10 <= 4 && decimalPart === 0) {
		return `${roundedCount} балла`;
	}
	return `${roundedCount} баллов`;
};
export const getBonusesWord = (count: number) => {
	// Округляем до одного знака после запятой
	const desiredValue = Math.floor(count * 100) / 100;
	const roundedCount = parseFloat(desiredValue.toFixed(1));

	// Получаем целую часть
	const integerPart = Math.floor(roundedCount);
	const decimalPart = roundedCount % 1;

	if (integerPart % 100 >= 11 && integerPart % 100 <= 14) {
		return `Начислим вам ${roundedCount} баллов после визита`;
	}
	if (integerPart % 10 === 1 && decimalPart === 0) {
		return `Начислим вам ${roundedCount} балл после визита`;
	}
	if (integerPart % 10 >= 2 && integerPart % 10 <= 4 && decimalPart === 0) {
		return `Начислим вам ${roundedCount} балла после визита`;
	}
	return `Начислим вам ${roundedCount} баллов после визита`;
};

interface BonusesAvailable {
	price: number;
	balance: number;
	useAllBonuses: boolean;
}

export const bonusesAvailable = ({price, balance, useAllBonuses}: BonusesAvailable) => {

	if (balance <= price && useAllBonuses) {
		return '0 бонусов';
	}

	return useAllBonuses
		? getBonusesWordIcon((balance - price) / 100)
		: getBonusesWordIcon(balance / 100);
};

interface CalculateFinishPrice {
	price: number;
	balance: number;
	useAllBonuses: boolean;
}

interface CanculateForPaymentSuccess {
	rewards: Rewards;
	payment: Payment;
}

interface CanculateForPayment {
	balance: number;
	rewards: Rewards;
	price: number;
	useAllBonuses: boolean;
}

type CanculateForPaymentOplati = Omit<CanculateForPayment, 'rewards'>;

export const canculateForPayment = ({price, rewards, useAllBonuses, balance}: CanculateForPayment) => {
	const priceAfterByBonuses = (useAllBonuses && balance > 0) ? price - (price >= balance ? balance : price) : price;

	if (price <= balance && useAllBonuses) {
		return 'Оплата полностью баллами, так держать!';
	}

	if (rewards.oplati_payment_reward_coef > rewards.bepaid_payment_reward_coef) {
		return `до ${getBonusesWordIcon((priceAfterByBonuses / 100 * rewards.oplati_payment_reward_coef) / 100)}`;
	}

	if (rewards.oplati_payment_reward_coef < rewards.bepaid_payment_reward_coef) {
		return `до ${getBonusesWordIcon((priceAfterByBonuses / 100 * rewards.bepaid_payment_reward_coef) / 100)}`;
	}

	return getBonusesWordIcon((priceAfterByBonuses / 100 * rewards.bepaid_payment_reward_coef) / 100);
};

export const canculateForPaymentSuccess = ({rewards, payment}: CanculateForPaymentSuccess) => {
	if (payment.provider === 'Oplati') {
		return getBonusesWordIcon((payment.price / 100 * rewards.oplati_payment_reward_coef) / 100);
	}
	return getBonusesWordIcon((payment.price / 100 * rewards.bepaid_payment_reward_coef) / 100);
};

export const canculateForPaymentOplati = ({price, useAllBonuses, balance}: CanculateForPaymentOplati) => {
	const priceAfterByBonuses = (useAllBonuses && balance > 0) ? price - (price >= balance ? balance : price) : price;
	if (price <= balance && useAllBonuses) {
		return undefined;
	}

	return getBonusesWordIcon((priceAfterByBonuses / 100 * 0.1));
};
