import styled from '@emotion/styled';

export const Main = styled.main`
	margin: var(--Spacing-desctop-H1-M) auto;
	max-width: 1410px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-H2);
	align-self: stretch;
	width: 100%;
	padding: 0 var(--Spacing-desctop-Block-S);

	@media screen and (max-width: 860px){
		margin: var(--Spacing-desctop-H2) auto;
		gap: 30px;
	}
	@media screen and (max-width: 470px){
		gap: 24px;
	}
`;
