import {FC, memo} from 'react';

import * as SC from './link-with-line-bottom.style';

interface Props {
	onClick?: () => void;
	label: string;
	className?: string;
	path: string
	target?: '_blank' | '_parent' | '_top' | '_self'
}

export const LinkWithLineBottom: FC<Props> = memo(({label, className, path, onClick, target}) => {

	return (
		<>
			{target
				? <SC.LinkATeg className={className} onClick={onClick} href={path} target={target}>{label}</SC.LinkATeg>
				: <SC.LinkReact className={className} onClick={onClick} to={path}>{label}</SC.LinkReact>}
		</>
	);
});
