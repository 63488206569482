import {FC, memo, useCallback, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import conditions from '../../../../../assets/icon/conditions.svg';
import {AbonementType} from '../../../../../interfaces/abonement';
import {useHandleGoToAuth} from '../../../../../pages/orders/utils';
import {useAppSelector} from '../../../../../redux/selectors/hook';
import {servicesPlatformCheckedSelector} from '../../../../../redux/selectors/platforms-state-selectors';
import {dataUserBalanceSelector} from '../../../../../redux/selectors/user-state-selectors';
import {useAppDispatch} from '../../../../../redux/store';
import {fetchGetConfig} from '../../../../../redux/thunks/payment/get-config';
import {fetchSetPayment} from '../../../../../redux/thunks/payment/set-payment';
import {fetchGetServicesByFilter} from '../../../../../redux/thunks/services/get-services-by-filter';
import {fetchGetUserById} from '../../../../../redux/thunks/user/get-user-by-id';
import {useResizeWindow} from '../../../../../shared/hooks/use-resize-window';
import {convertPriceToString} from '../../../../../shared/utils/converter-price';
import {getIdUser, isRightTokenTime} from '../../../../../shared/utils/decoder-jwt';
import {orgLogoSmall} from '../../../../../shared/utils/get-images-size';
import {converterTime} from '../../../../../shared/utils/time/converter-time';
import {ButtonNew} from '../../../../buttons-new';
import {ButtonCheckbox} from '../../../../controls-new/checkboxes/button-checkbox/button-checkbox';
import {InputPhone} from '../../../../controls-new/input-phone/input-phone';
import {ModalNew} from '../../../../modal-new/modal-new';
import {PaymentMethods} from '../../../../payment-methods/payment-methods';
import {BenefitRegister} from '../../components/benefit-register/benefit-register';
import {BonusesIconText} from '../../components/bonuses-icon-text/bonuses-icon-text';
import {LabelDetail} from '../../components/label-detail/label-detail';
import {Title} from '../../components/title/title';
import {
	bonusesAvailable,
	calculateFinishPrice,
	canculateForPaymentOplati,
	getLimitClassesAbonement,
	workingAbonementTo
} from '../../utils';
import {OrgInfo} from '../components/org-info/org-info';
import {OneServiceAdaptive} from '../one-service-adaptive/one-service-adaptive';
import * as SC from './modal-payments-abonement-one-service.style';

interface Props {
	abonement: AbonementType;
	onClickClose: () => void;
}

export interface Form {
	phone: string;
	provider: string;
}

export const ModalPaymentsAbonementOneService: FC<Props> = memo(({abonement, onClickClose}) => {
	const idUser = getIdUser();
	const dispatch = useAppDispatch();
	const bonuses = useAppSelector(state => state.payment.config);
	const services = useAppSelector(servicesPlatformCheckedSelector);
	const balance = useAppSelector(dataUserBalanceSelector);
	const price = abonement.info.price;
	const [useAllBonuses, setUseAllBonuses] = useState(false);
	const width = useResizeWindow();
	const [showMore, setShowMore] = useState(false);
	const metods = useForm<Form>({defaultValues: {phone: '', provider: 'Oplati'}});
	const {register, watch, setValue} = metods;
	const phoneRegex = /^\+375(29|33|44|25)\d{7}$/;
	const isValidPhone = watch('phone').length !== 13 || !phoneRegex.test(watch('phone'));
	const jwtTokenTime = isRightTokenTime();

	useEffect(() => {
		dispatch(fetchGetConfig());
		dispatch(fetchGetServicesByFilter({abonement_id: [abonement.id], allow_view_only: true}));
		if (jwtTokenTime) {
			dispatch(fetchGetUserById(idUser));
		}
	}, [jwtTokenTime]);

	const clickUseAllBonuses = useCallback(() => setUseAllBonuses(prevState => !prevState), []);

	const onClickBye = useCallback(() => {
		dispatch(fetchSetPayment({
			issuer: {Abonement: {id: abonement.id}},
			provider: watch('provider'),
			requester: jwtTokenTime ? {Registered: {id: idUser}} : {Anonym: {phone: watch('phone')}},
			success_url: `${window.location.protocol}//${window.location.host}/payment-abonement-success/${abonement.id}`,
			fail_url: `${window.location.protocol}//${window.location.host}/payment-abonement-fail/${abonement.id}`,
			used_bonus_amount: (useAllBonuses && balance > 0) ? (price >= balance ? balance : price) : undefined,
		}));
	}, [useAllBonuses, abonement.id, price, balance, jwtTokenTime, watch('provider')]);

	const onClickHandlerClose = useCallback(() => {
		if (showMore) {
			setShowMore(false);
			return;
		}
		onClickClose();
	}, [showMore]);
	const clickShowMore = () => setShowMore(prevState => !prevState);

	const onChangeServicePayment = useCallback((value: string) => setValue('provider', value), []);
	const city = abonement.platform_info?.city ?? null;
	const address = abonement.platform_info?.location_info.address || '';
	return (
		<ModalNew classNameWrapper={SC.Modal} onClickClose={onClickHandlerClose}>
			<Title title={(showMore && width <= 700) ? 'Подробности абонемента' : 'Оплата абонемента'}/>
			{width > 700 || showMore
				? <SC.OrderDetails>
					<OrgInfo city={city}
							 org_name={abonement.org_info.org_name}
							 address={address}
							 logo={orgLogoSmall(abonement.org_info.logo)}/>
					<SC.ServiceType>{abonement.info.name}</SC.ServiceType>
					{abonement.info.description.length > 0 &&
						<SC.Description>{abonement.info.description}</SC.Description>}
					<SC.AbonementDetails>
						<SC.AbonementDetailsWrapper>
							{services.length > 0 && <SC.DetailWrapper>
								<LabelDetail label={'Вид спорта'}/>
								<SC.Detail>{services[0].sports.join(', ')}</SC.Detail>
							</SC.DetailWrapper>}
							{services.length > 0 && <SC.DetailWrapper>
								<LabelDetail label={'Тип услуги'}/>
								<SC.Detail>{services[0].service_type}</SC.Detail>
							</SC.DetailWrapper>}
							<SC.DetailWrapper>
								<LabelDetail label={'Количество занятий'}/>
								<SC.Detail>{getLimitClassesAbonement(abonement.info.limit)}</SC.Detail>
							</SC.DetailWrapper>
							<SC.DetailWrapper>
								<LabelDetail label={'Время посещения'}/>
								{(abonement.info.working_from && abonement.info.working_to) ?
									<SC.Detail>с {converterTime(abonement.info.working_from)} до {converterTime(abonement.info.working_to)}</SC.Detail>
									: <SC.Detail>Весь день</SC.Detail>}
							</SC.DetailWrapper>
							<SC.DetailWrapper>
								<LabelDetail label={'Длительность'}/>
								<SC.Detail>До {workingAbonementTo(abonement.info.duration)}</SC.Detail>
							</SC.DetailWrapper>
							<SC.Price>Стоимость {convertPriceToString(price)}</SC.Price>
						</SC.AbonementDetailsWrapper>
						<SC.BobusesContainer>
							<BonusesIconText rewards={bonuses.rewards} price={abonement.info.price} balance={balance}
											 useAllBonuses={useAllBonuses}/>
							{jwtTokenTime && <SC.BonusesWrapper>
								<SC.HaveBonuses>Доступно {bonusesAvailable({
									useAllBonuses, price, balance
								})}</SC.HaveBonuses>
								<ButtonCheckbox label={'Cписать'} checked={useAllBonuses} id={'bonuses'}
												name={'bonuses'}
												value={'bonuses'} handleCheckboxChange={clickUseAllBonuses}/>
								<SC.ResultPrice>
									<span>Итого</span>
									<SC.Price>{calculateFinishPrice({price, balance, useAllBonuses})}</SC.Price>
								</SC.ResultPrice>
							</SC.BonusesWrapper>}
						</SC.BobusesContainer>
					</SC.AbonementDetails>
					<SC.VisitingConditionsWrapper>
						<SC.TitleWrapperConditions>
							<img src={conditions} alt="conditions icon"/>
							<SC.TitleConditions>Условия посещения</SC.TitleConditions>
						</SC.TitleWrapperConditions>
						<SC.Conditions>Для комфортных тренировок необходимо бронировать посещение заранее.
							Это можно сделать в разделе "Мои заказы" вашего личного кабинета.</SC.Conditions>
					</SC.VisitingConditionsWrapper>
					<FormProvider {...metods}>
						{!jwtTokenTime && <InputPhone keyForm={'phone'} {...register('phone')}/>}
						<PaymentMethods onChangeServicePayment={onChangeServicePayment} value={watch('provider')}
										discount={canculateForPaymentOplati({useAllBonuses, balance, price})}/>
					</FormProvider>
					<ButtonNew label={'Оплатить'} bgColor={'purple'} onClick={onClickBye}
							   disabled={jwtTokenTime ? false : isValidPhone}/>
					{!jwtTokenTime &&
						<BenefitRegister goToAuth={useHandleGoToAuth}/>}
				</SC.OrderDetails>
				: <OneServiceAdaptive abonement={abonement} balance={balance} onClickPayment={onClickBye}
									  showMore={showMore} clickShowMore={clickShowMore} useAllBonuses={useAllBonuses}
									  clickUseAllBonuses={clickUseAllBonuses}
									  onChangeServicePayment={onChangeServicePayment} useProvider={watch('provider')}/>}
		</ModalNew>
	);
});
