export const requestCodes = {
	success: 200,
	emptyData: 204,
	badRequest: 400,
	unauthorized: 401,
	forbidden: 403,
	notFound: 404,
	methodNotAllowed: 405,
	conflict: 409,
	serverError: 500,
};
