import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const SectionWelcome = css`
	padding: 0;
`;

export const BoxItems = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 1218px;
	margin: 65px auto 0;
	@media screen and (max-width: 700px) {
		margin: 40px auto 0;
	}
`;

export const ImgContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	max-width: 76%;
	margin-left: -10px;
	padding: 60px 50px;
	background-image: url('../../../assets/img/start_section_7.webp');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	aspect-ratio: 930/ 560;
	@media screen and (max-width: 1100px) {
		max-width: 100%;
		margin-left: 0;
		padding: 60px 50px;

		aspect-ratio: 930/ 560;
	}
	@media screen and (max-width: 850px) {
		padding: 60px 20px;
	}
	@media screen and (max-width: 700px) {
		justify-content: center;
		align-items: center;
		height: 300px;
		padding: 110px 20px;
	}
	@media screen and (max-width: 400px) {
		padding: 70px 20px;
	}

	@media screen and (max-width: 340px) {
		padding: 50px 20px;
	}
`;

export const TextContainer = styled.div`
	width: 331px;

	@media screen and (max-width: 750px) {
		width: 290px;
	}
`;

export const ImgContainerTitle  = styled.h3`
	font-weight: 600;
	font-size: 4rem;
	line-height: 52px;
	color: #c6f432;
	@media screen and (max-width: 1400px) {
		font-size: 3rem;
	}
	@media screen and (max-width: 1100px) {
		font-size: 2.5rem;
	}
	@media screen and (max-width: 850px) {
		font-size: 2rem;
	}
	@media screen and (max-width: 700px) {
		margin: 0 auto;
		line-height: 2.4rem;
	}
`;

export const ImgContainerText = styled.p`
	margin-top: 15px;
	font-weight: 400;
	font-size: 2.4rem;
	line-height: 31px;
	color: var(--white-color);

	span {
		font-weight: 700;
		color: #c6f432;
		text-transform: uppercase;
	}

	@media screen and (max-width: 1400px) {
		font-size: 2.2rem;
	}
	@media screen and (max-width: 1100px) {
		font-size: 2rem;
	}
	@media screen and (max-width: 850px) {
		font-size: 1.6rem;
	}
	@media screen and (max-width: 700px) {
		margin: 0 auto;
		line-height: 2.4rem;
		padding: 11px 0;
	}
`;

export const FormContainer = styled.div`
	position: absolute;
	top: -115px;
	right: 23px;
	width: 370px;
	background-color: var(--white-color);
	border-radius: 8px;
	box-shadow: 0 8px 40px 0 rgba(30, 45, 68, 0.2);
	@media screen and (max-width: 1400px) {
		top: -45px;
		right: 0;
		width: 350px;
	}
	@media screen and (max-width: 808px) {
		right: 0;
		left: 0;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		position: relative;
		top: -50px;
		width: 400px;
	}
	@media screen and (max-width: 500px) {
		width: 100%;
		right: 10px;
	}
`;

export const Title = styled.h2`
	padding: 0 15px;
`;
