export const MAIN = '/';
export const START_PAGES = '/main';
export const PLAY_FIELDS = '/playfields';
export const PLAY_FIELDS_PAGES = '/playfields/:page?';
export const ONE_FIELDS = '/playfield/:id';
export const ONE_FIELDS_PAGE = '/playfield/:id/:page?';
export const PROFILE_USER = '/profile';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const PAYMENT_METHODS = 'payment-methods';

// ORDER
export const ORDER_SUCCESS = '/order-success/:order_id';
export const ORDER_FAIL = '/order-fail/:order_id';
export const PAYMENT_ORDER = '/payment-order-page/:order_id';
export const ORDER_WAIT_APPROVAL = '/order-wait-approval/:order_id';
export const ORDER_WAIT_CONFIRMATION = '/order-wait-confirmation/:order_id';
export const ORDER_CONCELLATION = '/order-cancellation/:order_id';

// DOCS-PAGE
export const DOCS = '/docs';
export const PERSONAL_DATA_PROCESSING_POLICY = '/personal-data-processing-policy';
export const PUBLIC_CONTRACT_RUMIKI_INDIVIDUALS = '/public-contract-rumiki-individuals';
export const PUBLIC_CONTRACT_RUMIKI_LEGAL_ENTITIES = '/public-contract-rumiki-legal-entities';

// ADMIN-PAGES //
export const ADMIN = '/admin/';
export const ADMIN_SETTING_GLOBAL = 'setting/';
export const ADMIN_SETTING_ORGS = 'organizations/';
export const ADMIN_SETTING_PLATFORMS = 'platforms/';
export const ADMIN_SETTING_SERVICES = 'services/';
export const ADMIN_SETTING_USERS = 'users/';
export const ADMIN_SETTING_ORDERS = 'orders/';
export const ADMIN_SETTING_ORDERS_TYPE = 'orders/:type/';
export const ADMIN_SETTING_ORDERS_TYPE_PAGE = 'orders/:type/:page?';
export const ADMIN_PAGE = 'records';
export const STATISTIC = 'statistic';
export const SETTING_CITIES = 'cities';
export const SETTING_SPORTS = 'sports';
export const CREATE_PLATFORM = 'create-platform';
export const SHOW_ALL_PLATFORMS = 'show-all-platforms';

// abonement
export const ABONEMENT = 'abonements';
export const SERVICE_ABONEMENT = '/service-abonements/:kind/:service_id';
export const CREATE_ABONEMENT = 'create-abonement';
export const CREATE_ABONEMENT_PAGE = 'create-abonement/:page?';
export const ALL_ABONEMENTS = 'all-abonements';
export const UPDATE_ABONEMENT = 'update-abonement';
export const UPDATE_ABONEMENT_PAGE = 'update-abonement/:page?';
export const PAYMENT_SUCCESS_PAGE = 'payment-abonement-success/:abonement_id';
export const PAYMENT_FAIL_PAGE = 'payment-abonement-fail/:abonement_id';

// admin orgs
export const CREATE_ORG = 'create-organization';
export const UPDATE_ORG = 'update-organization/:org_name';
export const SHOW_ALL_ORGS = 'show-all-orgs';
export const SHOW_ALL_ORGS_PAGE = 'show-all-orgs/:page?';

// admin services
export const CREATE_SERVICE = 'create-service';
export const UPDATE_SERVICE = 'update-service/:id';
export const UPDATE_PLATFORM = 'update-platform/:id';
export const ALL_DERS = 'all-deps';
export const ALL_DERS_PAGE = 'all-deps/:page?';
export const CREATE_DERS_PAGE = 'create-deps/:page?';
export const SHOW_RENT = 'show-rent';
export const SHOW_RENT_PAGE = 'show-rent/:page?';
export const SHOW_ALL_INVENTORIES = 'show-all-inventories';
export const SHOW_ALL_INVENTORIES_PAGE = 'show-all-inventories/:page?';
export const SHOW_ALL_EVENTS = 'show-all-events';
export const SHOW_ALL_EVENTS_PAGE = 'show-all-events/:page?';
export const SHOW_ALL_TOURNAMENT = 'show-all-tournaments';
export const SHOW_ALL_TOURNAMENT_PAGE = 'show-all-tournaments/:page?';
export const SHOW_ALL_WORKOUT = 'show-all-workouts';
export const SHOW_ALL_WORKOUT_PAGE = 'show-all-workouts/:page?';
export const SERVICE_PREVIEW = '/service-preview';

// admin users
export const SHOW_ALL_USERS = 'show-all-users';
export const SHOW_ALL_USERS_PAGE = 'show-all-users/:page?';
export const SHOW_ALL_MODERATORS = 'show-all-moderators';
export const SHOW_ALL_MODERATORS_PAGE = 'show-all-moderators/:page?';
export const SHOW_ALL_BLACKLISTED = 'show-all-blacklisted';
export const SHOW_ALL_BLACKLISTED_PAGE = 'show-all-blacklisted/:page?';

// admin platform
export const SHOW_ALL_PLATFORMS_PAGE = 'show-all-platforms/:page?';
export const ADMIN_SETTING_PLATFORMS_DEFAULT = `${ADMIN}${ADMIN_SETTING_PLATFORMS}show-all-platforms`;

export const RESET_PASSWORD = '/resetPassword';
export const REG = '/registration';
export const CONTACTS = '/contact';
export const QUOTE_PAGE = '/quote-page';
export const ORDER = '/order';
export const ORDER_PAGE = '/order/:page?';
export const ABOUT_PROJECT = '/about';
export const ALL_ORG = '/organizations';
export const ALL_ORG_PAGES = '/organizations/:page?';
export const ONE_ORG_NAME = 'organization/:name';
export const ONE_ORG_NAME_PAGES = '/organization/:name/:schedule_type?';
export const NEWS_PAGES = '/news';
export const ERROR_404 = '*';

export const SERVICES = '/services';
export const SERVICE = '/services/:kind';
export const SERVICE_PAGE = '/service/:service_id';

export const FAQ = '/FAQ';
