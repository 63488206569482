import {UniqueImage} from '../components/images/index.type';
import {PaymentMethod, UserMeServerInfo} from './interfaces';

export enum OrderCreateFieldKey {
	service_id = 'service_id',
	amount = 'amount',
	date = 'date',
	description = 'description',
	duration = 'duration',
	from = 'from',
	host = 'host',
	requester = 'requester',
	attached = 'attached',
	platform_id = 'platform_id',
	trial_order = 'trial_order',
}

export type OrderAttached = {
	service_id: string,
	amount: number,
};
export type OrderAttachList = {
	order_id: string,
	owner: string
	platform_id: string
};
export type OrderAttachDelete = {
	inventory: string
	order_id: string
	owner: string
	platform_id: string
};
export type OrderInfo = {
	[OrderCreateFieldKey.from]: number;
	[OrderCreateFieldKey.service_id]: string;
	[OrderCreateFieldKey.date]: string;
	[OrderCreateFieldKey.duration]: number,
	[OrderCreateFieldKey.amount]: number
};

export type Registered = {
	Registered: {
		id: string;
	}
};
export type Anonym = {
	Anonym: {
		phone: string;
	};
};
export type UserRequester = Registered | Anonym;

export type TOrderCreate = {
	[OrderCreateFieldKey.platform_id]: string;
	[OrderCreateFieldKey.description]: string;
	[OrderCreateFieldKey.attached]: OrderAttached[];
	[OrderCreateFieldKey.requester]: {
		User: {
			info: UserRequester
		}
	} | { Platform: { phone: string, requester: string } };
	[OrderCreateFieldKey.trial_order]: boolean
} & OrderInfo;

export type TOrderClaimCreate = {
	[OrderCreateFieldKey.attached]: OrderAttached[];
	[OrderCreateFieldKey.description]: string;
	[OrderCreateFieldKey.from]: number;
	[OrderCreateFieldKey.duration]: number;
	[OrderCreateFieldKey.date]: string;
	[OrderCreateFieldKey.platform_id]: string;
	[OrderCreateFieldKey.amount]: number;
	[OrderCreateFieldKey.requester]: UserRequester;
	[OrderCreateFieldKey.service_id]: string,
	[OrderCreateFieldKey.trial_order]: boolean,
};

export type TimePointMeta = { freeCount: number; order?: IOrder | null };

export interface OrderDetails {
	amount: number;
	date: string;
	description: string;
	duration: number;
	price: number;
	from: number;
	owned_by: UserMeServerInfo;
	payment_method: PaymentMethod;
	trial_order: boolean;
}

export type OrderServiceInfo = {
	service_id: string;
	service_name: string;
	service_type: string;
	sports: string[];
};

export type StatusOrder =
	'Closed'
	| 'Pending'
	| 'WaitApproval'
	| 'WaitConfirmation'
	| 'Process'
	| 'Ignored'
	| 'IgnoredByPlatform'
	| 'Success'
	| 'Paid';
export interface OrderStatusDetails{
	paid: boolean;
	status: StatusOrder;
	reason: string;
	requester: string;
	created_at: string;
	last_updated: string;
}
export interface IOrder {
	id: string;
	attached_info: {
		attached: number;
	};
	host_info: {
		platform_id: string;
		platform_logo: UniqueImage[];
		location_info: {
			address: string;
			location_x: 0;
			location_y: 0;
		};
		city: string | null;
		organization: string;
		org_logo: UniqueImage[];
		use_crm: boolean;
	};
	order_details: OrderDetails;
	service_info: OrderServiceInfo;
	status_info: OrderStatusDetails;
}

export enum OrdersHistoryFilterKey {
	user = 'user',
	platform = 'platform',
	service = 'service',
	organization = 'organization',
	start_date = 'start_date',
	end_date = 'end_date',
	from = 'from',
	status = 'status',
	sports = 'sports',
	service_type = 'service_type',
	service_kind = 'service_kind',
}
