import {memo} from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import popd from '../../docs/processing-policy-pd.docx';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pdr from '../../docs/public-agreement-Rumiki.docx';
import {PERSONAL_DATA_PROCESSING_POLICY, PUBLIC_CONTRACT_RUMIKI_LEGAL_ENTITIES} from '../../shared/constants/path';
import * as SC from './registration-conditions.style';

export const AcceptConditionsOrg = memo(() => {
	return (
		<SC.AcceptConditions>
			Оставляя заявку, вы принимаете условия
			<SC.AcceptConditionsLink to={PUBLIC_CONTRACT_RUMIKI_LEGAL_ENTITIES}>{' '} публичного
				договора</SC.AcceptConditionsLink> и
			<SC.AcceptConditionsLink to={PERSONAL_DATA_PROCESSING_POLICY}>{' '}
				положения об обработке персональных данных
			</SC.AcceptConditionsLink>
		</SC.AcceptConditions>
	);
});

export const RegisterConditionsUser = memo(() => {
	return (
		<SC.RegistrationConditions>
			Регистрируясь, вы принимаете условия
			<SC.RegisterLink href={popd}
						download="Публичный договор Румики физ .docx">{' '}
				публичного договора
			</SC.RegisterLink> и
			<SC.RegisterLink href={pdr} download="Политика обработки ПД.docx">{' '}
				положения об обработке персональных данных
			</SC.RegisterLink>
		</SC.RegistrationConditions>
	);
});
