import {FC, memo} from 'react';

import BePaid from '../../assets/icon/BePaid.svg';
import oplati from '../../assets/icon/oplati.svg';
import {Radio} from '../controls-new/radio/radio';
import * as SC from './payment-methods.style';

interface Props {
	onChangeServicePayment: (value: string) => void;
	value: string;
	discount: string | undefined;
}

export const PaymentMethods: FC<Props> = memo(({onChangeServicePayment, value, discount}) => {

	return (
		<SC.Container>
			<SC.Title>Выберите способ оплаты:</SC.Title>
			<SC.OplatiWrapper>
				<SC.ServiceContainer>
					<SC.RadioWrapper>
						<Radio checked={value === 'Oplati'} onChange={() => onChangeServicePayment('Oplati')} label={''}
							   value={'Oplati'}/>
					</SC.RadioWrapper>
					<SC.ServiceLogo src={oplati} alt={'Оплати сервис'}/>
					<SC.DescriptionServiceWrapper>
						<SC.OplatyTitle>Приложением «Оплати»</SC.OplatyTitle>
						<SC.WarningAfterPay>После оформления заказа будет создан QR-код для оплаты.</SC.WarningAfterPay>
					</SC.DescriptionServiceWrapper>
				</SC.ServiceContainer>
				{/* после окончании акции удалить  */}
				{discount && <SC.BonusesOplati>Начислим {discount}</SC.BonusesOplati>}
			</SC.OplatiWrapper>
			<SC.ServiceContainer>
				<SC.RadioWrapper>
					<Radio checked={value === 'BePaid'} onChange={() => onChangeServicePayment('BePaid')} label={''}
						   value={BePaid}/>
				</SC.RadioWrapper>
				<SC.ServiceLogo src={BePaid} alt={'BePaid сервис'}/>
				<SC.DescriptionServiceWrapper>
					<SC.BePaidTitle>С помощью сервиса BePaid </SC.BePaidTitle>
					<SC.WarningAfterPay>Вы будете перенаправлены на платежную страницу BePayd </SC.WarningAfterPay>
				</SC.DescriptionServiceWrapper>
			</SC.ServiceContainer>
		</SC.Container>
	);
});
