import {FC, memo} from 'react';
import {Helmet} from 'react-helmet-async';

interface SEOHelmetProps {
	title: string;
	link: string;
	description?: string;
}

export const SEOHelmet:FC<SEOHelmetProps> = memo(({link, title, description}) => {

	return (
		<Helmet>
			<title>{title}</title>
			<meta property="og:url" content={`https://sporder.by/${link}`}/>
			<meta name={'author'} content={'Руслан Сенатрев'}/>
			<meta name="description" content={description}/>
		</Helmet>
	);
});
