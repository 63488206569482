import {createSlice} from '@reduxjs/toolkit';

import {BalanceHistory, Config, Payment} from '../../interfaces/payment';
import {fetchGetBalanceHistory} from '../thunks/balance/get-balance-history';
import {fetchGetBalanceHistoryTotal} from '../thunks/balance/get-balance-history-total';
import {fetchGetConfig} from '../thunks/payment/get-config';
import {fetchGetPayment} from '../thunks/payment/get-payment';

interface InitialStatePayment {
	payment: Payment;
	config: Config;
	balanceHistory: BalanceHistory;
	balanceHistoryTotal: number,
}

const initialState: InitialStatePayment = {
	balanceHistory: {user_id: '',info:[]},
	balanceHistoryTotal: 0,
	payment: {
		abonement_id: null,
		created_at: '1970-01-01T00:00:00Z',
		order_id: null,
		paid_by_balance: 0,
		price: 0,
		requester: '',
		status: 'pending',
		token: null,
		uid: '',
		provider: 'Oplati',
		updated_at: null
	},
	config: {
		discounts: {
			duration_in_month: 0,
			passive_cashback: 0,
			populate_balance_high_amount: 0,
			populate_balance_high_cashback: 0,
			populate_balance_medium_amount: 0,
			populate_balance_medium_cashback: 0,
			populate_balance_small_amount: 0,
			populate_balance_small_cashback: 0,
			register_cashback: 0
		},
		referral_state: {
			referral_profit: 0,
			referrer_profit: 0
		},
		rewards: {
			bepaid_payment_reward_coef: 0,
			oplati_payment_reward_coef: 0,
			order_reward: 0,
		}
	}
};

export const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchGetConfig.fulfilled, (state, action) => {
			state.config = action.payload?.data;
		});
		builder.addCase(fetchGetPayment.fulfilled, (state, action) => {
			state.payment = action.payload?.data;
		});
		builder.addCase(fetchGetBalanceHistory.fulfilled, (state, action) => {
			state.balanceHistory = action.payload?.data;
		});
		builder.addCase(fetchGetBalanceHistoryTotal.fulfilled, (state, action) => {
			state.balanceHistoryTotal = action.payload?.data;
		});
	}
});
