import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const OrderCardwrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--Corner-L, 16px);
	align-self: stretch;
	width: 100%;
`;
export const ConfirmCancel = styled.h5`
	color: var(--text-Accent, #1B1B1B);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
export const ReasonsWrapper = styled.form`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Block-S, 16px);
	align-self: stretch;
`;
export const Modal = css`
	align-items: center;
`;
export const ContainerModal = css`
	border-color: var(--border-Y) !important;
`;
