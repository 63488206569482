import {createAsyncThunk} from '@reduxjs/toolkit';

import {GetServicesByFilterParams} from '../../../api/query-params';
import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetServicesOrg = createAsyncThunk(
	'organizationSlice/fetchGetServicesOrg',
	async (params: GetServicesByFilterParams, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getServicesByFilter(params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === requestCodes.success) {
				return {status: response.status, data: response.data.services};
			}

			if (response.status === requestCodes.emptyData) {
				return {status: response.status, data: []};
			}
		} catch (error) {
			handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
