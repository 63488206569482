import styled from '@emotion/styled';

export const Window = styled.div`
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999999;
	background: var(--overlay-normal, rgba(153, 153, 153, 0.30));
`;
export const ContentWrapper = styled.div`
	width: 100%;
	overflow: auto;
	max-height: calc(100vh - 4px);
	margin-bottom: 16px;

	&::-webkit-scrollbar-thumb {
		background-color: var(--green);
		border-radius: 20px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: #edf6f3;
		border-radius: 20px;
		width: 4px;
		margin: 10px 0;
	}

	&::-webkit-scrollbar {
		width: 4px;
	}

	@media screen and (max-width: 670px){
		padding: var(--Spacing-desctop-Body-M) var(--Spacing-desctop-Block-S);
		@media (max-width: 500px) {
			/* Скрыть скроллбар */
			&::-webkit-scrollbar {
				display: none; /* Для вебки */
			}
		}
	}
`;
export const Content = styled.div`
	max-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: var(--Spacing-desctop-Block-L) 30px var(--Spacing-desctop-Block-L) var(--Spacing-desctop-H2);

	@media screen and (max-width: 470px) {
		gap: 16px;
	}
`;
export const Container = styled.div`
	background-color: var(--white-color);
	border-radius: 8px;
	border: 1px solid var(--text-G);
	max-width: 610px;
	position: absolute;
	width: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding-right: 10px;
	@media screen and (max-width: 670px){
		border-radius: 0;
		padding: 0;
		top: 0;
		transform: translate(-50%, 0);
		min-height: 100vh;
		max-width: 100%;
	}
`;
export const CloseButton = styled.button`
	position: absolute;
	right: 30px;
	top: 32px;

	@media screen and (max-width: 670px) {
		right: 12px;
		top: 14px;
	}
`;
