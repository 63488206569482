import {FC, memo} from 'react';
import {Link} from 'react-router-dom';

import * as SC from './link-with-border.style';

interface Props {
	onClick?: () => void;
	label: string;
	borderColor?: 'yellow' | 'green' | 'purple';
	className?: string;
	path: string
}

export const LinkWithBorder: FC<Props> = memo(({label, onClick, borderColor = 'green', className, path}) => {
	return (
		<Link to={path} onClick={onClick}>
			<SC.Wrapper borderColor={borderColor} className={className}>{label}</SC.Wrapper>
		</Link>
	);
});

