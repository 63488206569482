import styled from '@emotion/styled';

export const CloseIcon = styled.img`
	width: 16px;
	height: 16px;
	filter: brightness(0.5) saturate(100%) invert(32%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
	transition: 0.3s;
    z-index: 999;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			filter: brightness(0) saturate(100%) invert(31%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0) contrast(100%);
		}
	}
`;
