import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {
	setIsShowMessage,
	setResetMessageUser,
} from '../../../redux/reducers/user-data-reducer';
import {useAppSelector} from '../../../redux/selectors/hook';
import {dataMessageSelector} from '../../../redux/selectors/user-state-selectors';
import {REGISTER} from '../../../shared/constants/path';
import st from './message-user.module.css';

export const MessageUser = () => {
	const {action, btnTitle, textMessage, titleMessage, descriptionMessage, isErrorMessage} =
		useAppSelector(dataMessageSelector);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const closeErrorMessage = () => {
		if(action === 'auth'){
			navigate(REGISTER,{replace: true,});
		}

		dispatch(setIsShowMessage({isShowMessage: false}));
		dispatch(setResetMessageUser);
	};

	return (
		<div className={st.container_big}>
			<div className="wrapper"></div>
			<div className={st.message__container}>
				<button
					type="button"
					className={st.close_btn}
					onClick={() => {
						dispatch(setIsShowMessage({isShowMessage: false}));
						if (action === 'auth') {
							navigate(REGISTER, {replace: true});
						}
					}}
				>
					X
				</button>
				<h3 className={`${st.message__title} ${isErrorMessage ? st.message__title_err : ''}`}>
					{titleMessage}
				</h3>
				<h4 className={st.message__text}>{descriptionMessage}</h4>
				<p className={st.message__text}>{textMessage}</p>
				{action !== 'notAction' && (
					<button type="button" className={st.message__btn} onClick={closeErrorMessage}>
						{btnTitle}
					</button>
				)}
			</div>
		</div>
	);
};
