import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const ServiceType = styled.p`
	color: var(--text-Accent);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Description = styled.p`
	color: var(--text-Accent, #1B1B1B);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
export const OrderDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Block-S);
	align-self: stretch;
`;
export const AbonementDetails = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	gap: var(--Spacing-desctop-Block-S);
	position: relative;
	width: 100%;

	@media screen and (max-width: 500px){
		flex-direction: column;
	}
`;
export const AbonementDetailsWrapper = styled(AbonementDetails)`
	flex-direction: column;
	align-items: flex-start;
`;
export const BobusesContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: fit-content;
	gap: var(--Spacing-desctop-Block-S);

	@media screen and (max-width: 500px){
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
	@media screen and (max-width: 430px){
		flex-direction: column;
		justify-content: center;
		width: fit-content;
		margin:  0 auto;
	}
`;
export const DetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
`;
export const Detail = styled.span`
	color: var(--text-Strong, #303030);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Price = styled.span`
	color: var(--text-Weak, #4D4D4D);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const VisitingConditionsWrapper = styled.div`
	display: flex;
	padding: 8px 16px;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	align-self: stretch;
	border-radius: var(--Corner-M, 8px);
	background: var(--background-Alternative);
`;
export const TitleWrapperConditions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--Spacing-desctop-Block-S);
`;
export const TitleConditions = styled.h4`
	color: var(--text-B, #352C8D);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Conditions = styled.p`
	color: var(--text-Accent);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Modal = css`
	max-width: 690px !important;
	gap: var(--Spacing-desctop-Block-M, 24px) !important;
	@media screen and (max-width: 490px) {
		padding: 10px !important;
	}
`;

export const BonusesWrapper = styled.div`
	display: flex;
	padding: var(--Spacing-desctop-Body-M) var(--Spacing-desctop-Block-S);
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	gap: var(--Spacing-desctop-Body-M);
	border-radius: var(--Corner-S, 4px);
	border: 1px solid var(--border-Neutral);

	@media screen and (max-width: 550px){
		position: relative;
		align-items: flex-start;
		border: none;
		padding: 0;
	}
`;
export const HaveBonuses = styled.p`
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const ResultPrice = styled.div`
	display: flex;
	gap: var(--Spacing-desctop-Body-M, 8px);
	color: var(--text-Weak, #4D4D4D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
