import {FC} from 'react';

import {OrderServerStatus} from '../../../../interfaces/enums';
import {IOrder} from '../../../../interfaces/order';
import {renderStatus} from '../../../../shared/utils/order/order';
import {statusesForConfirm} from '../../../admin-page-containers/setting-orders-container/cards-order/cards-order';
import {ButtonNew, ButtonWithBorder} from '../../../buttons-new';
import {ignoredStatuses} from '../../../filtering-table/components/order-card/order-card';
import * as SC from './order-card-btn-admin.style';

interface Props {
	order: IOrder;
	handleActivateOrder: () => void;
	clickShowWindowCanselOrder: () => void;
}

export const OrderCardBtnAdmin: FC<Props> = ({
												 order, handleActivateOrder, clickShowWindowCanselOrder,
											 }) => {

	return (
		<>
			{order.status_info.status === OrderServerStatus.Paid
				? <SC.Closed>Заказ оплачен</SC.Closed>
				: <SC.BtnWrapper>
					{order.status_info.status === OrderServerStatus.WaitApproval &&
						<ButtonNew label={'Подтвердить заказ'} bgColor={'purple'} className={SC.ConfirmtOrderButton}
								   onClick={handleActivateOrder}/>}
					{statusesForConfirm.includes(order.status_info.status) &&
						<ButtonNew label={'Подтвердить заказ'} bgColor={'purple'} className={SC.ConfirmtOrderButton}
								   onClick={handleActivateOrder}/>}
					{order.status_info.status === OrderServerStatus.Success
						? <SC.Success>Заказ подтвержден</SC.Success>
						: (ignoredStatuses.includes(order.status_info.status)
							? <SC.Closed>Заказ {renderStatus(order).toLowerCase()}</SC.Closed> :
							<ButtonWithBorder label={'Отменить заказ'} onClick={clickShowWindowCanselOrder}
											  borderColor={'yellow'}
											  className={SC.Button}/>)}
				</SC.BtnWrapper>}
		</>
	);
};
