import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const OrderInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-M, 8px);
`;
export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;
export const ButtonsRow = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
`;
export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
export const ModalContainer = css`
	max-width: 690px !important;
	width: 100% !important;
`;
export const Modal = css`
	gap: var(--Spacing-desctop-Block-M, 24px) !important;
`;
export const RecommendationContainer = styled.div`
	display: flex;
	padding: 16px 24px;
	align-items: center;
	gap: var(--Spacing-desctop-H2, 40px);
	border-radius: var(--Corner-12, 12px);
	outline: 1px solid var(--border-Neutral, #CFCFCF);
	background-color: var(--background-Base, #FFF);

	@media screen and (max-width: 650px) {
		flex-direction: column-reverse;
		gap: var(--Spacing-desctop-Block-M, 40px);
		padding: 12px 16px;
	}
`;
export const OrgInfoContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 16px;
	align-self: stretch;
`;
export const ServiceType = styled.h3`
	color: var(--text-Strong, #303030);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const Data = styled.span`
	color: var(--text-Strong, #303030);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Price = styled.span`
	color: var(--text-Weak, #4D4D4D);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
`;
export const BonusesIconWrapper = styled.div`
	position: relative;
`;
export const BonusesIconText = styled.span`
	position: absolute;
	top: 32%;
	left: 30%;
	transform: translate(-20%, -20%);
	rotate: 4deg;
	color: var(--text-Accent, #1B1B1B);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	text-wrap: nowrap;
`;
export const BonusesIcon = styled.img`
	width: 260px;
`;
