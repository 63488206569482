import {useCallback, useEffect, useRef, useState} from 'react';
import {NavLink} from 'react-router-dom';

import {ReactComponent as IconLogin} from '../../../../../assets/icon/login.svg';
import {ReactComponent as IconLogout} from '../../../../../assets/icon/logout.svg';
import {ReactComponent as IconOrder} from '../../../../../assets/icon/order.svg';
import {ReactComponent as IconProfile} from '../../../../../assets/icon/profile.svg';
import {ReactComponent as IconRegistration} from '../../../../../assets/icon/registration.svg';
import {getBonusesWordIcon} from '../../../../../components/order/modals/utils';
import {AuthPages} from '../../../../../interfaces/interfaces';
import {useHandleGoToAuth} from '../../../../../pages/orders/utils';
import {
	resetInfoOrdersOrg, resetInfoOrdersPlatform, resetInfoOrdersUser
} from '../../../../../redux/reducers/orders-reducer';
import {
	resetDataUser, setIsLoginPage, setResetIsAuth,
} from '../../../../../redux/reducers/user-data-reducer';
import {useAppSelector} from '../../../../../redux/selectors/hook';
import {
	dataUserBalanceSelector,
	isAuthSelector,
	nickUserSelector,
	selectedAdminFullRole
} from '../../../../../redux/selectors/user-state-selectors';
import {useAppDispatch} from '../../../../../redux/store';
import {fetchGetUserById} from '../../../../../redux/thunks/user/get-user-by-id';
import {LOCAL_STORAGE_KEY_MAP} from '../../../../../shared/constants/local-storage';
import {LOGIN, MAIN, PROFILE_USER, REGISTER} from '../../../../../shared/constants/path';
import {useClickOutside} from '../../../../../shared/hooks/use-click-outside';
import {buildAdminMenu} from '../../../../../shared/utils/admin/admin-menu';
import {getIdUser, isRightTokenTime} from '../../../../../shared/utils/decoder-jwt';
import {getLocalStorage, isAuth} from '../../../../../shared/utils/save-local-storage';
import * as SC from './user-info.style';

export const UserInfo = () => {
	const [isOpen, setIsOpen] = useState(false);

	const elementRef = useRef<HTMLDivElement | null>(null);
	useClickOutside(elementRef, () => setIsOpen(false));
	const userNick = useAppSelector(nickUserSelector);
	const isUserAuthenticatedByStore = useAppSelector(isAuthSelector);
	const isUserAuthenticated = isUserAuthenticatedByStore || isAuth();
	const selectedRole = useAppSelector(selectedAdminFullRole);
	const adminLink = buildAdminMenu(selectedRole)[0]?.to;
	const idUser = getIdUser();
	const userName = userNick || getLocalStorage(LOCAL_STORAGE_KEY_MAP.userNick) || 'John Doe';
	const dispatch = useAppDispatch();
	const balance = useAppSelector(dataUserBalanceSelector);
	const jwtTokenTime = isRightTokenTime();

	useEffect(() => {
		if(jwtTokenTime && idUser){
			dispatch(fetchGetUserById(idUser));
		}
	}, [idUser, jwtTokenTime]);

	const handleGoToPage = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleGoToLogout = useCallback(() => {
		localStorage.clear();
		dispatch(resetInfoOrdersUser());
		dispatch(resetDataUser());
		dispatch(setResetIsAuth());
		dispatch(resetInfoOrdersPlatform());
		dispatch(resetInfoOrdersOrg());
		dispatch(setIsLoginPage(true));
		handleGoToPage();
	}, []);

	const handleGoToAuth = useCallback(() => {
		useHandleGoToAuth();
		handleGoToPage();
	}, []);

	return (
		<SC.UserInfo ref={elementRef}>
			{isAuth()
				? (!isRightTokenTime()
					? <IconLogout onClick={() => setIsOpen((isOpen) => !isOpen)}/>
					: <SC.BalanseWrapper>
						<IconProfile width={24} height={24} onClick={() => setIsOpen((isOpen) => !isOpen)}/>
						<SC.Balance>{getBonusesWordIcon(balance / 100)}</SC.Balance>
				</SC.BalanseWrapper>)
				: <IconProfile width={24} height={24} onClick={() => setIsOpen((isOpen) => !isOpen)}/>}
			{isOpen ? (
				<SC.UserInfoDetails>
					{isUserAuthenticated && isRightTokenTime() ? (
						<>
							<div className={SC.UserInfoDetailsListItem}>
								{adminLink ? (
									<NavLink
										to={adminLink}
										className={SC.UserInfoDetailsListItem}
										onClick={handleGoToPage}
									>
										<IconProfile width={24} height={24}/>
									</NavLink>
								) : (<IconProfile width={24} height={24}/>)}
								{userName}
							</div>

							<NavLink
								to={PROFILE_USER}
								className={SC.UserInfoDetailsListItem}
								onClick={handleGoToPage}
							>
								<IconOrder/>
								История заказов
							</NavLink>
							<NavLink
								to={MAIN}
								className={SC.UserInfoDetailsListItem}
								onClick={handleGoToLogout}
							>
								<IconLogout/>
								Выйти
							</NavLink>
						</>
					) : (
						<>
							<NavLink
								to={LOGIN}
								className={SC.UserInfoDetailsListItem}
								onClick={handleGoToAuth}
								state={{page: AuthPages.login}}
							>
								<IconLogin/>
								Войти
							</NavLink>
							<NavLink
								to={REGISTER}
								className={SC.UserInfoDetailsListItem}
								onClick={handleGoToAuth}
								state={{page: AuthPages.registration}}
							>
								<IconRegistration/>
								Регистрация
							</NavLink>
						</>
					)}
				</SC.UserInfoDetails>
			) : null}
		</SC.UserInfo>
	);
};
