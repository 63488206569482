import {FC, memo, useCallback, useState} from 'react';

import noImagePlaceholder from '../../../../assets/img/no_image.png';
import canceled_icon from '../../../../assets/order-icon/canceled-icon.svg';
import confirmed_icon from '../../../../assets/order-icon/confirmed-icon.svg';
import pending_icon from '../../../../assets/order-icon/expectation-icon.svg';
import {OrderServerStatus} from '../../../../interfaces/enums';
import {IOrder, StatusOrder} from '../../../../interfaces/order';
import {SERVICE_PAGE} from '../../../../shared/constants/path';
import {useResizeWindow} from '../../../../shared/hooks/use-resize-window';
import {convertPriceToString} from '../../../../shared/utils/converter-price';
import {orgLogoSmall} from '../../../../shared/utils/get-images-size';
import {getStatusIcon, renderStatus} from '../../../../shared/utils/order/order';
import {activateScroll, disabledScroll} from '../../../../shared/utils/scroll-body';
import {converterTime, converterTimeString} from '../../../../shared/utils/time/converter-time';
import {ButtonNew, ButtonWithBorder} from '../../../buttons-new';
import {Link} from '../../../links-navigate';
import {ModalPayment} from '../../../order/modals';
import {CancelOrder} from '../../../order/modals/components/cancel-order/cancel-order';
import {OrderDetails} from '../../../order/order-details/order-details';
import {OverflowTooltip} from '../../../overflow-tooltip/overflow-tooltip';
import * as SC from './order-card.style';

interface OrderCardProps {
	order: IOrder;
	refetch?: () => void
	notShowButton?: boolean;
	className?: string;
}

export const ignoredStatuses: StatusOrder[] = [
	OrderServerStatus.Ignored,
	OrderServerStatus.IgnoredByPlatform,
	OrderServerStatus.Closed,
	OrderServerStatus.Paid,
	OrderServerStatus.Success,
];

export function resetTime(date: Date) {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}


export const OrderCard: FC<OrderCardProps> = memo(({order, refetch, notShowButton, className}) => {
	const [showWindow, setShowWindow] = useState<string | undefined>(undefined);
	const status = order.status_info.status;
	const iconStatus = getStatusIcon(canceled_icon, confirmed_icon, pending_icon, status);
	const width = useResizeWindow();
	const today = resetTime(new Date());
	const compareDate = resetTime(new Date(order.order_details.date));

	const onClickShowWindowModal = useCallback((value: string) => {
		if (showWindow === value) {
			setShowWindow(undefined);
			activateScroll();
		} else {
			disabledScroll();
			setShowWindow(value);
		}
	}, [showWindow]);

	return (
		<>
			<SC.Container className={className} active={today <= compareDate && !ignoredStatuses.includes(status)}>
				<SC.Details onClick={() => onClickShowWindowModal('details')}>Подробнее...</SC.Details>
				<SC.HeaderCard>
					<SC.Logo
						src={orgLogoSmall(order.host_info.org_logo) || noImagePlaceholder}
						alt={`[${order.service_info.sports}]${order.service_info.service_type}-${order.host_info.organization}`}
						title={`[${order.service_info.sports}]${order.service_info.service_type}-${order.host_info.organization}`}/>
					<SC.OrgDataWrapper>
						{width > 600 &&
							<SC.ServiceType><OverflowTooltip content={order.host_info.organization}/></SC.ServiceType>}
						{width <= 600 && <SC.ServiceTypeMobile>{order.host_info.organization}</SC.ServiceTypeMobile>}
						{width > 600 && <SC.Address><OverflowTooltip
							content={`${order.host_info.city ? `${order.host_info.city}, ` : ''}${order.host_info.location_info.address}`}/></SC.Address>}
						{width <= 600 &&
							<SC.AddressMobile>{order.host_info.city ? `${order.host_info.city}, ` : ''}, {order.host_info.location_info.address}</SC.AddressMobile>}
					</SC.OrgDataWrapper>
				</SC.HeaderCard>
				<SC.OptionsContainer>
					<SC.Options>
						<SC.Option>Дата и Время</SC.Option>
						<SC.Option>Услуга</SC.Option>
						<SC.Option>Статус</SC.Option>
					</SC.Options>
					<SC.OptionsData>
						<SC.OptionData>{converterTimeString(order.order_details.date)} {converterTime(order.order_details.from)} - {converterTime(order.order_details.from + order.order_details.duration)}</SC.OptionData>
						<SC.OptionData>{order.service_info.service_type}</SC.OptionData>
						<SC.OptionData><img src={iconStatus} alt={'status_icon'}/> {renderStatus(order)}</SC.OptionData>
					</SC.OptionsData>
				</SC.OptionsContainer>
				<SC.PriceButtonsWrapper>
					<SC.Price>{convertPriceToString(order.order_details.price)}</SC.Price>
					{!notShowButton && <SC.ButtonWrapper>{order.status_info.status === OrderServerStatus.Pending &&
						<ButtonNew label={'Оплатить'} bgColor={'purple'}
								   onClick={() => onClickShowWindowModal('payment')}/>}
						{!ignoredStatuses.includes(status) ?
							<ButtonWithBorder label={'Отменить заказ'} onClick={() => onClickShowWindowModal('cancel')}
											  borderColor={'yellow'}/>
							: <Link label={'Повторить заказ'} bgColor={'purple'}
									path={SERVICE_PAGE.replace(':service_id', order.service_info.service_id)}/>}
					</SC.ButtonWrapper>}
				</SC.PriceButtonsWrapper>
				{showWindow === 'details' &&
					<OrderDetails order={order} closeWindowDetails={() => onClickShowWindowModal('details')}/>}
				{showWindow === 'payment' &&
					<ModalPayment order={order} onClickClose={() => onClickShowWindowModal('payment')}/>}
				{showWindow === 'cancel' &&
					<CancelOrder order={order} onClickClose={() => onClickShowWindowModal('cancel')}
								 refetch={refetch}/>}
			</SC.Container>
		</>
	);
});
