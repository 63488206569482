import {createAsyncThunk} from '@reduxjs/toolkit';

import {searchUsersAPI} from '../../../api/search-users-a-p-i';
import {messageErr404} from '../../../shared/constants/message-user';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setIsShowMessage, setMessageUser, setShowLoader, UserDataForAdmin} from '../../reducers/user-data-reducer';

export const fetchGetUserByIdForAdmin = createAsyncThunk(
	'userDataSlice/fetchGetUserByIdForAdmin',
	async (data: string, {dispatch, rejectWithValue}) => {
		try {
			const response = await searchUsersAPI.getUserById(data);

			if (response.status === requestCodes.success) {
				const registered = response.data.description.Registered;
				const data: UserDataForAdmin = {
					name: registered.name,
					id: registered.id,
					phone: registered.phone,
					email: registered.email,
					telegram: registered.telegram,
					avatar: response.data.meta.avatar
				};
				return {
					status: response.status,
					data
				};
			}

			if (response.status === requestCodes.emptyData) {
				dispatch(setShowLoader({isShowLoader: false}));
				dispatch(setMessageUser({messageUser: messageErr404}));
				dispatch(setIsShowMessage({isShowMessage: true}));
			}
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
