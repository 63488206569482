import styled from '@emotion/styled';
interface Style{
	defaultColor?: 'yellow' | 'green' | 'purple';
}

export const Container  = styled.button<Style>`
	width: fit-content;
	color: ${props => props.defaultColor === 'yellow' ? 'var(--border-Y)' : (props.defaultColor === 'green' ? 'var(--green)' : 'var(--text-B)')};
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	border-bottom: 1px solid ${props => props.defaultColor === 'yellow' ? 'var(--border-Y)' : (props.defaultColor === 'green' ? 'var(--green)' : 'var(--text-B)')};
	transition: 0.3s;

	&:disabled {
		background-color: var(--button-Desabled-Primary);
		pointer-events: none;
	}

	@media (hover: hover) {
		&:hover {
			color: ${props => props.defaultColor === 'yellow' ? '#B97916' : (props.defaultColor === 'green' ? 'var(--green-hover)' : 'var(--text-B-hover)')};
			border-bottom: 1px solid ${props => props.defaultColor === 'yellow' ? '#B97916' : (props.defaultColor === 'green' ? 'var(--green-hover)' : 'var(--text-B-hover)')};
		}
	}
`;
