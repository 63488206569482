import classNames from 'classnames';
import {FC} from 'react';

import {IOrder} from '../../../interfaces/order';
import {getIdUser} from '../../../shared/utils/decoder-jwt';
import {renderRequester, renderStatus} from '../../../shared/utils/order/order';
import {converterDatetimeString, converterTimeString} from '../../../shared/utils/time/converter-time';
import {getTimeDisplayValue} from '../../../shared/utils/time/get-time-display-value';
import {ListWithIcons} from '../../list-with-icons/list-with-icons';
import {ModalNew} from '../../modal-new/modal-new';
import IconCount from '../../svg-icon-components/icon-count';
import IconDollar from '../../svg-icon-components/icon-dollar';
import IconLocation from '../../svg-icon-components/icon-location';
import IconPencil from '../../svg-icon-components/icon-pencil';
import IconTime from '../../svg-icon-components/icon-time';
import styles from './order-details.module.css';

interface InterfaceOrderDetails {
	order: IOrder | null;
	groupClassName?: string;
	closeWindowDetails: () => void;
}

export const OrderDetails: FC<InterfaceOrderDetails> = ({order, groupClassName, closeWindowDetails}) => {
	if (!order) {
		return;
	}

	const idUser = getIdUser();

	return (
		<ModalNew onClickClose={closeWindowDetails}>
			<h2>Подробнее о заказе</h2>
			<div className={styles.details}>
				<div className={classNames(styles.details_box, groupClassName)}>
					<h3>Тип заказа</h3>
					<ListWithIcons>
						<ListWithIcons.Item icon={<IconLocation/>} contentClassName={styles.list_item_content}>
							<span className={styles.label}>Номер заказа: {order.id}</span>
						</ListWithIcons.Item>

						<ListWithIcons.Item icon={<IconTime/>} contentClassName={styles.list_item_content}>
              <span className={styles.label}>
                {order.order_details.date ? `${converterTimeString(order.order_details.date)} ,` : 'Не указано'}{' '}
				  {order.order_details.date && getTimeDisplayValue(
					  order.order_details.from,
					  order.order_details.from + order.order_details.duration,
					  true,
				  )}
              </span>
						</ListWithIcons.Item>
						<ListWithIcons.Item icon={<IconCount/>} contentClassName={styles.list_item_content}>
              <span className={styles.label}>
                Количество: {order.order_details.amount} шт.
              </span>
						</ListWithIcons.Item>
						<ListWithIcons.Item icon={<IconCount/>} contentClassName={styles.list_item_content}>
              <span className={styles.label}>
                Бесплатное занятие: {order.order_details.trial_order ? 'Да' : 'Нет'}.
              </span>
						</ListWithIcons.Item>
						{!!order.order_details.description && (
							<ListWithIcons.Item
								icon={<IconPencil/>}
								contentClassName={styles.list_item_content}
							>
                <span className={styles.label}>
                  {order.order_details.description}{' '}
                </span>
							</ListWithIcons.Item>
						)}
					</ListWithIcons>
				</div>
				<div className={classNames(styles.details_box, groupClassName)}>
					<h3>Информация о пользователе</h3>
					<ListWithIcons>
						{'Registered' in order.order_details.owned_by &&
							<ListWithIcons.Item
								icon={<IconCount/>}
								contentClassName={styles.list_item_content}
							>
              <span className={styles.label}>
                {order.order_details.owned_by.Registered.name}
              </span>
							</ListWithIcons.Item>}
						<ListWithIcons.Item icon={<IconCount/>} contentClassName={styles.list_item_content}>
              <span className={styles.label}>
                {'Registered' in order.order_details.owned_by ? order.order_details.owned_by.Registered.phone : order.order_details.owned_by.Anonym.phone}
              </span>
						</ListWithIcons.Item>
						{'Registered' in order.order_details.owned_by &&
							<ListWithIcons.Item icon={<IconCount/>} contentClassName={styles.list_item_content}>
              <span className={styles.label}>
                {order.order_details.owned_by.Registered.email}
              </span>
							</ListWithIcons.Item>}
					</ListWithIcons>
				</div>
				<div className={classNames(styles.details_box, groupClassName)}>
					<h3>Информация об услуге</h3>
					<ListWithIcons>
						<ListWithIcons.Item icon={<IconCount/>} contentClassName={styles.list_item_content}>
              <span className={styles.label}>
                Вид услуги: {order.service_info.service_type}
              </span>
						</ListWithIcons.Item>
					</ListWithIcons>

					<ListWithIcons>
						<ListWithIcons.Item icon={<IconCount/>} contentClassName={styles.list_item_content}>
              <span className={styles.label}>
                Виды спорта: {order.service_info.sports.join(', ')}
              </span>
						</ListWithIcons.Item>
					</ListWithIcons>

				</div>
				<div className={classNames(styles.details_box, groupClassName)}>
					<h3>Статус заказа</h3>
					<ListWithIcons>
						<ListWithIcons.Item
							icon={<IconTime/>}
							contentClassName={styles.list_item_content}
						>
              <span className={styles.label}>
                Статус заказа: {renderStatus(order)}
              </span>
						</ListWithIcons.Item>

						<ListWithIcons.Item icon={<IconCount/>} contentClassName={styles.list_item_content}>
              <span className={styles.label}>
                Изменения: {renderRequester(order, idUser)}
              </span>
						</ListWithIcons.Item>

						{!!order.status_info.last_updated && (
							<ListWithIcons.Item icon={<IconDollar/>} contentClassName={styles.list_item_content}>
                <span className={styles.label}>
                  Дата изменения:{' '}
					{converterDatetimeString(order.status_info.last_updated)}
                </span>
							</ListWithIcons.Item>
						)}
					</ListWithIcons>
				</div>
			</div>
		</ModalNew>
	);
};
