import {FC, memo} from 'react';

import * as SC from './link.style';

interface Props {
	click?: () => void;
	label: string;
	bgColor?: 'yellow' | 'green' | 'purple';
	className?: string;
	path: string;
}


export const Link: FC<Props> = memo(({bgColor = 'green', click, label, className, path}) => {
	return (
		<SC.Container onClick={click} className={className} to={path}>
			<SC.Wrapper bgColor={bgColor}>{label}</SC.Wrapper>
		</SC.Container>
	);
});
