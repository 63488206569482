import {FC, memo} from 'react';

import {Rewards} from '../../../../../interfaces/payment';
import {canculateForPayment, getBonusesWordIcon} from '../../utils';
import * as SC from './bonuses-icon-text.style';

interface Props {
	rewards: Rewards;
	price: number;
	balance: number;
	useAllBonuses: boolean;
	anonim?: boolean;
}

export const BonusesIconText: FC<Props> = memo(({rewards, price, useAllBonuses, balance, anonim}) => {

	if (anonim) {
		return <div>
			<SC.Container>
				<SC.Wrapper>
					<SC.BonusesIconText>
						<span>Начислим</span> {getBonusesWordIcon(rewards.order_reward / 100)}
					</SC.BonusesIconText>
				</SC.Wrapper>
			</SC.Container>
		</div>;
	}

	return (
		<div>
			<SC.Container>
				<SC.Wrapper>{balance >= price && useAllBonuses
					?
					<SC.BonusesIconText>{canculateForPayment({
						rewards,
						price,
						balance,
						useAllBonuses
					})}</SC.BonusesIconText>
					: <SC.BonusesIconText><span>Начислим</span> {canculateForPayment({
						rewards,
						price,
						balance,
						useAllBonuses
					})} <br/> за оплату</SC.BonusesIconText>}</SC.Wrapper>
			</SC.Container>
		</div>
	);
});
