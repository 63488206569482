import {ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';

import * as SC from './input-phone.style';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	placeholder?: string;
	keyForm: 'phone';
	defaultValue?: string;
}

export interface Form {
	phone: string;
}

export const InputPhone: FC<Props> = ({placeholder, keyForm, defaultValue, ...restProps}) => {
	const [value, setValueState] = useState(defaultValue ||'');
	const inputRef = useRef<HTMLInputElement | null>(null);
	const {setValue} = useFormContext<Form>();

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Remove all characters except numbers and add +375 at the beginning
		const formattedValue = `+375${inputValue.replace(/^\+375|^375|\D/g, '')}`;
		if (inputValue.startsWith('+375') && formattedValue.length <= 13) {
			setValueState(formattedValue);
			setValue(keyForm, formattedValue);
		}
	};

	const onFocus = () => {
		if (value === '') {
			setValueState('+375');
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.setSelectionRange(4, 4);
				}
			}, 0);
		}
	};
	const onBlur = () => {
		if (value === '+375') {
			setValueState('');
		}
	};

	return (
		<SC.Input
			{...restProps} ref={inputRef} placeholder={placeholder || 'Введите номер телефона'}
			onChange={onChange} onFocus={onFocus} value={value} onBlur={onBlur}/>
	);
};
