import {FC, memo, useCallback, useState} from 'react';

import {AbonementType} from '../../../../../../interfaces/abonement';
import {Rewards} from '../../../../../../interfaces/payment';
import {ServiceListItem} from '../../../../../../interfaces/service';
import {convertPriceToString} from '../../../../../../shared/utils/converter-price';
import {ButtonNew} from '../../../../../buttons-new';
import {ButtonCheckbox} from '../../../../../controls-new/checkboxes/button-checkbox/button-checkbox';
import {PaymentMethods} from '../../../../../payment-methods/payment-methods';
import {BonusesIconText} from '../../../components/bonuses-icon-text/bonuses-icon-text';
import {
	bonusesAvailable,
	calculateFinishPrice,
	canculateForPaymentOplati,
	getLimitAbonement,
	workingAbonementTo
} from '../../../utils';
import {CardAbonement} from '../card-abonement/card-abonement';
import {ServicesAbonementAdaptive} from '../services-abonement-adaptive/services-abonement-adaptive';
import * as SC from './details-abonement-adaptive.style';

interface Props {
	abonement: AbonementType;
	balance: number;
	rewards: Rewards;
	price: number;
	useAllBonuses: boolean;
	onClickBye: () => void;
	clickUseAllBonuses: () => void;
	services: ServiceListItem[];
	onChangeServicePayment: (value: string) => void;
	useProvider: string;
}

export const DetailsAbonementAdaptive: FC<Props> = memo(({
															 abonement, balance, onClickBye, price, rewards,
															 clickUseAllBonuses, useAllBonuses, services,
															 useProvider, onChangeServicePayment,
														 }) => {
	const [showMore, setShowMore] = useState(false);
	const clickShowMore = useCallback(() => setShowMore(prevState => !prevState), []);
	const info = abonement.info;

	return (
		<>
			<CardAbonement abonement={abonement} clickShowMore={clickShowMore}/>
			{showMore && <>
				<ServicesAbonementAdaptive abonement={abonement} services={services}/>
				<SC.AbonementInfoWrapper>
					{info.description.length > 0 &&
						<SC.Description>{info.description}</SC.Description>}
					<SC.LimitWrapper>
						<SC.Limit>{getLimitAbonement(info.duration)}</SC.Limit>
						<SC.WorkTo>До {workingAbonementTo(info.duration)}</SC.WorkTo>
					</SC.LimitWrapper>
					<SC.Price>{convertPriceToString(info.price)}</SC.Price>
				</SC.AbonementInfoWrapper>
			</>}
			<SC.BonusesContainer>
				<SC.BonusesWrapper>
					<SC.HasBonuses>Доступно <span>{bonusesAvailable({
						useAllBonuses, balance, price: info.price
					})}</span></SC.HasBonuses>
					<ButtonCheckbox label={'Cписать'} checked={useAllBonuses} id={'bonuses'} name={'bonuses'}
									value={'bonuses'} handleCheckboxChange={clickUseAllBonuses}/>
					<SC.ResultPrice>
						<span>Итого</span>
						<SC.PriceRes>{calculateFinishPrice({price: info.price, balance, useAllBonuses})}</SC.PriceRes>
					</SC.ResultPrice>
				</SC.BonusesWrapper>
				<BonusesIconText rewards={rewards} price={price} useAllBonuses={useAllBonuses} balance={balance}/>
			</SC.BonusesContainer>
			<PaymentMethods onChangeServicePayment={onChangeServicePayment} value={useProvider}
							discount={canculateForPaymentOplati({useAllBonuses, balance, price: info.price})}/>
			<ButtonNew label={'Оплатить'} bgColor={'purple'} onClick={onClickBye}/>
		</>
	);
});
