import {FC, memo} from 'react';

import * as SC from './thumb.style';
type PropType = {
	selected: boolean
	src: string;
	onClick: () => void
};

export const Thumb: FC<PropType> = memo(({selected, src, onClick}) => {

	return (
		<SC.EmblaThumbsSlide selected={selected}>
			<SC.EmblaThumbsSlideNumber onClick={onClick} type="button" selected={selected}>
				<SC.Img src={src} alt="" selected={selected}/>
			</SC.EmblaThumbsSlideNumber>
		</SC.EmblaThumbsSlide>
	);
});
