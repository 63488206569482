import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	padding: 16px 24px;
	border-radius: var(--Corner-12, 12px);
	outline: 1px solid var(--border-Neutral);
	background: var(--background-Base);

	@media screen and (max-width: 600px) {
		width: 100%;
	}
`;
export const TitleBenefit = styled.h4`

	color: rgb(27, 27, 27);
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0;
	text-align: center;
`;
export const TextBenefit = styled.p`
	color: rgb(77, 77, 77);
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0;
	text-align: center;
`;
export const BenefitBlockWrapper = styled.div`
	display: flex;
	gap: var(--Spacing-desctop-Block-S);
	flex-direction: column;
`;
export const BenefitBlockLinks = styled(BenefitBlockWrapper)`
	display: flex;
	flex-direction: row;
	gap: var(--Spacing-desctop-Block-S);
	@media screen and (max-width: 500px){
		flex-direction: column;
	}
`;
