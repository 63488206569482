import {ABONEMENT} from '../constants/path';

export const cleanWebsiteUrl = (url: string) => {
	return url.replace(/^https?:\/\//, '').replace(/\/$/, '');
};

interface CrossToAbonementsParams {
	platform_id: string;
	service_type: string;
	service_kind: string;
	sport_types: string[];
}

export const crossToAbonementsParams = ({
											platform_id, service_type, service_kind, sport_types
										}: CrossToAbonementsParams) => {
	const sportsQuery = sport_types.join(',');
	const url = new URLSearchParams({platform_id, service_type, service_kind, sport_types: sportsQuery,}).toString();
	return `/${ABONEMENT}?${url}`;
};
