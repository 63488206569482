import styled from '@emotion/styled';

import {ContainerStyle} from '../../../../../../pages/profile-page/components/abonement-card/abonement-card.style';

export const Container = styled.div<ContainerStyle>`
	display: flex;
	max-width: 450px;
	width: 100%;
	padding: 16px 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--Spacing-desctop-Body-M, 8px);
	border-radius: 8px;
	border: 1px solid ${props => props.active ? 'var(--border-G)' : 'var(--border-Neutral)'};
	background: ${props => props.active ? 'var(--background-Alternative)' : 'var(--background-Base)'};
	margin: 0 auto;
`;
export const Name = styled.h2`
	color: var(--text-Accent, #1B1B1B);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
`;
export const Price = styled.span`
	color: var(--text-Weak, #4D4D4D);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
`;
export const LimitWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: var(--Spacing-desctop-Body-S, 4px);
	align-items: center;
	width: 100%;
`;
export const Limit = styled.span`
	color: var(--text-Strong, #303030);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const AbonementPriceLimitWrapper = styled(LimitWrapper)`
	align-items: flex-start;
`;
export const WorkTo = styled(Limit)`
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
`;
export const ButtonDetails = styled.button`
	display: flex;
	align-items: center;
	gap: 4px;
	color: var(--text-B);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;
export const Vector = styled.img`
	filter: brightness(0) saturate(100%) invert(35%) sepia(92%) saturate(586%) hue-rotate(232deg) brightness(29%) contrast(100%);
`;
